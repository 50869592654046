/* Copyright 2013 - 2024 Waiterio LLC */
import getStripeBrowserByCountry from '@waiterio/stripe-publishable/getStripeBrowserByCountry.js'

export default async function confirmStripeCardPayment({
  card,
  clientSecret,
  countryCode,
  stripeAccountId,
}) {
  let stripe = await getStripeBrowserByCountry({ countryCode, stripeAccountId })

  let result = await stripe.confirmCardPayment(clientSecret, {
    payment_method: {
      card,
    },
  })

  if (result.error) {
    throw new Error(result.error.message)
  }

  return result.paymentIntent
}
