/* Copyright 2013 - 2024 Waiterio LLC */
import { getContext } from '@monorepo/context/index.js'
import Itemstamp from '@waiterio/model/Itemstamp.js'
import { TAKEAWAY, DELIVERY } from '@waiterio/model/Meal.js'
import { orderStatusSelector, paidSelector } from '../selectors/AppSelectors.js'

// TODO use actual translate not in hook form
function t(key) {
  return key
}

function isPushNotificationSupported() {
  return (
    'serviceWorker' in navigator &&
    'PushManager' in window &&
    'Notification' in window
  )
}

async function askUserPermission() {
  if (isPushNotificationSupported() && Notification.permission === 'default') {
    await Notification.requestPermission()
  }
}

function sendNotification(event) {
  if (isPushNotificationSupported() && Notification.permission === 'granted') {
    const { meal } = event
    const context = getContext()
    const { name } = context.data.restaurant
    const mealId = meal._id

    const orderStatus = orderStatusSelector(context, { mealId })
    const orderPaid = paidSelector(context, { mealId })
    const isTakeaway = meal.service === TAKEAWAY
    const isDelivery = meal.service === DELIVERY

    let body = ''
    const orderAccepted = orderStatus === Itemstamp.Status.ORDERED
    const orderCooking = orderStatus === Itemstamp.Status.COOKING
    const orderReady = orderStatus === Itemstamp.Status.READY
    const orderServed = orderStatus === Itemstamp.Status.SERVED

    if (orderAccepted) {
      body = t('Your order has been confirmed')
    } else if (orderCooking) {
      body = t('Your order is being prepared')
    } else if (orderReady && isTakeaway) {
      body = t('Your order is ready for pickup')
    } else if ((orderServed || orderPaid) && isTakeaway) {
      body = t('Your order is picked up')
    } else if (orderReady && isDelivery) {
      body = t('Your order is on the way')
    } else if ((orderServed || orderPaid) && isDelivery) {
      body = t('Your order is delivered')
    } else {
      body = t('Your order is cancelled')
    }

    const title = `Order update from ${name}`
    const data = Object.assign(meal, { locale: context.language })

    const options = {
      body,
      icon: 'https://assets.waiterio.com/icons/favicon-96x96.png',
      vibrate: [200, 100, 200],
      data,
    }

    navigator.serviceWorker
      .getRegistration()
      .then(registration => {
        registration.showNotification(title, options)
      })
      .catch(error => console.log('service worker not ready', error))
  }
}

export default {
  isPushNotificationSupported,
  askUserPermission,
  sendNotification,
}
