/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import countries from '@monorepo/shared/countries.js'
import { useTranslation } from '@multilocale/react/index.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import colSm12 from '@waiterio/styles/bootstrap/colSm12.js'
import LayoutComponent from '../components/LayoutPublicComponent.js'
import headingContainer from '../styles/headingContainer.js'
import headingTitle from '../styles/headingTitle.js'
import useRestaurant from '../useRestaurant.js'
import useSubdomain from '../useSubdomain.js'
import languages from '../languages.js'

export const paths = languages.map(locale => ({
  path: `/${locale}/contact/`,
  props: {
    locale,
  },
}))

export const Head = ({ locale }) => {
  const { t } = useTranslation(locale)
  const vanityId = useSubdomain()
  const { data: restaurant = {} } = useRestaurant(vanityId)
  const url = `/${locale}/contact/`
  const canonical = restaurant?.vanityId
    ? `https://${restaurant.vanityId}.waiterio.com${url}`
    : null

  const title = `${t('Contact')} | ${restaurant.name || t('Restaurant')}`
  const description = restaurant?.description || t('Restaurant')
  const cover = restaurant?.coverPhotoUr

  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={cover} />
      <meta property="og:site_name" content={t('Successful Restaurant')} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="article" />
      <meta property="og:url" content={canonical} />
      <meta property="og:image" content={cover} />
      <meta name="application-name" content={title} />
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      {canonical && <link rel="canonical" href={canonical} />}
    </>
  )
}

const ContactPage = props => {
  const { locale } = props
  const { t } = useTranslation(locale)
  const vanityId = useSubdomain()
  const { data: restaurant = {} } = useRestaurant(vanityId)
  const { email, street, city, zipcode, state, countryCode, phone, whatsapp } =
    restaurant

  return (
    <LayoutComponent locale={locale}>
      <div css={{ background: 'var(--color-primary)', height: 62 }} />

      <div css={[container, { marginTop: 80, marginBottom: 80 }]}>
        <div css={row}>
          <div css={colSm12}>
            <div css={headingContainer}>
              <h3 css={headingTitle}>{t('Contact')}</h3>
            </div>
          </div>
        </div>

        <div
          css={{
            maxWidth: 500,
            position: 'relative',
            textAlign: 'center',
            margin: 'auto',
            fontSize: 25,
            fontWeight: '200',
          }}
        >
          {street && <div>{`${street || ''}`?.trim()}</div>}

          {(city || zipcode) && (
            <div css={{ marginTop: 5 }}>
              {`${zipcode || ''} ${city || ''}`?.trim()}
            </div>
          )}

          {(state || countryCode) && (
            <div css={{ marginTop: 5 }}>
              {`${state || ''} ${
                t(countries[countryCode], locale) || ''
              }`?.trim()}
            </div>
          )}

          {(phone || email || whatsapp) && (
            <div css={{ marginTop: 60 }}>
              {phone && (
                <div css={{ padding: 5 }}>
                  <span css={{ textTransform: 'capitalize' }}>
                    {t('phone')}
                  </span>
                  :&nbsp;
                  <a href={`tel:${phone.trim()}`}>{phone.trim()}</a>
                </div>
              )}

              {whatsapp && (
                <div css={{ padding: 5 }}>
                  <span css={{ textTransform: 'capitalize' }}>
                    {t('Whatsapp')}
                  </span>
                  :&nbsp;
                  <a
                    href={`https://api.whatsapp.com/send?phone=${whatsapp}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {whatsapp}
                  </a>
                </div>
              )}

              {email && (
                <div css={{ padding: 5 }}>
                  <span css={{ textTransform: 'capitalize' }}>
                    {t('email')}
                  </span>
                  :&nbsp;
                  <a href={`mailto:${email.trim()}`}>{email.trim()}</a>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </LayoutComponent>
  )
}

export default ContactPage
