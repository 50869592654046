/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import Modal from '@waiterio/components/Modal.js'
import ModalHeader from '@waiterio/components/ModalHeader.js'

const ErrorDialog = ({ close, confirm, locale, message, title }) => {
  const { t } = useTranslation(locale)

  if (message && message.trim().length === 0) {
    message = null
  }

  return (
    <Modal close={close}>
      <ModalHeader testId="error-dialog-title">
        {title || t('Error')}
      </ModalHeader>
      <div
        data-testid="error-dialog-message"
        css={{ display: 'flex', color: '#ababab', minHeight: 80 }}
      >
        <div
          css={{
            alignSelf: 'center',
            width: '100%',
            textAlign: 'center',
            whiteSpace: 'pre-line',
            padding: '14.5px',
            overflow: 'hidden',
            wordWrap: 'break-word',
            userSelect: 'text',
            cursor: 'text',
          }}
        >
          {message || t('An error occurred')}
        </div>
      </div>
      <div
        css={{
          fontSize: 18,
          fontWeight: '200',
          backgroundColor: 'var(--color-primary)',
          color: 'white',
          padding: '10px 50px',
          cursor: 'pointer',
          textAlign: 'center',
          lineHeight: '28px',
          opacity: 0.5,
          ':hover, :focus': {
            backgroundColor: 'var(--color-primary)',
            opacity: 1,
          },
          '@media (max-width: 992px)': {
            backgroundColor: 'var(--color-primary)',
            opacity: 1,
          },
        }}
        onClick={event => {
          event.preventDefault()
          close()
        }}
      >
        {confirm || t('Okay')}
      </div>
    </Modal>
  )
}

export default ErrorDialog
