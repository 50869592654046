/* Copyright 2013 - 2024 Waiterio LLC */
import { createSelector } from 'reselect'
import values from 'ramda/src/values.js'
import Itemstamp from '@waiterio/model/Itemstamp.js'
import MealHelper from '@waiterio/shared/MealHelper.js'

const account = context => context.data.restaurant?.account
const meals = context => context.data.meals

export const mealIdProps = (context, props) => props?.mealId

export const mealsSelector = createSelector([meals], meals => values(meals))

export const accountSelector = createSelector(
  [account],
  account => account, // ? new Account(account) : null
)

export const currentMealSelector = createSelector(
  [mealsSelector, mealIdProps],
  (meals, mealId) => meals.find(meal => meal._id === mealId),
)

export const orderStatusSelector = createSelector(
  [currentMealSelector],
  meal => {
    if (meal) {
      const itemstampStatus = Itemstamp.Status
      let orderStatus = itemstampStatus.ANNOTATED
      const mealHelper = new MealHelper(meal)

      if (
        mealHelper.hasSomeItemstampWithThisStatus(itemstampStatus.REQUESTED)
      ) {
        orderStatus = itemstampStatus.REQUESTED
      } else if (
        mealHelper.hasSomeItemstampWithThisStatus(itemstampStatus.ORDERED)
      ) {
        orderStatus = itemstampStatus.ORDERED
      } else if (
        mealHelper.hasSomeItemstampWithThisStatus(itemstampStatus.COOKING)
      ) {
        orderStatus = itemstampStatus.COOKING
      } else if (
        mealHelper.hasSomeItemstampWithThisStatus(itemstampStatus.READY)
      ) {
        orderStatus = itemstampStatus.READY
      } else if (
        mealHelper.hasSomeItemstampWithThisStatus(itemstampStatus.SERVED)
      ) {
        orderStatus = itemstampStatus.SERVED
      } else if (
        mealHelper.hasSomeItemstampWithThisStatus(itemstampStatus.CANCELLED)
      ) {
        orderStatus = itemstampStatus.CANCELLED
      }

      return orderStatus
    }
  },
)

export const paidSelector = createSelector([currentMealSelector], meal => {
  let paid = false

  if (meal) {
    const mealHelper = new MealHelper(meal)
    paid = mealHelper.areAllItemsPaid()
  }

  return paid
})
