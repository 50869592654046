/* Copyright 2013 - 2024 Waiterio LLC */
import { useQuery } from '@tanstack/react-query'
import fetchMenu from './fetchMenu.js'

export default function useMenu(restaurantId) {
  return useQuery({
    queryKey: ['menus', restaurantId],
    queryFn: () => fetchMenu(restaurantId),
    enabled: !!restaurantId,
  })
}
