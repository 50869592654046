/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { Link } from 'react-router-dom'
import { useTranslation } from '@multilocale/react/index.js'
import useMenu from '../useMenu.js'
import useRestaurant from '../useRestaurant.js'
import useSubdomain from '../useSubdomain.js'

const navigation = css({
  display: 'flex',
  alignTtems: 'center',
  position: 'fixed',
  right: 0,
  left: 0,
  top: 0,
  width: '100%',
  color: 'white',
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: '10px 100px',
  transition: 'all .5s ease-in-out',
  backgroundColor: 'var(--color-primary)',
  zIndex: 1000,
})

const nameLink = css({
  color: 'white',
  cursor: 'pointer',
  fontSize: 20,
  fontWeight: 800,
  overflow: 'hidden',
  padding: '10px 20px',
  position: 'relative',
  textDecoration: 'none',
  textOverflow: 'ellipsis',
  ':hover': {
    color: 'white',
    ':after': {
      width: 'calc(100% - 30px);',
    },
  },
  ':after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 0,
    height: 2,
    margin: '0 15px',
    backgroundColor: 'white',
    transition: 'width .3s',
  },
})

const links = css({
  display: 'flex',
  listStyleType: 'none',
  fontSize: 20,
  lineHeight: '26px',
  margin: 0,
  padding: 0,
})

const link = css({
  color: 'white',
  display: 'block',
  height: 46,
  backgroundColor: 'rgba(0, 0, 0, 0)',
  cursor: 'pointer',
  fontSize: 15,
  fontWeight: 400,
  letterSpacing: '1px',
  overflow: 'hidden',
  padding: '10px 20px',
  textDecoration: 'none',
  whiteSpace: 'nowrap',
  position: 'relative',
  ':hover': {
    color: 'white',
    ':after': {
      width: 'calc(100% - 30px);',
    },
  },
  ':after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 0,
    height: 2,
    margin: '0 15px',
    backgroundColor: 'white',
    transition: 'width .3s',
  },
})

const NavigationDesktopComponent = props => {
  const { locale, style } = props
  const { t } = useTranslation(locale)
  const vanityId = useSubdomain()
  const { data: restaurant = {} } = useRestaurant(vanityId)
  const { data: menu = {} } = useMenu(restaurant?._id)

  const { name, phone, deliveryServiceOffered, takeawayServiceOffered } =
    restaurant

  const showOrderOnline =
    menu &&
    phone &&
    (deliveryServiceOffered !== false || takeawayServiceOffered !== false)

  return (
    <nav css={[navigation, style]}>
      <Link css={nameLink} to={`/${locale}`}>
        {name}
      </Link>
      <div css={{ flexGrow: 1 }} />
      <ul css={links}>
        <li>
          <Link css={link} to={`/${locale}/contact`}>
            {t('Contact')}
          </Link>
        </li>
        {menu && (
          <li>
            <Link css={link} to={`/${locale}/menu`}>
              {t('Menu')}
            </Link>
          </li>
        )}
        {showOrderOnline && (
          <li>
            <Link css={link} to={`/${locale}/order`}>
              {t('Order Online')}
            </Link>
          </li>
        )}
      </ul>
    </nav>
  )
}

export default NavigationDesktopComponent
