/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { useContext } from '@monorepo/context/index.js'
import NavigationComponent from './NavigationComponent.js'

const errorMessage = css({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  height: 160,
  fontSize: 36,
  color: 'red',
  padding: 36,
})

const LayoutComponent = props => {
  const { locale, headerStyle, children } = props
  let [context] = useContext()
  const { loading, error } = context

  return (
    <>
      {loading && <div css={{ marginBottom: 30 }} className="loader" />}

      {!loading && (
        <>
          <NavigationComponent locale={locale} style={headerStyle} />

          {children}

          {error && <div css={errorMessage}>{error}</div>}
        </>
      )}
    </>
  )
}

export default LayoutComponent
