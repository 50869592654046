/* Copyright 2013 - 2024 Waiterio LLC */
import { darken, lighten } from 'polished'

const theme2primary = {
  aqua: '#199398',
  blue: '#2765b0',
  brown: '#8d4a16',
  green: '#3fa535',
  orange: '#ec6b06',
  red: '#cc1517',
  yellow: '#c9b604',
}

function generateTheme(themeName) {
  let colorPrimary = theme2primary[themeName] || '#626262'

  return {
    '--color-base': '#626262',
    '--color-primary': colorPrimary,
    '--color-primary-dark': darken(0.08, colorPrimary),
    '--color-primary-darker': darken(0.16, colorPrimary),
    '--color-primary-darkest': darken(0.24, colorPrimary),
    '--color-primary-light': lighten(0.08, colorPrimary),
    '--color-primary-lighter': lighten(0.16, colorPrimary),
    '--color-primary-lightest': lighten(0.24, colorPrimary),
  }
}

export default generateTheme
