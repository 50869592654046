/* Copyright 2013 - 2024 Waiterio LLC */
import checkStringNotEmpty from '@monorepo/validation/checkStringNotEmpty.js'
import countriesStripeConnectEU from './countriesStripeConnectEU.js'
import getStripeBrowserEU from './getStripeBrowserEU.js'
import getStripeBrowserUS from './getStripeBrowserUS.js'

export default function getStripeBrowserByCountry({
  countryCode,
  stripeAccountId,
}) {
  countryCode = checkStringNotEmpty(countryCode)

  let stripe

  if (countriesStripeConnectEU[countryCode]) {
    stripe = getStripeBrowserEU(stripeAccountId)
  } else {
    stripe = getStripeBrowserUS(stripeAccountId)
  }

  return stripe
}
