/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'

const IconRemove = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-remove'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M19.008 13.008h-14.016v-2.016h14.016v2.016z" />
    </svg>
  )
}

export default IconRemove
