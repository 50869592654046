/* Copyright 2013 - 2024 Waiterio LLC */
import format from 'date-fns/format/index.js'

import Discount from '@waiterio/model/Discount.js'
import Itemstamp from '@waiterio/model/Itemstamp.js'
import { DELIVERY, TAKEAWAY } from '@waiterio/model/Meal.js'
import ServiceCharge from '@waiterio/model/ServiceCharge.js'

function MealHelper(meal, unpaid, selectedItemstamps) {
  this.meal = meal

  this.unpaid = unpaid
  this.selectedItemstamps = selectedItemstamps

  this.totalPrice = 0
  this.serviceChargePrice = 0
  this.discountPrice = 0
  this.taxPrice = 0
  this.paid = true
  this.paymentMethods = {}
  this.items = 0
  this.cancelledItems = 0

  this.itemstamps =
    typeof meal.itemstamps === 'object'
      ? Object.values(meal.itemstamps)
      : meal.itemstamps

  this.itemstamps.forEach(itemstamp => {
    if (!itemstamp.paid && itemstamp.status !== Itemstamp.Status.CANCELLED) {
      this.paid = false
    }

    if (itemstamp.status === Itemstamp.Status.CANCELLED) {
      this.cancelledItems += 1
    } else if (
      !this.unpaid ||
      (itemstamp.paid !== true &&
        (!this.selectedItemstamps || this.selectedItemstamps[itemstamp.id]))
    ) {
      this.items += 1

      if (itemstamp.item.price > 0) {
        this.totalPrice += parseFloat(itemstamp.item.price)
      }

      if (
        itemstamp.paymentMethod &&
        !this.paymentMethods[itemstamp.paymentMethod.id]
      ) {
        this.paymentMethods[itemstamp.paymentMethod.id] =
          itemstamp.paymentMethod
      }

      // Add extras
      if (itemstamp.extras && itemstamp.extras.length > 0) {
        itemstamp.extras.forEach(extra => {
          if (
            extra.price > 0 &&
            itemstamp.status !== Itemstamp.Status.CANCELLED
          ) {
            this.totalPrice += parseFloat(extra.price)
          }
        })
      }
    }
  })

  if (
    this.itemstamps.length > 0 &&
    this.itemstamps.length > this.cancelledItems
  ) {
    if (meal.serviceCharge) {
      if (
        ServiceCharge.QuantityType.FIXED === meal.serviceCharge.quantityType
      ) {
        this.serviceChargePrice = meal.serviceCharge.quantity * meal.customers
      } else {
        this.serviceChargePrice =
          (this.totalPrice * meal.serviceCharge.quantity) / 100
      }

      this.totalPrice += this.serviceChargePrice
    }

    if (meal.discount) {
      if (Discount.QuantityType.FIXED === meal.discount.quantityType) {
        this.discountPrice -= meal.discount.quantity
      } else {
        this.discountPrice -= (this.totalPrice * meal.discount.quantity) / 100
      }

      this.totalPrice += this.discountPrice
    }

    if (meal.tax) {
      if (meal.tax.alreadyIncludedInItems) {
        // the tax is calculated on the net price before tax
        this.taxPrice =
          Math.round(
            ((this.totalPrice * (meal.tax.quantity / 100)) /
              (1 + meal.tax.quantity / 100)) *
              100,
          ) / 100
      } else {
        this.taxPrice =
          Math.round(((this.totalPrice * meal.tax.quantity) / 100) * 100) / 100
        this.totalPrice += this.taxPrice
      }
    }
  } else {
    this.paid = false
  }
}

MealHelper.prototype.getOrderName = function getOrderName() {
  let orderName

  if (this.meal.service === DELIVERY) {
    let specifier = ''

    if (!specifier && this.meal.deliveryTime) {
      specifier = format(this.meal.deliveryTime, 'H:mm')
    }

    if (this.meal.customerName) {
      specifier += ' ' + this.meal.customerName
    }

    specifier = specifier.trim()

    orderName = `Delivery ${specifier}`
  } else if (this.meal.service === TAKEAWAY) {
    let specifier = ''

    if (!specifier && this.meal.pickupTime) {
      specifier = format(this.meal.pickupTime, 'H:mm')
    }

    if (this.meal.customerName) {
      specifier += ' ' + this.meal.customerName
    }

    specifier = specifier.trim()

    orderName = `Takeaway ${specifier}`
  } else if (this.meal.table && this.meal.table.length > 0) {
    orderName = `Table ${this.meal.table}`
  } else {
    orderName = `Order ${format(this.meal.creationTime, 'H:mm')}`
  }

  return orderName
}

MealHelper.prototype.getTotalPrice = function getTotalPrice() {
  return this.totalPrice
}

MealHelper.prototype.getServiceChargePrice = function getServiceChargePrice() {
  return this.serviceChargePrice
}

MealHelper.prototype.getDiscountPrice = function getDiscountPrice() {
  return this.discountPrice
}

MealHelper.prototype.getTaxPrice = function getTaxPrice() {
  return this.taxPrice
}

MealHelper.prototype.getItemsCount = function getItemsCount() {
  return this.items
}

MealHelper.prototype.areAllItemsPaid = function areAllItemsPaid() {
  return this.paid
}

MealHelper.prototype.hasSomeItemstampWithThisStatus =
  function hasSomeItemstampWithThisStatus(status) {
    return this.itemstamps.some(itemstamp => itemstamp.status === status)
  }

MealHelper.prototype.getPaymentMethods = function getPaymentMethods() {
  return Object.keys(this.paymentMethods).map(key => this.paymentMethods[key])
}

export default MealHelper
