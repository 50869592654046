/* Copyright 2013 - 2024 Waiterio LLC */
import PushReceiver from './PushReceiver.js'

export default {
  start() {
    PushReceiver.subscribe()
  },

  stop() {},
}
