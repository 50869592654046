/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { Link } from 'react-router-dom'
import { useContext } from '@monorepo/context/index.js'
import colMd10 from '@waiterio/styles/bootstrap/colMd10.js'
import colLg8 from '@waiterio/styles/bootstrap/colLg8.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import { useTranslation } from '@multilocale/react/index.js'
import LayoutComponent from '../components/LayoutPublicComponent.js'
import MapSection from './MapSection.js'
import MenuSection from './MenuSection.js'
import coverPhotoSelector from '../selectors/coverPhotoSelector.js'
import useRestaurant from '../useRestaurant.js'
import useSubdomain from '../useSubdomain.js'
import { activeMealSelector } from '../selectors/OrderSelectors.js'
import languages from '../languages.js'

const siteHeading = css`
  color: white;
  padding: 200px 0 150px;
  text-align: center;
  @media (min-width: 768px) {
    padding: 200px 0;
  }
`

const siteTitle = css({
  display: 'block',
  lineHeight: '46px',
  fontSize: 40,
  margin: 0,
  '@media (max-width: 767px)': {
    fontSize: 34,
  },
})

const orderButton = css({
  display: 'flex',
  justifyContent: 'center',
  lineHeight: '26px',
  fontSize: 24,
  fontWeight: 300,
  marginTop: 50,
})

export const paths = languages.map(locale => ({
  path: `/${locale}/`,
  props: {
    locale,
  },
}))

export const Head = ({ locale }) => {
  const { t } = useTranslation(locale)
  const vanityId = useSubdomain()
  const { data: restaurant = {} } = useRestaurant(vanityId)

  const url = `/${locale}/`
  const canonical = restaurant.vanityId
    ? `https://${restaurant.vanityId}.waiterio.com${url}`
    : null

  const title = restaurant.name || t('Restaurant')
  const description = restaurant.description || t('Restaurant')

  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={restaurant.coverPhotoUr} />
      <meta property="og:site_name" content={t('Successful Restaurant')} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="article" />
      <meta property="og:url" content={canonical} />
      <meta property="og:image" content={restaurant.coverPhotoUr} />
      <meta name="application-name" content={title} />
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      {canonical && <link rel="canonical" href={canonical} />}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org/',
            '@type': 'Restaurant',
            name: restaurant.name,
            description: restaurant.description,
            email: restaurant.email,
            image: restaurant.coverPhotoUrl,
            telephone: restaurant.phone,
            hasMenu: `https://${restaurant.rvanityId}.waiterio.com/${locale}/menu/`,
            address: {
              '@type': 'PostalAddress',
              addressCountry: restaurant.countryCode,
              addressRegion: restaurant.state,
              addressLocality: restaurant.city,
              postalCode: restaurant.zipcode,
              streetAddress: restaurant.street,
            },
          }),
        }}
      />
    </>
  )
}

const HomePage = props => {
  let { locale = 'en' } = props
  const { t } = useTranslation(locale)
  const vanityId = useSubdomain()
  const { data: restaurant = {} } = useRestaurant(vanityId)
  const {
    name,
    phone,
    street,
    deliveryServiceOffered,
    takeawayServiceOffered,
  } = restaurant
  const { menu } = restaurant

  let [context] = useContext()
  let coverPhotoUrl = coverPhotoSelector(context)
  const activeMeal = activeMealSelector(context)
  const showOrderOnline =
    menu &&
    phone &&
    !activeMeal &&
    (deliveryServiceOffered !== false || takeawayServiceOffered !== false)
  let [didScroll, setDidScroll] = useState(false)
  let [headerStyle, setHeaderStyle] = useState({
    backgroundColor: 'transparent',
    boxShadow: 'none',
  })

  useEffect(() => {
    function onScroll() {
      if (window.pageYOffset > 100 && !didScroll) {
        setDidScroll(true)
        setHeaderStyle({
          boxShadow: '0 0 30px 0 rgba(0,0,0,.1)',
        })
      } else if (window.pageYOffset < 100 && didScroll) {
        setDidScroll(false)
        setHeaderStyle({
          backgroundColor: 'transparent',
          boxShadow: 'none',
        })
      }
    }

    window.addEventListener('scroll', onScroll)

    return function cleanup() {
      window.removeEventListener('scroll', onScroll)
    }
  }, [didScroll])

  return (
    <LayoutComponent locale={locale} headerStyle={headerStyle}>
      <header css={{ position: 'relative', maxHeight: 480 }}>
        <picture>
          <source
            type="image/webp"
            media="(min-width: 1920px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 960px)"
            srcSet={coverPhotoUrl?.replace('.jpg', '-1920w.webp')}
          />
          <source
            type="image/webp"
            media="(min-width: 1600px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 800px)"
            srcSet={coverPhotoUrl?.replace('.jpg', '-1600w.webp')}
          />
          <source
            type="image/webp"
            media="(min-width: 1366px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 683px)"
            srcSet={coverPhotoUrl?.replace('.jpg', '-1366w.webp')}
          />
          <source
            type="image/webp"
            media="(min-width: 1024px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 512px)"
            srcSet={coverPhotoUrl?.replace('.jpg', '-1024w.webp')}
          />
          <source
            type="image/webp"
            media="(min-width: 768px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 384px)"
            srcSet={coverPhotoUrl?.replace('.jpg', '-768w.webp')}
          />
          <source
            type="image/webp"
            srcSet={coverPhotoUrl?.replace('.jpg', '-640w.webp')}
          />

          <source
            type="image/jpeg"
            media="(min-width: 1920px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 960px)"
            srcSet={coverPhotoUrl?.replace('.jpg', '-1920w.jpg')}
          />
          <source
            type="image/jpeg"
            media="(min-width: 1600px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 800px)"
            srcSet={coverPhotoUrl?.replace('.jpg', '-1600w.jpg')}
          />
          <source
            type="image/jpeg"
            media="(min-width: 1366px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 683px)"
            srcSet={coverPhotoUrl?.replace('.jpg', '-1366w.jpg')}
          />
          <source
            type="image/jpeg"
            media="(min-width: 1024px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 512px)"
            srcSet={coverPhotoUrl?.replace('.jpg', '-1024w.jpg')}
          />
          <source
            type="image/jpeg"
            media="(min-width: 768px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 384px)"
            srcSet={coverPhotoUrl?.replace('.jpg', '-768w.jpg')}
          />
          <source
            type="image/jpeg"
            srcSet={coverPhotoUrl?.replace('.jpg', '-640w.jpg')}
          />

          <img
            css={{
              height: '100%',
              position: 'absolute',
              top: 0,
              padding: 0,
              objectFit: 'cover',
            }}
            src={coverPhotoUrl}
            alt={name}
          />
        </picture>

        <div
          css={{
            position: 'absolute',
            left: 0,
            top: 0,
            height: '100%',
            width: '100%',
            backgroundColor: 'rgb(33, 37, 41)',
            opacity: 0.75,
          }}
        />
        <div css={container}>
          <div css={row}>
            <div
              css={[
                colMd10,
                colLg8,
                { marginLeft: 'auto', marginRight: 'auto' },
              ]}
            >
              <div css={siteHeading}>
                <h2 css={siteTitle}>{name}</h2>
                {showOrderOnline && (
                  <div css={orderButton}>
                    <Link
                      to={`/${locale}/order`}
                      css={{
                        background: 'white',
                        borderRadius: 4,
                        border: '1px solid white',
                        color: 'var(--color-base)',
                        fontSize: 16,
                        fontWeight: 'bold',
                        margin: 5,
                        padding: '15px 20px 15px 20px',
                        cursor: 'pointer',
                        textDecoration: 'none',
                        ':hover': {
                          border: '1px solid var(--color-primary)',
                          color: 'var(--color-primary)',
                        },
                      }}
                    >
                      {t('Order Online')}
                    </Link>
                  </div>
                )}
                {activeMeal && (
                  <div css={orderButton}>
                    <Link
                      to={`/${locale}/orders/${activeMeal._id}`}
                      css={{
                        background: 'white',
                        borderRadius: 4,
                        border: '1px solid white',
                        color: 'var(--color-base)',
                        fontSize: 16,
                        fontWeight: 'bold',
                        margin: 5,
                        padding: '15px 20px 15px 20px',
                        cursor: 'pointer',
                        textDecoration: 'none',
                        textTransform: 'capitalize',
                        ':hover': {
                          border: '1px solid var(--color-primary)',
                          color: 'var(--color-primary)',
                        },
                      }}
                    >
                      {t('View order')}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>

      <MenuSection locale={locale} />

      {street && <MapSection locale={locale} />}
    </LayoutComponent>
  )
}

export default HomePage
