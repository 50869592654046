/* Copyright 2013 - 2024 Waiterio LLC */
import { css } from '@emotion/react'

export default css`
  label: heading-title;
  font-size: 36px;
  line-height: 44px;
  margin-bottom: 25px;
  text-align: center;
  text-transform: capitalize;
  position: relative;

  :after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 45%;
    top: 100%;
    height: 10px;
    border-bottom: 2px solid black;
    width: calc(10%);
  }

  @media (max-width: 767px) {
    font-size: 30px;
  }
`
