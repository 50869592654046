/* Copyright 2013 - 2024 Waiterio LLC */
import post from '@waiterio/api-client/post.js'

export default function addPaymentIntent({
  amount,
  captureMethod,
  currency,
  countryCode,
  stripeAccountId,
}) {
  return post({
    url: 'stripe/payment-intent',
    body: {
      amount,
      captureMethod,
      countryCode,
      currency,
      stripeAccountId,
    },
  })
}
