/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import colSm12 from '@waiterio/styles/bootstrap/colSm12.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import LayoutComponent from '../components/LayoutPublicComponent.js'
import headingContainer from '../styles/headingContainer.js'
import headingTitle from '../styles/headingTitle.js'
import languages from '../languages.js'

export const paths = languages
  .map(locale => ({
    path: `/${locale}/not-found/`,
    props: {
      locale,
    },
  }))
  .concat({
    path: '*',
  })

export const Head = () => <title>Page not found</title>

const NotFoundPage = props => {
  let { locale = 'en' } = props

  return (
    <LayoutComponent locale={locale}>
      <div css={{ background: '#626262', height: 62 }} />

      <div css={[container, { marginTop: 40, marginBottom: 40 }]}>
        <div css={row}>
          <div css={colSm12}>
            <div css={headingContainer}>
              <h3 css={headingTitle}>404 ERRORS</h3>
            </div>
          </div>
        </div>

        <div
          css={{
            maxWidth: 500,
            position: 'relative',
            textAlign: 'center',
            margin: 'auto',
            fontSize: 25,
            fontWeight: '200',
          }}
        >
          <div
            css={{
              fontSize: 180,
              fontWeight: 700,
              lineHeight: '180px',
              color: '#333333',
            }}
          >
            404
          </div>
          <div
            css={{
              fontSize: 24,
              fontWeight: 600,
              lineHeight: '24px',
              margin: '20px 0px',
            }}
          >
            Page not found
          </div>
          <a
            href="/"
            css={{
              backgroundColor: '#626262',
              color: 'white',
              display: 'inline-block',
              fontSize: 18,
              fontWeight: 600,
              lineHeight: '28px',
              padding: 10,
              ':hover': {
                color: 'white',
                backgroundColor: 'black',
              },
            }}
          >
            Go back to the home page
          </a>
        </div>
      </div>
    </LayoutComponent>
  )
}

export default NotFoundPage
