/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import Modal from '@waiterio/components/Modal.js'
import ModalHeader from '@waiterio/components/ModalHeader.js'

const loader = css({
  top: '50%',
  margin: '0 auto',

  borderRadius: '50%',
  width: '5em',
  height: '5em',

  fontSize: '5px',
  position: 'relative',
  textIndent: '-9999em',
  borderTop: '1.1em solid rgba(0, 0, 0, 0.2)',
  borderRight: '1.1em solid rgba(0, 0, 0, 0.2)',
  borderBottom: '1.1em solid rgba(0, 0, 0, 0.2)',
  borderLeft: '1.1em solid var(--color-primary)',
  transform: 'translateZ(0)',
  animation: 'rotation 1.1s infinite linear',
})

const LoadingDialog = ({ message, title }) => (
  <Modal>
    <ModalHeader testId="loading-dialog-title">{title}</ModalHeader>
    <div css={{ display: 'flex', color: '#626262', minHeight: 80 }}>
      <div
        css={[
          loader,
          {
            alignSelf: 'center',
            height: 40,
            width: 40,
            minWidth: 40,
            margin: 20,
          },
        ]}
        style={{ minHeight: 40 }}
      />
      <div
        data-testid="loading-dialog-message"
        css={{
          display: 'flex',
          alignSelf: 'center',
          alignItems: 'center',
          textAlign: 'left',
          width: 200,
          whiteSpace: 'pre-line',
          padding: 16,
          paddingLeft: 8,
        }}
      >
        <span>{message}</span>
      </div>
    </div>
  </Modal>
)

export default LoadingDialog
