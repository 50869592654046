/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect'
import addDays from 'date-fns/add_days/index.js'
import addMinutes from 'date-fns/add_minutes/index.js'
import isToday from 'date-fns/is_today/index.js'
import startOfDay from 'date-fns/start_of_day/index.js'
import { DELIVERY, TAKEAWAY } from '@waiterio/model/Meal.js'

const localeProps = (context, props) => props?.locale
const mealProps = (context, props) => props?.meal
const translateProps = (context, props) => props?.translate

export const timesSelector = createSelector(
  [mealProps, localeProps, translateProps],
  (meal, locale, t) => {
    let deliveryDate
    let pickupDate
    let dateValues = []
    let dateLabels = []
    let timeValues = []
    let timeLabels = []

    if (meal) {
      let isDelivery = meal.service === DELIVERY
      let isTakeaway = meal.service === TAKEAWAY

      const todayDayOfMonth = new Date().getDate()
      deliveryDate = startOfDay(
        meal.deliveryTime || meal.creationTime,
      ).getTime()
      pickupDate = startOfDay(meal.pickupTime || meal.creationTime).getTime()

      let time

      if (isDelivery) {
        time = meal.deliveryTime
      } else if (isTakeaway) {
        time = meal.pickupTime
      }

      time = time || meal.creationTime

      for (let i = 0; i < 31; i += 1) {
        let date = startOfDay(meal.creationTime)
        date = addDays(date, i)
        let value = date.getTime()
        let label = t('Today')
        if (todayDayOfMonth !== date.getDate()) {
          label = date.toLocaleDateString(locale, {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
          })
        }
        dateValues.push(value)
        dateLabels.push(label)
      }

      if (isToday(time)) {
        let creationTimeMinutes = new Date().getMinutes()

        let startMinutes = Math.ceil(creationTimeMinutes / 5) * 5

        for (let i = 0; i < 12; i += 1) {
          let date = new Date(time)
          date.setMinutes(startMinutes + i * 5, 0, 0)

          if (isToday(date)) {
            let value = date.getTime()
            let label = date.toLocaleTimeString(locale, {
              hour: 'numeric',
              minute: 'numeric',
            })
            timeValues.push(value)
            timeLabels.push(label)
          }
        }

        for (let i = 0; i < 12; i += 1) {
          let date = new Date(time)
          let startMinutes = Math.ceil(creationTimeMinutes / 15) * 15 + 60
          date.setMinutes(startMinutes + i * 15, 0, 0)
          if (isToday(date)) {
            let value = date.getTime()
            let label = date.toLocaleTimeString(locale, {
              hour: 'numeric',
              minute: 'numeric',
            })

            timeValues.push(value)
            timeLabels.push(label)
          }
        }

        for (let i = 0; i < 40; i += 1) {
          let date = new Date(time)
          let startMinutes = Math.ceil(creationTimeMinutes / 30) * 30 + 240
          date.setMinutes(startMinutes + i * 30, 0, 0)
          if (isToday(date)) {
            let value = date.getTime()
            let label = date.toLocaleTimeString(locale, {
              hour: 'numeric',
              minute: 'numeric',
            })

            timeValues.push(value)
            timeLabels.push(label)
          }
        }
      } else {
        let date = startOfDay(time)
        for (let i = 0; i < 96; i += 1) {
          date = addMinutes(date, 15)
          let value = date.getTime()
          let label = date.toLocaleTimeString(locale, {
            hour: 'numeric',
            minute: 'numeric',
          })

          timeValues.push(value)
          timeLabels.push(label)
        }
      }
    }

    return {
      dateLabels,
      dateValues,
      deliveryDate,
      pickupDate,
      timeLabels,
      timeValues,
    }
  },
)
