/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState } from 'react'
import { css, jsx } from '@emotion/react'
import { Link } from 'react-router-dom'
import { useTranslation } from '@multilocale/react/index.js'
import useMenu from '../useMenu.js'
import useRestaurant from '../useRestaurant.js'
import useSubdomain from '../useSubdomain.js'

const navigation = css({
  backgroundColor: 'var(--color-primary)',
  display: 'flex',
  position: 'fixed',
  justifyContent: 'space-between',
  top: 0,
  zIndex: 2000,
  width: '100%',
  height: 64,
})

const drawerContainer = css({
  cursor: 'pointer',
  height: 64,
  padding: '17px 20px',
  width: 64,
})

const drawerIcon = expanded =>
  css({
    width: 24,
    position: 'absolute',
    top: '40%',

    ':after, :before': {
      content: "''",
      width: 24,
      height: 2,
      position: 'absolute',
      backgroundColor: 'white',
      transition: '.3s ease-in-out',
      top: expanded ? 5 : 0,
      transform: expanded ? 'rotate(45deg)' : 0,
    },

    ':before': {
      top: expanded ? 5 : 10,
      transform: expanded ? 'rotate(-45deg)' : 0,
    },
  })

const links = expanded =>
  css({
    overflow: 'hidden',
    height: `${expanded ? '100%' : '0px'}`,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'var(--color-primary)',
    position: 'fixed',
    top: 64,
    left: 0,
    zIndex: 1000,
    transition: 'height 0.4s ease-in-out',
  })

const link = css({
  color: 'white',
  fontSize: 16,
  position: 'relative',
  height: 45,
  cursor: 'pointer',
  borderBottom: 'solid',
  borderBottomWidth: 1,
  borderBottomColor: '#DCDCDC',
  padding: 15,
  margin: '0px 20px',
  textDecoration: 'none',
  paddingLeft: 0,
  ':hover': {
    backgroundColor: 'var(--color-primary)',
    color: 'white',
  },
})

const NavigationSmartphoneComponent = props => {
  const { locale } = props
  const vanityId = useSubdomain()
  const { data: restaurant = {} } = useRestaurant(vanityId)
  const { data: menu = {} } = useMenu(restaurant?._id)
  const { name, phone, deliveryServiceOffered, takeawayServiceOffered } =
    restaurant
  const { t } = useTranslation(locale)
  const [expanded, setExpanded] = useState(false)

  const toggleDrawer = () => {
    setExpanded(prevState => !prevState)
  }

  return (
    <nav css={navigation}>
      <div css={drawerContainer} onClick={toggleDrawer}>
        <div css={drawerIcon(expanded)} data-testid="icon-drawer" />
      </div>

      <div css={links(expanded)}>
        <Link css={link} to={`/${locale}`}>
          {t('Home')}
        </Link>
        <Link css={link} to={`/${locale}/contact`}>
          {t('Contact')}
        </Link>
        {menu && (
          <Link css={link} to={`/${locale}/menu`}>
            {t('Menu')}
          </Link>
        )}
        {/* eslint-disable indent */}
        {menu &&
          phone &&
          (deliveryServiceOffered !== false ||
            takeawayServiceOffered !== false) && (
            <Link css={link} to={`/${locale}/order`}>
              {t('Order Online')}
            </Link>
          )}
        {/* eslint-enable indent */}
      </div>

      <div
        css={{
          fontWeight: 800,
          margin: 'auto',
          fontSize: 20,
          color: 'white',
          textTransform: 'capitalize',
        }}
      >
        {name}
      </div>

      <div css={{ width: 64 }} />
    </nav>
  )
}

export default NavigationSmartphoneComponent
