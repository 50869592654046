/* Copyright 2013 - 2024 Waiterio LLC */
import getRestaurantRest from '@waiterio/api-client/getRestaurant.js'
import NotFoundError from '@monorepo/errors/NotFoundError.js'

export default async function getRestaurant(idOrVanityId) {
  let restaurant = await getRestaurantRest(idOrVanityId)
  if (!restaurant) {
    throw new NotFoundError('restaurant not found')
  }

  if (restaurant.suspended) {
    throw new Error(`restaurant is suspended (${restaurant.suspended})`)
  }

  return restaurant
}
