/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect } from 'react'
import { jsx } from '@emotion/react'
import { useParams } from 'react-router-dom'
import { useTranslation } from '@multilocale/react/index.js'
import { useContext } from '@monorepo/context/index.js'
import IconPhone from '@stiloso/icons/IconPhone.js'
import { TAKEAWAY, DELIVERY } from '@waiterio/model/Meal.js'
import Itemstamp from '@waiterio/model/Itemstamp.js'
import colLg4 from '@waiterio/styles/bootstrap/colLg4.js'
import colMd5 from '@waiterio/styles/bootstrap/colMd5.js'
import colSm12 from '@waiterio/styles/bootstrap/colSm12.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import LayoutComponent from '../components/LayoutPrivateComponent.js'
import PusherManager from '../services/PusherManager.js'
import PushNotificationHandler from '../services/PushNotificationHandler.js'
import {
  currentMealSelector,
  orderStatusSelector,
  paidSelector,
} from '../selectors/AppSelectors.js'
import {
  itemsSelector,
  totalPriceFormattedSelector,
  itemsCountSelector,
  taxPriceSelector,
} from '../selectors/PaymentSelectors.js'
import headingContainer from '../styles/headingContainer.js'
import headingTitle from '../styles/headingTitle.js'
import useMenu from '../useMenu.js'
import useRestaurant from '../useRestaurant.js'
import useSubdomain from '../useSubdomain.js'
import languages from '../languages.js'

export const paths = languages.map(locale => ({
  path: `/${locale}/orders/:mealId`,
  props: {
    locale,
  },
}))

export const Head = ({ locale }) => {
  const { t } = useTranslation(locale)
  let [context] = useContext()

  const restaurant = context?.data?.restaurant ?? {}

  const title = `${t('Your order')} | ${restaurant.name || t('Restaurant')}`

  return <title>{title}</title>
}

const ConfirmationPage = props => {
  const { locale = 'en' } = props
  const { mealId } = useParams()
  let [context] = useContext()
  const vanityId = useSubdomain()
  const { data: restaurant = {} } = useRestaurant(vanityId)
  const { data: menu = {} } = useMenu(restaurant?._id)
  const meal = currentMealSelector(context, { mealId })
  const items = itemsSelector(context, { meal, menu })
  const itemsCount = itemsCountSelector(context, { meal })
  const totalPrice = totalPriceFormattedSelector(context, { meal, menu })
  const taxPrice = taxPriceSelector(context, { meal, menu })
  const orderStatus = orderStatusSelector(context, { mealId })
  const paid = paidSelector(context, { mealId })

  useEffect(() => {
    PusherManager.start()
  }, [])

  useEffect(() => {
    PushNotificationHandler.askUserPermission()
  }, [])

  const { t } = useTranslation(locale)

  const { name, phone, street, whatsapp } = restaurant || {}

  const { customerName, pickupTime, service } = meal || {}

  const isTakeaway = service === TAKEAWAY
  const isDelivery = service === DELIVERY

  let whatsappText = t('Hi, can you confirm the order?')

  if (isTakeaway) {
    whatsappText += ` ${t('Takeaway')}`
    whatsappText += ` ${t('Customer')} ${customerName}`
    whatsappText += ` ${t('Pickup time')} ${new Date(
      pickupTime,
    ).toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' })}`
    whatsappText += ' Waiterio POS'
  }

  const orderRequested = orderStatus === Itemstamp.Status.REQUESTED
  const orderAccepted = orderStatus === Itemstamp.Status.ORDERED
  const orderCooking = orderStatus === Itemstamp.Status.COOKING
  const orderReady = orderStatus === Itemstamp.Status.READY
  const orderServed = orderStatus === Itemstamp.Status.SERVED
  const orderCancelled = orderStatus === Itemstamp.Status.CANCELLED
  const orderPaid = paid

  return (
    <LayoutComponent locale={locale}>
      <div css={{ background: '#626262', height: 62 }} />

      <div css={[container, { marginTop: 40, marginBottom: 40 }]}>
        <div css={row}>
          <div css={colSm12}>
            <div css={headingContainer}>
              <h3 css={headingTitle}>{t('Your order')}</h3>
            </div>
          </div>
        </div>
        {items && (
          <div css={row}>
            <div
              css={[
                colMd5,
                colLg4,
                {
                  padding: 15,
                  margin: '15px auto',
                  maxHeight: 500,
                  height: '100%',
                  textAlign: 'center',
                  border:
                    (orderRequested && '1px solid #fdad5c') ||
                    ((orderAccepted || orderCooking) && '1px solid #3b5998') ||
                    (orderReady && '1px solid #FFB6C1') ||
                    ((orderServed || paid) && '1px solid #5cb85c') ||
                    (orderCancelled && '1px solid red'),
                },
              ]}
            >
              {orderRequested && (
                <>
                  <div
                    css={{
                      fontWeight: 'bold',
                      padding: 8,
                      color: orderRequested && '#fdad5c',
                    }}
                  >
                    {orderRequested && t('Your order is awaiting confirmation')}
                  </div>

                  {phone && (
                    <>
                      <div css={{ padding: 8 }}>
                        {t('Call us to complete the order')}
                      </div>
                      <a
                        href={`tel:${phone}`}
                        css={{
                          display: 'block',
                          fontWeight: 'bold',
                          padding: 8,
                        }}
                      >
                        {phone}
                      </a>
                    </>
                  )}
                  <IconPhone css={{ padding: 8, width: 50, height: 50 }} />
                  {whatsapp && (
                    <>
                      <div css={{ padding: 8 }}>
                        {t(
                          'Write us on Whatsapp to complete the order',
                          locale,
                        )}
                      </div>
                      <a
                        href={`https://api.whatsapp.com/send?phone=${whatsapp}&text=${encodeURI(
                          whatsappText,
                        )}`}
                        css={{
                          display: 'block',
                          fontWeight: 'bold',
                          padding: 8,
                        }}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {whatsapp}
                      </a>
                    </>
                  )}
                </>
              )}

              {!orderRequested && (
                <>
                  <div
                    css={{
                      fontWeight: 'bold',
                      padding: 8,
                      display: 'flex',
                      justifyContent: 'space-between',
                      color:
                        (orderRequested && '#fdad5c') ||
                        ((orderAccepted || orderCooking) && '#3b5998') ||
                        (orderReady && '#FFB6C1') ||
                        ((orderServed || orderPaid) && '#5cb85c') ||
                        (orderCancelled && 'red'),
                    }}
                  >
                    <span>{t('Status')}</span>
                    <span>
                      {orderAccepted && t('Confirmed')}
                      {orderCooking && t('Being prepared')}
                      {orderReady && isTakeaway && t('Ready for pickup')}
                      {orderServed && isTakeaway && t('Picked up')}
                      {orderReady && isDelivery && t('On the way to deliver')}
                      {orderServed && isDelivery && t('Delivered')}
                      {orderPaid && t('Completed')}
                      {orderCancelled && t('Cancelled')}
                    </span>
                  </div>

                  <div
                    css={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: 8,
                      textTransform: 'capitalize',
                    }}
                  >
                    <span css={{ textAlign: 'left', marginRight: 8 }}>
                      {t('Customer')}
                    </span>
                    <span css={{ textAlign: 'right' }}>{customerName}</span>
                  </div>
                  {isTakeaway && (
                    <>
                      <div
                        css={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: 8,
                        }}
                      >
                        <span css={{ textAlign: 'left', marginRight: 8 }}>
                          {t('Takeaway')}
                        </span>
                        <span css={{ textAlign: 'right' }}>{name}</span>
                      </div>
                      <div
                        css={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: 8,
                        }}
                      >
                        <span css={{ textAlign: 'left', marginRight: 8 }}>
                          {t('Pickup time')}
                        </span>
                        <span css={{ textAlign: 'right' }}>
                          {new Date(meal.pickupTime).toLocaleTimeString(
                            locale,
                            { hour: '2-digit', minute: '2-digit' },
                          )}
                        </span>
                      </div>
                    </>
                  )}
                  {isDelivery && (
                    <>
                      <div
                        css={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: 8,
                        }}
                      >
                        <span css={{ textAlign: 'left', marginRight: 8 }}>
                          {t('Delivery')}
                        </span>
                        <span css={{ textAlign: 'right' }}>
                          {restaurant.name}
                        </span>
                      </div>
                      <div
                        css={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: 8,
                        }}
                      >
                        <span css={{ textAlign: 'left', marginRight: 8 }}>
                          {t('Delivery time')}
                        </span>
                        <span css={{ textAlign: 'right' }}>
                          {new Date(meal.deliveryTime).toLocaleTimeString(
                            locale,
                            { hour: '2-digit', minute: '2-digit' },
                          )}
                        </span>
                      </div>
                      <div
                        css={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: 8,
                          textTransform: 'capitalize',
                        }}
                      >
                        <span css={{ textAlign: 'left', marginRight: 8 }}>
                          {t('Delivery')} {t('Address')}
                        </span>
                        <span css={{ textAlign: 'right' }}>
                          {meal.customerAddress}
                        </span>
                      </div>
                    </>
                  )}

                  {street && (
                    <div
                      css={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: 8,
                        textTransform: 'capitalize',
                      }}
                    >
                      <span css={{ textAlign: 'left', marginRight: 8 }}>
                        {name} {t('Address')}
                      </span>
                      <span css={{ textAlign: 'right' }}>{street}</span>
                    </div>
                  )}
                </>
              )}
            </div>

            <div
              css={[
                colMd5,
                colLg4,
                {
                  maxHeight: 500,
                  height: '100%',
                  margin: '15px auto',
                  border: '1px solid #e8e8e8',
                },
              ]}
            >
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingLeft: 8,
                  paddingRight: 8,
                  margin: '15px 0px',
                }}
              >
                <span css={{ fontWeight: 'bold' }}>{t('Order')}</span>
                <span>
                  {itemsCount} {t('Items')}
                </span>
              </div>

              <div css={{ maxHeight: 250, height: '100%', overflowY: 'auto' }}>
                {items.map(item => (
                  <div
                    key={item.itemstampIds[0]}
                    css={{
                      textDecoration:
                        orderStatus === 'CANCELLED' && 'line-through',
                      display: 'flex',
                      justifyContent: 'space-between',
                      fontWeight: '200',
                      marginTop: 20,
                      paddingLeft: 8,
                      paddingRight: 8,
                      ':first-of-type': {
                        marginTop: 0,
                      },
                      ':last-of-child': {
                        marginTop: 0,
                      },
                    }}
                  >
                    <div css={{ display: 'flex', flex: '25%' }}>
                      <div
                        css={{
                          fontSize: 12,
                          marginTop: 2,
                          maxWidth: 25,
                          width: '100%',
                          textAlign: 'left',
                        }}
                      >
                        {item.quantity} x
                      </div>
                    </div>
                    <div css={{ flex: '75%' }}>
                      {item.name}

                      {item.extras.map(
                        extra =>
                          extra.quantity && (
                            <div css={{ marginTop: 2 }} key={extra.id}>
                              + {extra.name}
                            </div>
                          ),
                      )}

                      {item.note && (
                        <div css={{ marginTop: 2 }}>! {item.note}</div>
                      )}
                    </div>
                    <div css={{ flex: '25%', textAlign: 'right' }}>
                      {item.priceWithExtra}
                    </div>
                  </div>
                ))}
              </div>

              {meal.tax && !meal.tax.alreadyIncludedInItems && (
                <div
                  css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 30,
                    marginBottom: 15,
                    fontWeight: '200',
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}
                >
                  <span>
                    {meal.tax.name} {meal.tax.quantity}%
                  </span>
                  <span>{taxPrice}</span>
                </div>
              )}

              <div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingLeft: 8,
                  paddingRight: 8,
                  marginTop: 30,
                  marginBottom: 15,
                }}
              >
                <div css={{ fontWeight: 'bold' }}>{t('Total')}</div>
                <div css={{ fontWeight: 'bold' }}>{totalPrice}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </LayoutComponent>
  )
}

export default ConfirmationPage
