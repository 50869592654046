/* Copyright 2013 - 2024 Waiterio LLC */
import addMealRest from '@waiterio/api-client/addMeal.js'
import { plantMeal } from '../forage/MealsForage.js'

export default function addMeal(restaurantId, meal) {
  return plantMeal(meal).then(meal =>
    addMealRest(restaurantId, meal).catch(error => {
      throw error
    }),
  )
}
