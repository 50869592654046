/* Copyright 2013 - 2024 Waiterio LLC */
const Event = {
  ACCOUNT_ADDED_EVENT: 'ACCOUNT_ADDED_EVENT',
  ACCOUNT_REMOVED_EVENT: 'ACCOUNT_REMOVED_EVENT',
  ACCOUNT_UPDATED_EVENT: 'ACCOUNT_UPDATED_EVENT',
  CATEGORY_ADDED_EVENT: 'CATEGORY_ADDED_EVENT',
  CATEGORY_REMOVED_EVENT: 'CATEGORY_REMOVED_EVENT',
  CATEGORY_UPDATED_EVENT: 'CATEGORY_UPDATED_EVENT',
  CONVERSATION_ADDED_EVENT: 'CONVERSATION_ADDED_EVENT',
  CONVERSATION_REMOVED_EVENT: 'CONVERSATION_REMOVED_EVENT',
  CONVERSATION_UPDATED_EVENT: 'CONVERSATION_UPDATED_EVENT',
  CHARGE_ADDED_EVENT: 'CHARGE_ADDED_EVENT',
  CHARGE_REMOVED_EVENT: 'CHARGE_REMOVED_EVENT',
  CHARGE_UPDATED_EVENT: 'CHARGE_UPDATED_EVENT',
  DEVICE_ADDED_EVENT: 'DEVICE_ADDED_EVENT',
  DEVICE_REMOVED_EVENT: 'DEVICE_REMOVED_EVENT',
  DEVICE_UPDATED_EVENT: 'DEVICE_UPDATED_EVENT',
  CURRENT_MEALS_UPDATED_EVENT: 'CURRENT_MEALS_UPDATED_EVENT',
  INVITE_ADDED_EVENT: 'INVITE_ADDED_EVENT',
  INVITE_REMOVED_EVENT: 'INVITE_REMOVED_EVENT',
  INVITE_UPDATED_EVENT: 'INVITE_UPDATED_EVENT',
  ITEM_ADDED_EVENT: 'ITEM_ADDED_EVENT',
  ITEM_REMOVED_EVENT: 'ITEM_REMOVED_EVENT',
  ITEM_UPDATED_EVENT: 'ITEM_UPDATED_EVENT',
  ITEMSTAMP_ADDED_EVENT: 'ITEMSTAMP_ADDED_EVENT',
  ITEMSTAMP_REMOVED_EVENT: 'ITEMSTAMP_REMOVED_EVENT',
  ITEMSTAMP_UPDATED_EVENT: 'ITEMSTAMP_UPDATED_EVENT',
  MAP_ADDED_EVENT: 'MAP_ADDED_EVENT',
  MAP_REMOVED_EVENT: 'MAP_REMOVED_EVENT',
  MAP_UPDATED_EVENT: 'MAP_UPDATED_EVENT',
  MEAL_ADDED_EVENT: 'MEAL_ADDED_EVENT',
  MEAL_REMOVED_EVENT: 'MEAL_REMOVED_EVENT',
  MEAL_UPDATED_EVENT: 'MEAL_UPDATED_EVENT',
  MENU_ADDED_EVENT: 'MENU_ADDED_EVENT',
  MENU_REMOVED_EVENT: 'MENU_REMOVED_EVENT',
  MENU_UPDATED_EVENT: 'MENU_UPDATED_EVENT',
  MESSAGE_ADDED_EVENT: 'MESSAGE_ADDED_EVENT',
  MESSAGE_REMOVED_EVENT: 'MESSAGE_REMOVED_EVENT',
  MESSAGE_UPDATED_EVENT: 'MESSAGE_UPDATED_EVENT',
  PAYMENT_ADDED_EVENT: 'PAYMENT_ADDED_EVENT',
  PAYMENT_REMOVED_EVENT: 'PAYMENT_REMOVED_EVENT',
  PAYMENT_UPDATED_EVENT: 'PAYMENT_UPDATED_EVENT',
  PRINTER_ADDED_EVENT: 'PRINTER_ADDED_EVENT',
  PRINTER_REMOVED_EVENT: 'PRINTER_REMOVED_EVENT',
  PRINTER_UPDATED_EVENT: 'PRINTER_UPDATED_EVENT',
  RESTAURANT_ADDED_EVENT: 'RESTAURANT_ADDED_EVENT',
  RESTAURANT_REMOVED_EVENT: 'RESTAURANT_REMOVED_EVENT',
  RESTAURANT_UPDATED_EVENT: 'RESTAURANT_UPDATED_EVENT',
  SUBSCRIPTION_ADDED_EVENT: 'SUBSCRIPTION_ADDED_EVENT',
  SUBSCRIPTION_REMOVED_EVENT: 'SUBSCRIPTION_REMOVED_EVENT',
  SUBSCRIPTION_UPDATED_EVENT: 'SUBSCRIPTION_UPDATED_EVENT',
  ROLE_ADDED_EVENT: 'ROLE_ADDED_EVENT',
  ROLE_REMOVED_EVENT: 'ROLE_REMOVED_EVENT',
  ROLE_UPDATED_EVENT: 'ROLE_UPDATED_EVENT',
  TEST_EVENT: 'TEST_EVENT',
  USER_ADDED_EVENT: 'USER_ADDED_EVENT',
  USER_REMOVED_EVENT: 'USER_REMOVED_EVENT',
  USER_UPDATED_EVENT: 'USER_UPDATED_EVENT',
}

export default Event
