/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import { jsx } from '@emotion/react'

const Spinner = ({ style = { height: '100%' } }) => (
  <div style={style}>
    <div className="loader" />
  </div>
)

export default Spinner
