/* Copyright 2013 - 2024 Waiterio LLC */
import getBrowserLanguage from '@monorepo/shared/getBrowserLanguage.js'

export default function getInitialContext() {
  return {
    data: {
      meals: {},
      payments: {},
      restaurant: {},
      menu: {},
    },
    error: '',
    language: getBrowserLanguage(),
    loading: false,
    pages: {
      contact: {},
      home: {},
      info: {},
      menu: {},
      order: {},
      orders: {},
      table: {},
    },
    session: {},
  }
}
