/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useMemo, useState } from 'react'
import { jsx } from '@emotion/react'
import { useNavigate, useParams } from 'react-router-dom'
import { useContext } from '@monorepo/context/index.js'
import { CardElement, Elements, useElements } from '@stripe/react-stripe-js'
import getDate from 'date-fns/get_date/index.js'
import getMonth from 'date-fns/get_month/index.js'
import getTime from 'date-fns/get_time/index.js'
import getYear from 'date-fns/get_year/index.js'
import setDate from 'date-fns/set_date/index.js'
import setMonth from 'date-fns/set_month/index.js'
import setYear from 'date-fns/set_year/index.js'
import addPaymentIntent from '@waiterio/api-client/addPaymentIntent.js'
import IS_DEVELOPMENT from '@monorepo/env/IS_DEVELOPMENT.js'
import { useTranslation } from '@multilocale/react/index.js'
import Itemstamp from '@waiterio/model/Itemstamp.js'
import { DELIVERY, TAKEAWAY } from '@waiterio/model/Meal.js'
import Payment from '@waiterio/model/Payment.js'
import PaymentMethod from '@waiterio/model/PaymentMethod.js'
import UUID from '@waiterio/model/UUID.js'
import getDefaultFractionDigits from '@monorepo/shared/currencies/getDefaultFractionDigits.js'
import colMd4 from '@waiterio/styles/bootstrap/colMd4.js'
import colLg4 from '@waiterio/styles/bootstrap/colLg4.js'
import colMd6 from '@waiterio/styles/bootstrap/colMd6.js'
import colLg6 from '@waiterio/styles/bootstrap/colLg6.js'
import colSm12 from '@waiterio/styles/bootstrap/colSm12.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import getStripeBrowserByCountry from '@waiterio/stripe-publishable/getStripeBrowserByCountry.js'
import useMenu from '../useMenu.js'
import useRestaurant from '../useRestaurant.js'
import useSubdomain from '../useSubdomain.js'
import { plantMeal } from '../forage/MealsForage.js'
import {
  accountSelector,
  currentMealSelector,
  orderStatusSelector,
} from '../selectors/AppSelectors.js'
import { timesSelector } from '../selectors/CheckoutSelectors.js'
import {
  itemsSelector,
  totalPriceSelector,
  totalPriceFormattedSelector,
  taxPriceSelector,
  itemsCountSelector,
} from '../selectors/PaymentSelectors.js'
import addMeal from '../updater/addMeal.js'
import addPayment from '../updater/addPayment.js'
import LayoutComponent from '../components/LayoutPrivateComponent.js'
import Dropdown from '../components/Dropdown.js'
import headingContainer from '../styles/headingContainer.js'
import headingTitle from '../styles/headingTitle.js'

import LoadingDialog from '../dialogs/LoadingDialog.js'
import ErrorDialog from '../dialogs/ErrorDialog.js'
import confirmStripeCardPayment from '../services/confirmStripeCardPayment.js'
import languages from '../languages.js'

const Toggler = ({
  defaultLabel,
  otherLabel,
  defaultValue,
  otherValue,
  onToggle,
}) => {
  let [currentValue, setCurrentValue] = useState(defaultValue)

  const toggle = () => {
    if (currentValue === defaultValue) {
      setCurrentValue(otherValue)
      onToggle(otherValue)
    } else {
      setCurrentValue(defaultValue)
      onToggle(defaultValue)
    }
  }

  const toggleDefault = () => {
    if (currentValue === otherValue) {
      setCurrentValue(defaultValue)
      onToggle(defaultValue)
    }
  }

  const toggleOther = () => {
    if (currentValue === defaultValue) {
      setCurrentValue(otherValue)
      onToggle(otherValue)
    }
  }

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
        margin: '14px auto 14px auto',
      }}
    >
      <div
        css={{ cursor: 'pointer', margin: 8 }}
        onClick={toggleDefault}
        data-testid={`toggle-${defaultLabel}`.toLowerCase()}
      >
        {defaultLabel}
      </div>
      <div
        onClick={toggle}
        css={{
          position: 'relative',
          width: 40,
          height: 15,
          marginTop: 10,
          backgroundColor: 'white',
          border: '1px solid var(--color-primary)',
          borderRadius: 20,
          cursor: 'pointer',
          ':after': {
            content: '""',
            position: 'absolute',
            top: -3.5,
            left: currentValue === defaultValue && 0,
            right: currentValue === otherValue && 0,
            width: 20,
            height: 20,
            borderRadius: 40,
            backgroundColor: 'var(--color-primary)',
          },
          ':hover': {
            ':after': {
              backgroundColor: 'var(--color-primary-dark)',
            },
          },
        }}
      />
      <div
        css={{ cursor: 'pointer', margin: 8 }}
        onClick={toggleOther}
        data-testid={`toggle-${otherLabel}`.toLowerCase()}
      >
        {otherLabel}
      </div>
    </div>
  )
}

export const paths = languages.map(locale => ({
  path: `/${locale}/checkout/:mealId`,
  props: {
    locale,
  },
}))

export const Head = ({ locale }) => {
  const { t } = useTranslation(locale)
  const vanityId = useSubdomain()
  const { data: restaurant = {} } = useRestaurant(vanityId)

  const title = `${t('Checkout')} | ${restaurant?.name || t('Restaurant')}`

  return <title>{title}</title>
}

const CheckoutPage = props => {
  const { locale = 'en' } = props
  const { mealId } = useParams()
  const { t } = useTranslation(locale)
  const navigate = useNavigate()
  let elements = useElements()
  let [context, produce] = useContext()
  produce = produce(['pages', 'order'])
  const vanityId = useSubdomain()
  const { data: restaurant = {} } = useRestaurant(vanityId)
  const { data: menu = {} } = useMenu(restaurant?._id)

  let { sendingDialog = false, errorDialog = false } = context.pages.order
  const account = accountSelector(context)
  let meal = currentMealSelector(context, { mealId })
  const items = itemsSelector(context, { meal, menu })
  const itemsCount = itemsCountSelector(context, { meal, menu })
  const totalPrice = totalPriceSelector(context, { meal, menu })
  const totalPriceFormatted = totalPriceFormattedSelector(context, {
    meal,
    menu,
  })
  const taxPrice = taxPriceSelector(context, { meal, menu })
  const orderStatus = orderStatusSelector(context, { mealId })
  const {
    dateLabels,
    dateValues,
    deliveryDate,
    pickupDate,
    timeLabels,
    timeValues,
  } = timesSelector(context, { locale, meal, translate: t })

  if (restaurant && !meal && typeof window !== 'undefined') {
    window.location.replace('/order')
  }

  if (meal && IS_DEVELOPMENT) {
    meal = meal.set('customerName', meal.customerName || 'John Smith')
    meal = meal.set('customerPhone', meal.customerPhone || '987654321')
  }

  const { deliveryServiceOffered } = restaurant || {}

  const {
    customerName,
    customerPhone,
    customerAddress,
    deliveryTime,
    pickupTime,
    service,
  } = meal || {}

  const isDelivery = service === DELIVERY
  const isTakeaway = service === TAKEAWAY
  const orderAnnotated = orderStatus === Itemstamp.Status.ANNOTATED

  let paymentMethod = context?.pages?.order?.paymentMethod

  if (!paymentMethod && account?.chargesEnabled) {
    paymentMethod = PaymentMethod.CREDIT_CARD_PAYMENT_METHOD
  }

  let pickupOrDeliveryTimeSelected = pickupTime && true

  if (isDelivery) {
    pickupOrDeliveryTimeSelected = deliveryTime && true
  }

  let updateMeal = meal => {
    produce(draft => {
      draft.meal = meal
      plantMeal(meal)
    })
  }

  let changeCustomerName = event => {
    const customerName = event.target.value

    updateMeal(
      meal.merge({
        customerName,
      }),
    )
  }

  let changeCustomerPhone = event => {
    const customerPhone = event.target.value

    updateMeal(
      meal.merge({
        customerPhone,
      }),
    )
  }

  let changeCustomerAddress = event => {
    const customerAddress = event.target.value

    updateMeal(
      meal.merge({
        customerAddress,
      }),
    )
  }

  let changePickupDate = pickupDate => {
    let { pickupTime } = meal
    if (!pickupTime) {
      pickupTime = new Date()
      pickupTime.setMinutes(Math.ceil(pickupTime.getMinutes() / 15) * 15, 0, 0)
    }
    pickupTime = setYear(pickupTime, getYear(pickupDate))
    pickupTime = setMonth(pickupTime, getMonth(pickupDate))
    pickupTime = setDate(pickupTime, getDate(pickupDate))
    pickupTime = getTime(pickupTime)
    updateMeal(
      meal.merge({
        pickupTime,
      }),
    )
  }

  let changePickupTime = pickupTime => {
    updateMeal(
      meal.merge({
        pickupTime,
      }),
    )
  }

  let changeDeliveryDate = deliveryDate => {
    let { deliveryTime } = meal
    if (!deliveryTime) {
      deliveryTime = new Date()
      deliveryTime.setMinutes(
        Math.ceil(deliveryTime.getMinutes() / 15) * 15,
        0,
        0,
      )
    }
    deliveryTime = setYear(deliveryTime, getYear(deliveryDate))
    deliveryTime = setMonth(deliveryTime, getMonth(deliveryDate))
    deliveryTime = setDate(deliveryTime, getDate(deliveryDate))
    deliveryTime = getTime(deliveryTime)
    updateMeal(
      meal.merge({
        deliveryTime,
      }),
    )
  }

  let changeDeliveryTime = deliveryTime => {
    updateMeal(
      meal.merge({
        deliveryTime,
      }),
    )
  }

  let changePaymentMethod = paymentMethod => {
    produce(draft => {
      draft.paymentMethod = paymentMethod
    })
  }

  let changeService = service => {
    updateMeal(
      meal.merge({
        service,
        pickupTime: undefined,
        deliveryTime: undefined,
      }),
    )
  }

  let showErrorDialog = error => {
    produce(draft => {
      delete draft.sendingDialog
      draft.errorDialog = error ? error.toString() : null
    })
  }

  let hideErrorDialog = () => {
    produce(draft => {
      delete draft.errorDialog
    })
  }

  let showSendingDialog = () => {
    produce(draft => {
      draft.sendingDialog = true
    })
  }

  let hideSendingDialog = () => {
    produce(draft => {
      delete draft.sendingDialog
    })
  }

  let sendOrder = async event => {
    event.preventDefault()

    try {
      Object.keys(meal.itemstamps).forEach(itemstampId => {
        if (
          Itemstamp.Status.ANNOTATED === meal.itemstamps[itemstampId].status
        ) {
          meal = meal.setItemstampStatus(
            itemstampId,
            Itemstamp.Status.REQUESTED,
          )
        }
      })

      showSendingDialog()

      if (
        account?.stripeAccountId &&
        paymentMethod === PaymentMethod.CREDIT_CARD_PAYMENT_METHOD
      ) {
        let paymentIntent = await addPaymentIntent({
          stripeAccountId: account?.stripeAccountId,
          amount: totalPrice * 100,
          captureMethod: 'manual',
          currency: menu.currency,
          countryCode: account.countryCode,
        })

        let { clientSecret } = paymentIntent
        let { stripeAccountId } = account

        let card = elements.getElement(CardElement)

        let stripePaymentIntent = await confirmStripeCardPayment({
          card,
          clientSecret,
          countryCode: account.countryCode,
          stripeAccountId,
        })

        let creationTime = new Date().getTime()
        let currencyCode = stripePaymentIntent.currency.toUpperCase()
        let payment = new Payment({
          _id: UUID.generate(),
          creationTime,
          lastEditTime: creationTime,
          stripePaymentIntentId: stripePaymentIntent.id,
          currencyCode: stripePaymentIntent.currency.toUpperCase(),
          status: stripePaymentIntent.status.toUpperCase(),
          paymentMethod:
            stripePaymentIntent.payment_method_types?.[0]?.toUpperCase(),
          amount:
            stripePaymentIntent.amount /
            10 ** getDefaultFractionDigits(currencyCode),
          restaurantId: meal.restaurantId,
          mealId: meal._id,
        })

        await addPayment(payment.restaurantId, payment)

        meal = meal.set('paymentsIds', [payment._id])

        Object.values(meal.itemstamps).forEach(itemstamp => {
          if (Itemstamp.Status.CANCELLED !== itemstamp.status) {
            meal = meal.setIn(['itemstamps', itemstamp.id, 'paid'], true)
            meal = meal.setIn(['itemstamps', itemstamp.id, 'paymentMethod'], {
              type: PaymentMethod.CREDIT_CARD_PAYMENT_METHOD,
            })
          }
        })
      }

      await addMeal(meal.restaurantId, meal)

      hideSendingDialog()
      navigate(`/${locale}/orders/${meal._id}`)
    } catch (error) {
      if (meal) {
        Object.keys(meal.itemstamps).forEach(itemstampId => {
          if (
            Itemstamp.Status.REQUESTED === meal.itemstamps[itemstampId].status
          ) {
            meal = meal.setItemstampStatus(
              itemstampId,
              Itemstamp.Status.ANNOTATED,
            )
          }
        })

        updateMeal(meal)
      }

      hideSendingDialog()

      showErrorDialog(error)
    }
  }

  return (
    <LayoutComponent locale={locale}>
      <div css={{ background: '#626262', height: 62 }} />

      <div css={[container, { marginTop: 40, marginBottom: 40 }]}>
        <div css={row}>
          <div css={colSm12}>
            <div css={headingContainer}>
              <h3 css={headingTitle}>{t('Checkout')}</h3>
            </div>
          </div>
        </div>
        {items && (
          <div css={row}>
            <div
              css={[
                colMd6,
                colLg6,
                {
                  padding: 14,
                  margin: '20px auto',
                  border: '1px solid #e8e8e8',
                },
              ]}
            >
              {deliveryServiceOffered && orderAnnotated && (
                <Toggler
                  defaultLabel={t('Delivery')}
                  otherLabel={t('Takeaway')}
                  defaultValue={DELIVERY}
                  otherValue={TAKEAWAY}
                  onToggle={changeService}
                />
              )}

              {!deliveryServiceOffered && orderAnnotated && (
                <h3
                  css={{ textAlign: 'center', margin: '14px auto 14px auto' }}
                >
                  {t('Takeaway')}
                </h3>
              )}

              {!orderAnnotated && (
                <h3
                  css={{ textAlign: 'center', margin: '14px auto 14px auto' }}
                >
                  {t(isTakeaway ? 'Takeaway' : 'Delivery')}
                </h3>
              )}

              <form
                css={{ marginTop: 28, marginBottom: 28 }}
                onSubmit={sendOrder}
              >
                <input
                  value={customerName || ''}
                  type="text"
                  placeholder={t('name')}
                  onChange={changeCustomerName}
                  css={{
                    width: '100%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    lineHeight: '48px',
                    padding: '0 16px',
                    fontSize: 16,
                    color: '#626262',
                    border: '1px solid #e8e8e8',
                    outline: 'none',
                    ':focus': { border: '1px solid #626262' },
                    '::placeholder': {
                      textTransform: 'capitalize',
                    },
                  }}
                  required
                  readOnly={!orderAnnotated && true}
                />

                <input
                  value={customerPhone || ''}
                  type="text"
                  placeholder={t('phone')}
                  onChange={changeCustomerPhone}
                  css={{
                    width: '100%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    lineHeight: '48px',
                    padding: '0 16px',
                    fontSize: 16,
                    color: '#626262',
                    marginTop: 14,
                    border: '1px solid #e8e8e8',
                    outline: 'none',
                    ':focus': { border: '1px solid #626262' },
                    '::placeholder': {
                      textTransform: 'capitalize',
                    },
                  }}
                  required
                  readOnly={!orderAnnotated && true}
                />

                {isDelivery && (
                  <>
                    <input
                      value={customerAddress || ''}
                      type="text"
                      placeholder={t('Address')}
                      onChange={changeCustomerAddress}
                      css={{
                        width: '100%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        lineHeight: '48px',
                        padding: '0 16px',
                        fontSize: 16,
                        color: '#626262',
                        marginTop: 14,
                        border: '1px solid #e8e8e8',
                        outline: 'none',
                        ':focus': { border: '1px solid #626262' },
                        '::placeholder': {
                          textTransform: 'capitalize',
                        },
                      }}
                      required
                      readOnly={!orderAnnotated && true}
                    />
                    <div
                      css={{
                        marginTop: 14,
                        pointerEvents: !orderAnnotated && 'none',
                      }}
                    >
                      <Dropdown
                        name={t('Delivery date')}
                        onSelect={changeDeliveryDate}
                        currentValue={deliveryDate}
                        labels={dateLabels}
                        values={dateValues}
                      />
                    </div>
                    <div
                      css={{
                        marginTop: 14,
                        pointerEvents: !orderAnnotated && 'none',
                      }}
                    >
                      <Dropdown
                        name={t('Delivery time')}
                        onSelect={changeDeliveryTime}
                        currentValue={deliveryTime}
                        labels={timeLabels}
                        values={timeValues}
                      />
                    </div>
                  </>
                )}

                {isTakeaway && (
                  <>
                    <div
                      css={{
                        marginTop: 14,
                        pointerEvents: !orderAnnotated && 'none',
                      }}
                    >
                      <Dropdown
                        name={t('Pickup date')}
                        onSelect={changePickupDate}
                        currentValue={pickupDate}
                        labels={dateLabels}
                        values={dateValues}
                      />
                    </div>
                    <div
                      css={{
                        marginTop: 14,
                        pointerEvents: !orderAnnotated && 'none',
                      }}
                    >
                      <Dropdown
                        name={t('Pickup time')}
                        onSelect={changePickupTime}
                        currentValue={pickupTime}
                        labels={timeLabels}
                        values={timeValues}
                      />
                    </div>
                  </>
                )}

                {account?.chargesEnabled && (
                  <>
                    <Toggler
                      defaultLabel={t('Payment card')}
                      otherLabel={t('Cash')}
                      defaultValue={PaymentMethod.CREDIT_CARD_PAYMENT_METHOD}
                      otherValue={PaymentMethod.CASH_PAYMENT_METHOD}
                      onToggle={changePaymentMethod}
                    />

                    {paymentMethod ===
                      PaymentMethod.CREDIT_CARD_PAYMENT_METHOD && (
                      <div
                        css={{
                          width: '100%',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          padding: 16,
                          fontSize: 16,
                          color: '#626262',
                          marginTop: 14,
                          border: '1px solid #e8e8e8',
                          outline: 'none',
                        }}
                      >
                        <CardElement
                          options={{
                            style: {
                              base: {
                                fontSize: 16,
                                color: '#626262',
                                '::placeholder': {
                                  color: 'rgb(195, 195, 195)',
                                },
                              },
                              invalid: {
                                color: '#d9534f',
                              },
                            },
                          }}
                        />
                      </div>
                    )}
                  </>
                )}

                {orderAnnotated && (
                  <button
                    css={{
                      width: '100%',
                      fontSize: 18,
                      fontWeight: '200',
                      backgroundColor: 'var(--color-primary)',
                      border: '1px solid var(--color-primary)',
                      color: 'white',
                      marginTop: 28,
                      padding: '10px 50px',
                      cursor: 'pointer',
                      textAlign: 'center',
                      textTransform: 'uppercase',
                      pointerEvents: !pickupOrDeliveryTimeSelected && 'none',
                      opacity: !pickupOrDeliveryTimeSelected && 0.5,
                      ':hover, :focus': {
                        backgroundColor: 'var(--color-primary-dark)',
                      },
                      '@media (max-width: 992px)': {
                        ':hover, :focus': {
                          backgroundColor: 'var(--color-primary-dark)',
                        },
                      },
                    }}
                    type="submit"
                  >
                    {t('Send order')}
                  </button>
                )}
              </form>
            </div>

            <div
              css={[
                colMd4,
                colLg4,
                {
                  padding: 15,
                  margin: '20px auto auto auto',
                  border: '1px solid #e8e8e8',
                },
              ]}
            >
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 15,
                  marginBottom: 30,
                }}
              >
                <span css={{ fontWeight: 'bold' }}>{t('Order')}</span>
                <span>
                  {itemsCount} {t('Items')}
                </span>
              </div>

              {items.map(item => (
                <div
                  key={item.itemstampIds[0]}
                  css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontWeight: '200',
                    marginTop: 30,
                  }}
                >
                  <div css={{ display: 'flex', flex: '25%' }}>
                    <div
                      css={{
                        fontSize: 12,
                        marginTop: 2,
                        maxWidth: 25,
                        width: '100%',
                        textAlign: 'left',
                      }}
                    >
                      {item.quantity} x
                    </div>
                  </div>
                  <div css={{ flex: '75%' }}>
                    {item.name}

                    {item.extras.map(
                      extra =>
                        extra.quantity && (
                          <div css={{ marginTop: 2 }} key={extra.id}>
                            + {extra.name}
                          </div>
                        ),
                    )}

                    {item.note && (
                      <div css={{ marginTop: 2 }}>! {item.note}</div>
                    )}
                  </div>
                  <div css={{ flex: '25%', textAlign: 'right' }}>
                    {item.priceWithExtra}
                  </div>
                </div>
              ))}

              {meal.tax && !meal.tax.alreadyIncludedInItems && (
                <div
                  css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 30,
                    marginBottom: 15,
                    fontWeight: '200',
                  }}
                >
                  <span>
                    {meal.tax.name} {meal.tax.quantity}%
                  </span>
                  <span>{taxPrice}</span>
                </div>
              )}

              <div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 30,
                  marginBottom: 15,
                }}
              >
                <div css={{ fontWeight: 'bold' }}>{t('Total')}</div>
                <div css={{ fontWeight: 'bold' }}>{totalPriceFormatted}</div>
              </div>
            </div>
          </div>
        )}
      </div>

      {sendingDialog && (
        <LoadingDialog
          title={t('Sending order')}
          message={t('Sending order')}
        />
      )}
      {errorDialog && (
        <ErrorDialog
          locale={locale}
          message={errorDialog}
          close={hideErrorDialog}
        />
      )}
    </LayoutComponent>
  )
}

const CheckoutPageWithStripe = ({ mealId, locale }) => {
  let [context] = useContext()
  const vanityId = context.subdomain
  const { data: restaurant = {} } = useRestaurant(vanityId)
  let countryCode = restaurant?.account?.countryCode
  let stripeAccountId = restaurant?.account?.stripeAccountId

  const stripe = useMemo(() => {
    let stripe = null

    if (countryCode && stripeAccountId) {
      stripe = getStripeBrowserByCountry({
        countryCode,
        stripeAccountId,
      })
    }

    return stripe
  }, [countryCode, stripeAccountId])

  if (restaurant) {
    return (
      <Elements stripe={stripe}>
        <CheckoutPage mealId={mealId} locale={locale} />
      </Elements>
    )
  } else {
    return null
  }
}

export default CheckoutPageWithStripe
