/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState } from 'react'
import { jsx, css } from '@emotion/react'
import unselectable from '@waiterio/styles/unselectable.js'
import { formatPriceWithSymbol } from '@waiterio/shared/PriceFormatter.js'
import { useTranslation } from '@multilocale/react/index.js'
import IconClose from '@stiloso/icons/IconClose.js'
import IconDone from '@stiloso/icons/IconDone.js'

export const modalOuter = css({
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'center',
  position: 'fixed',
  transform: 't(0,0)',
  overflowY: 'hidden',
  width: 'auto',
  left: 0,
  right: 0,
  height: 'auto',
  top: 0,
  bottom: 0,
  zIndex: 9990,
  background: '#transparent',
  padding: '0 16px',
})

export const modal = css(unselectable, {
  boxSizing: 'border-box',
  width: '480px',
  height: 'auto',
  maxHeight: '100%',
  overflowX: 'hidden',
  overflowY: 'auto',
  position: 'relative',
  zIndex: 9999,
  background: '#fefefe',
})

export const backdrop = css({
  display: 'block',
  position: 'absolute',
  width: 'auto',
  left: 0,
  right: 0,
  height: 'auto',
  top: 0,
  bottom: 0,
  boxSizing:
    'border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; -o-box-sizing: border-box',
  zIndex: 9995 /* display between modal container and modal window */,
  backgroundColor: ['rgb(0,0,0)', 'rgba(0,0,0,0.4)'],
})

const AddExtrasDialog = props => {
  const { currencyCode, item, locale, meal } = props

  const { t } = useTranslation(locale)

  const sortedExtras = []
    .concat(item.extras)
    .sort((firstExtra, secondExtra) =>
      firstExtra.name > secondExtra.name ? 1 : -1,
    )

  const [hasChanged, setHasChanged] = useState(false)
  const [itemstamp, setItemstamp] = useState(
    meal.itemstamps[item.itemstampIds[0]],
  )
  const [extras, setExtras] = useState(sortedExtras)
  const [note, setNote] = useState(itemstamp.note || '')

  const close = event => {
    if (event) {
      event.preventDefault()
    }

    if (props.close) {
      props.close()
    }
  }

  const addExtra = itemExtra => {
    const newExtras = JSON.parse(JSON.stringify(extras))
    let extra = newExtras.find(extra => extra.id === itemExtra.id)
    extra.quantity = 1
    const newItemstamp = itemstamp.addOrUpdateExtra(extra)
    setExtras(newExtras)
    setItemstamp(newItemstamp)
  }

  const removeExtra = itemExtra => {
    const newExtras = JSON.parse(JSON.stringify(extras))
    let extra = newExtras.find(extra => extra.id === itemExtra.id)
    delete extra.quantity
    const newItemstamp = itemstamp.removeExtra(extra.id)
    setExtras(newExtras)
    setItemstamp(newItemstamp)
  }

  const onChangeNote = event => {
    const note = event.target.value
    setNote(note)
    setItemstamp(itemstamp.set('note', note.trim().toLowerCase()))
    setHasChanged(true)
  }

  const onClickExtra = extra => {
    if (!extra.quantity) {
      addExtra(extra)
    } else {
      removeExtra(extra)
    }

    setHasChanged(true)
  }

  const onClickDone = event => {
    props.addOrUpdateItemstamp(itemstamp)
    close(event)
  }

  return (
    <div css={modalOuter}>
      <div css={modal}>
        {!item.photoUrl && (
          <div css={{ padding: 16 }}>
            <div css={{ display: 'flex', justifyContent: 'space-between' }}>
              <div
                css={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <IconClose
                  css={{
                    width: 22,
                    height: 22,
                    fill: 'var(--color-primary)',
                    cursor: 'pointer',
                  }}
                  onClick={close}
                />
              </div>

              <div
                css={{
                  padding: 0,
                  margin: 0,
                  textAlign: 'center',
                  fontSize: 20,
                  textTransform: 'capitalize',
                  fontWeight: 'bold',
                  color: '#626262',
                }}
              >
                {item.name}
              </div>

              <div />
            </div>

            {item.description && (
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 16,
                  padding: 16,
                }}
              >
                <span
                  css={{
                    maxWidth: 300,
                    fontSize: 14,
                    textAlign: 'center',
                    fontWeight: '300',
                  }}
                >
                  {item.description}
                </span>
              </div>
            )}
          </div>
        )}

        {item.photoUrl && (
          <div>
            <img
              css={{ objectFit: 'cover', height: 300, width: '100%' }}
              src={item.photoUrl}
            />
            <div
              css={{
                position: 'absolute',
                left: 16,
                top: 16,
                backgroundColor: 'var(--color-primary)',
                borderRadius: 20,
                padding: 6,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconClose
                css={{
                  width: 22,
                  height: 22,
                  fill: 'white',
                  cursor: 'pointer',
                }}
                onClick={close}
              />
            </div>

            <div
              css={{
                padding: 0,
                margin: 16,
                textAlign: 'center',
                fontSize: 20,
                textTransform: 'capitalize',
                fontWeight: 'bold',
                color: '#626262',
              }}
            >
              {item.name}
            </div>

            {item.description && (
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: 16,
                }}
              >
                <span
                  css={{
                    maxWidth: 300,
                    fontSize: 14,
                    textAlign: 'center',
                    fontWeight: '300',
                  }}
                >
                  {item.description}
                </span>
              </div>
            )}
          </div>
        )}

        <div css={{ height: 1, width: '100%', backgroundColor: '#ebebeb' }} />

        <div css={{ marginTop: 16, marginBottom: 16 }}>
          {extras.length > 0 && (
            <>
              <div
                css={{
                  padding: 16,
                  marginBottom: 16,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  fontSize: 15,
                  fontWeight: 'bold',
                  backgroundColor: '#f6f6f6',
                }}
              >
                <span css={{ color: '#626262' }}>{t('Extras')}</span>
              </div>
              {extras.map(extra => (
                <div
                  key={extra.id}
                  css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: 16,
                    cursor: 'pointer',
                  }}
                  onClick={() => onClickExtra(extra)}
                >
                  <div
                    css={{
                      width: 24,
                      height: 24,
                      border: `2px solid ${
                        extra.quantity ? 'var(--color-primary)' : '#626262'
                      }`,
                      position: 'relative',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      transformOrigin: 'center',
                      backgroundColor: extra.quantity && 'var(--color-primary)',
                      transition:
                        'all .3s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                    }}
                  >
                    <IconDone
                      css={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        width: !extra.quantity && 0,
                        maxWidth: 20,
                        height: !extra.quantity && 0,
                        maxHeight: 20,
                        transformOrigin: 'center',
                        transition:
                          'all .4s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                        fill: 'white',
                      }}
                    />
                  </div>
                  <div
                    css={{
                      marginLeft: 16,
                      display: 'flex',
                      alignItems: 'center',
                      flex: 1,
                      textTransform: 'capitalize',
                      fontWeight: '200',
                    }}
                  >
                    {extra.name}
                  </div>
                  <div css={{ flex: 1, textAlign: 'right', fontWeight: '200' }}>
                    + {formatPriceWithSymbol(extra.price, currencyCode)}
                  </div>
                </div>
              ))}
            </>
          )}

          <div css={{ marginTop: 16, marginBottom: 16 }}>
            <div
              css={{
                padding: 16,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                fontSize: 15,
                fontWeight: 'bold',
                backgroundColor: '#f6f6f6',
              }}
            >
              <span css={{ color: '#626262' }}>{t('Add note')}</span>
            </div>

            <div css={{ display: 'flex', margin: 16 }}>
              <textarea
                placeholder={t('Add instructions for kitchen')}
                value={note}
                onChange={onChangeNote}
                css={{
                  display: 'flex',
                  flex: 1,
                  padding: 16,
                  fontSize: 14,
                  fontWeight: '200',
                  resize: 'vertical',
                  minHeight: 68,
                  border: '1px solid #626262',
                }}
              />
            </div>
          </div>

          <div css={{ height: 1, width: '100%', backgroundColor: '#ebebeb' }} />

          <div
            css={{
              position: 'sticky',
              bottom: 16,
              left: 0,
              marginTop: 16,
              marginLeft: 16,
              marginRight: 16,
            }}
          >
            <div
              css={{
                display: 'flex',
                padding: 8,
                justifyContent: 'center',
                width: '100%',
                color: 'white',
                backgroundColor: 'var(--color-primary)',
                cursor: 'pointer',
                pointerEvents: !hasChanged && 'none',
                opacity: !hasChanged && '0.5',
                ':hover': {
                  backgroundColor: 'var(--color-primary-dark)',
                },
              }}
              onClick={onClickDone}
            >
              <div css={{ padding: 8 }}>{t('Save')}</div>
            </div>
          </div>
        </div>
      </div>
      <div css={backdrop} onClick={close} data-testid="close-modal" />
    </div>
  )
}

export default AddExtrasDialog
