/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import getBrowserLanguage from '@monorepo/shared/getBrowserLanguage.js'

export const paths = [
  '/table',
  '/table/*',
  // TODO temporary fix for old qr code links for https://analytics.waiterio.com/restaurants/e810a23b9c659ae4561f39c0
  '/table1',
  '/table2',
  '/table3',
  '/table4',
  '/table5',
  '/table6',
  '/table7',
  '/table8',
  '/table9',
  '/table10',
  '/table11',
  '/table12',
  '/table13',
  '/table14',
  '/table15',
  '/table16',
  '/table17',
  '/table18',
  '/table19',
  '/table20',
  '/table21',
  '/table22',
  '/table23',
  '/table24',
  '/table25',
  '/table26',
  '/table27',
  '/table28',
  '/table29',
  '/table30',
  '/table31',
  '/table32',
  '/table33',
  '/table34',
  '/table35',
  '/table36',
  '/table37',
  '/table38',
  '/table39',
  '/table40',
  '/tableMcKabro',
  '/tableMinimal',
  '/tableInsurgente',
  '/tablePasillo',
  '/tableB-1',
  '/tableB-2',
  '/tableb-3',
  '/tableb-4',
  '/tableb.5',
  '/tableb-6',
  '/tableBarra',
]

const TableRedirect = () => {
  const location = useLocation()
  console.log('location', location)
  const locale = getBrowserLanguage() || 'en'
  return <Navigate exact to={`/${locale}${location?.pathname ?? '/table'}`} />
}

export default TableRedirect
