/* Copyright 2013 - 2024 Waiterio LLC */
import Immutable from 'seamless-immutable'
import Category from './Category.js'
import ServiceCharge from './ServiceCharge.js'
import Tax from './Tax.js'
import UUID from './UUID.js'

function Menu(source) {
  if (source instanceof Menu) {
    return source
  } else {
    Object.keys(source || {}).forEach(key => {
      this[key] = source[key]
    })

    if (!this._id) {
      this._id = UUID.generate()
    }

    if (!this.creationTime) {
      this.creationTime = new Date().getTime()
    }

    if (!this.lastEditTime) {
      this.lastEditTime = this.creationTime
    }

    if (!this.categories) {
      this.categories = []
    }

    if (this.uncategorized) {
      this.uncategorized = new Category(this.uncategorized)
    }

    if (!this.uncategorized) {
      this.uncategorized = new Category({
        id: Category.UNCATEGORIZED,
        name: Category.UNCATEGORIZED,
      })
    }

    if (this.categories) {
      for (let i = 0; i < this.categories.length; i += 1) {
        this.categories[i] = new Category(this.categories[i])
      }
    }

    if (!this.imports) {
      this.imports = []
    }

    if (!this.photos) {
      this.photos = []
    }

    if (this.serviceCharge) {
      this.serviceCharge = new ServiceCharge(this.serviceCharge)
    }

    if (this.tax) {
      this.tax = new Tax(this.tax)
    }

    return Immutable(this, { prototype: Menu.prototype })
  }
}

Menu.prototype.constructor = Menu

Menu.prototype.addOrUpdateCategory = function addOrUpdateCategory(
  category,
  addToTop,
) {
  let menu = this

  if (!category.id) {
    menu = menu.setIn(
      ['categories', menu.categories.length],
      new Category(category),
    )
  } else if (category.id === Category.UNCATEGORIZED) {
    menu = menu.setIn('uncategorized', new Category(category))
  } else {
    let index = this.getCategoryIndex(category.id)

    if (!index && index !== 0) {
      index = this.categories.length
    } else {
      addToTop = false
    }

    if (addToTop) {
      menu = menu.set(
        'categories',
        [new Category(category)].concat(menu.categories),
      )
    } else {
      menu = menu.setIn(['categories', index], new Category(category))
    }
  }

  return menu
}

Menu.prototype.addOrUpdateItem = function addOrUpdateItem(categoryId, item) {
  let menu = this

  let category = menu.getCategory(categoryId)

  if (category) {
    category = category.addOrUpdateItem(item)
    menu = menu.addOrUpdateCategory(category)
  }

  return menu
}

Menu.prototype.addOrUpdateExtraToCategory = function addOrUpdateExtraToCategory(
  categoryId,
  extra,
) {
  let menu = this

  let category = menu.getCategory(categoryId)

  if (category) {
    category = category.addOrUpdateExtra(extra)
    menu = menu.addOrUpdateCategory(category)
  }

  return menu
}

Menu.prototype.addOrUpdateExtraToItem = function addOrUpdateExtraToItem(
  categoryId,
  itemId,
  extra,
) {
  let menu = this

  let item = menu.getItem(categoryId, itemId)

  if (item) {
    item = item.addOrUpdateExtra(extra)
    menu = menu.addOrUpdateItem(categoryId, item)
  }

  return menu
}

Menu.prototype.getCategoryIndex = function getCategoryIndex(categoryId) {
  let index = null
  let found = false
  let currentIndex = 0

  while (currentIndex < this.categories.length && !found) {
    if (this.categories[currentIndex].id === categoryId) {
      found = true
      index = currentIndex
    }

    currentIndex += 1
  }

  return index
}

Menu.prototype.getCategory = function getCategory(categoryId) {
  let category = null

  if (this.categories) {
    let i = 0

    while (i < this.categories.length && !category) {
      if (this.categories[i].id === categoryId) {
        category = this.categories[i]
      }

      i += 1
    }
  }

  return category
}

Menu.prototype.getExtra = function getExtra(categoryId, itemId, extraId) {
  let extra = null

  if (itemId) {
    const item = this.getItem(categoryId, itemId)

    if (item) {
      extra = item.getExtra(extraId)
    }
  } else {
    const category = this.getCategory(categoryId)

    if (category) {
      extra = category.getExtra(extraId)
    }
  }

  return extra
}

Menu.prototype.getItem = function getItem(categoryId, itemId) {
  let item = null

  if (categoryId === Category.UNCATEGORIZED) {
    item = this.uncategorized.getItem(itemId)
  } else {
    const category = this.getCategory(categoryId)

    if (category) {
      item = category.getItem(itemId)
    }
  }

  return item
}

Menu.prototype.removeCategory = function removeCategory(categoryId) {
  return this.set(
    'categories',
    this.categories.filter(category => category.id !== categoryId),
  )
}

Menu.prototype.removeItem = function removeItem(categoryId, itemId) {
  let menu = this

  let category = this.getCategory(categoryId)

  if (category) {
    category = category.removeItem(itemId)
    menu = menu.addOrUpdateCategory(category)
  }

  return menu
}

Menu.prototype.countItems = function countItems() {
  let itemsCount = this.uncategorized.items.length

  this.categories.forEach(category => {
    itemsCount += category.items.length
  })

  return itemsCount
}

export default Menu
