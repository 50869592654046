/* Copyright 2013 - 2024 Waiterio LLC */
import { createSelector } from 'reselect'
import values from 'ramda/src/values.js'
import Meal, { TAKEAWAY, DELIVERY } from '@waiterio/model/Meal.js'
import Itemstamp from '@waiterio/model/Itemstamp.js'

import { toLines, mergeAllLines, sortLines } from '@waiterio/shared/liner.js'

const menuProps = (context, props) => props?.menu

const meals = context => context?.data?.meals

const mealProps = (context, props) => props?.meal

const restaurantProps = (_, props) => props?.restaurant

export const mealIdProps = (context, props) => props?.mealId

export const mealsSelector = createSelector([meals], meals => values(meals))

export const activeMealSelector = createSelector([mealsSelector], meals =>
  meals
    .slice(0)
    .reverse()
    .find(meal =>
      Object.keys(meal.itemstamps).some(
        itemstampId =>
          meal.itemstamps[itemstampId].status === Itemstamp.Status.REQUESTED ||
          meal.itemstamps[itemstampId].status === Itemstamp.Status.ORDERED ||
          meal.itemstamps[itemstampId].status === Itemstamp.Status.COOKING ||
          meal.itemstamps[itemstampId].status === Itemstamp.Status.READY ||
          meal.itemstamps[itemstampId].status === Itemstamp.Status.SERVED,
      ),
    ),
)

export const annotatedMealSelector = createSelector([mealsSelector], meals => {
  let meal = meals.find(meal =>
    Object.keys(meal.itemstamps).some(
      itemstampId =>
        meal.itemstamps[itemstampId].status === Itemstamp.Status.ANNOTATED,
    ),
  )

  return meal
})

export const currentMealSelector = createSelector(
  [mealsSelector, mealIdProps],
  (meals, mealId) => meals.find(meal => meal._id === mealId),
)

export const mealSelector = createSelector(
  [mealProps, annotatedMealSelector, menuProps, restaurantProps],
  (mealProps, meal, menu, restaurant) => {
    if (menu) {
      return (
        mealProps ||
        meal ||
        new Meal({
          restaurantId: menu.restaurantId,
          tax: menu.tax,
          service: restaurant.deliveryServiceOffered ? DELIVERY : TAKEAWAY,
        })
      )
    }
  },
)

export const categoriesSelector = createSelector(
  [mealProps, menuProps],
  (meal, menu) => {
    if (menu?.categories) {
      const itemstamps = values(meal.itemstamps)
      let lines = toLines(itemstamps)

      lines = sortLines(menu, lines)
      lines = mergeAllLines(lines)

      return menu.categories.reduce((categoriesArray, category) => {
        if (category.visibleToCustomers !== false) {
          categoriesArray.push({
            id: category.id,
            name: category.name,
            currency: menu.currency,
            items: category.items.reduce((array, item) => {
              const itemLines = lines.filter(line => line.id === item.id)

              if (itemLines.length) {
                array = array.concat(
                  itemLines.map(line => {
                    line.description = item.description
                    line.photoUrl = item.photoUrl

                    return line
                  }),
                )
              } else if (item.visibleToCustomers !== false) {
                array = array.concat({
                  id: item.id,
                  name: item.name,
                  description: item.description,
                  photoUrl: item.photoUrl,
                  price: item.price,
                  available: item.available,
                  itemstampIds: [],
                })
              }

              return array
            }, []),
          })
        }

        return categoriesArray
      }, [])
    }
  },
)
