/* Copyright 2013 - 2024 Waiterio LLC */
import Immutable from 'seamless-immutable'

function Discount(source) {
  if (source instanceof Discount) {
    return source
  } else {
    Object.keys(source || {}).forEach(key => {
      this[key] = source[key]
    })

    if (!this.quantityType) {
      this.quantityType = Discount.QuantityType.FIXED
    }

    if (!this.quantity) {
      this.quantity = 0
    }

    return Immutable(this, { prototype: Discount.prototype })
  }
}

Discount.QuantityType = {
  FIXED: 'FIXED',
  PERCENTAGE: 'PERCENTAGE',
}

export default Discount
