/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import getBrowserLanguage from '@monorepo/shared/getBrowserLanguage.js'

export const paths = ['/checkout/:mealId']

const CheckoutRedirect = () => {
  const locale = getBrowserLanguage() || 'en'
  const { mealId } = useParams()
  return <Navigate exact to={`/${locale}/checkout/${mealId}`} />
}

export default CheckoutRedirect
