/* Copyright 2013 - 2024 Waiterio LLC */
import IS_DEVELOPMENT from '@monorepo/env/IS_DEVELOPMENT.js'
import IS_PRODUCTION from '@monorepo/env/IS_PRODUCTION.js'

export default function getStripePublishableKeyUS() {
  let stripePublishableKey = 'pk_test_d3Y23fId1BeVS0zWJNA5cZ0400pzPqr5eS'

  if (IS_PRODUCTION) {
    stripePublishableKey = 'pk_live_4mItPdgh9Jr6ueGthoyf6bmQ00ITaiUd8Q'
  } else if (IS_DEVELOPMENT) {
    stripePublishableKey = 'pk_test_d3Y23fId1BeVS0zWJNA5cZ0400pzPqr5eS'
    // stripePublishableKey = 'pk_live_4mItPdgh9Jr6ueGthoyf6bmQ00ITaiUd8Q';
  }

  return stripePublishableKey
}
