/* Copyright 2013 - 2024 Waiterio LLC */
import { loadStripe } from '@stripe/stripe-js/pure.js'
import getStripePublishableKeyEU from '@waiterio/stripe-publishable/getStripePublishableKeyEU.js'

let stripeEU

export default async function getStripeBrowserEU(stripeAccountId) {
  if (!stripeEU) {
    let stripePublishableKeyEU = getStripePublishableKeyEU()
    stripeEU = await loadStripe(stripePublishableKeyEU, {
      stripeAccount: stripeAccountId,
    })
  }
  return stripeEU
}
