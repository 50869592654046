/* Copyright 2013 - 2024 Waiterio LLC */
import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client' // eslint-disable-line import/extensions
import { BrowserRouter } from 'react-router-dom'
import { Provider } from '@monorepo/context/index.js'
import allowSubdomainsCrossOrigin from '@monorepo/shared/allowSubdomainsCrossOrigin.js'
import getSentryBrowser from '@monorepo/telemetry/getSentryBrowser.js'
import { initMultilocale } from '@multilocale/react/index.js'
import ErrorBoundary from '@waiterio/components/ErrorBoundary.js'
import StageBadge from '@waiterio/components/StageBadge.js'
import ScrollToTop from '@waiterio/components/ScrollToTop.js'
import WaiterioProvider from './WaiterioProvider.js'
import ThemeInjector from './components/ThemeInjector.js'
import rehydrateSession from './session/rehydrate.js'
import GlobalStyles from './styles/GlobalStyles.js'
import getInitialContext from './getInitialContext.js'
import Routes from './Routes.js'
import Spinner from './components/Spinner.js'

const initialContext = window.__PRELOADED_CONTEXT__ || getInitialContext()

function onContextProvided() {
  rehydrateSession()
}

allowSubdomainsCrossOrigin()

initMultilocale({
  organizationId: 'e395dba6a14d8af4c2d35890',
  project: 'waiterio-whitelabel',
})

createRoot(document.getElementById('root')).render(
  <ErrorBoundary>
    <BrowserRouter>
      <WaiterioProvider>
        <Provider initialContext={initialContext} callback={onContextProvided}>
          <Suspense fallback={<Spinner />}>
            <ScrollToTop />
            <GlobalStyles />
            <ThemeInjector />
            <Routes />
            <StageBadge />
          </Suspense>
        </Provider>
      </WaiterioProvider>
    </BrowserRouter>
  </ErrorBoundary>,
)

getSentryBrowser()
