/* Copyright 2013 - 2024 Waiterio LLC */
import Immutable from 'seamless-immutable'
import UUID from './UUID.js'

function PaymentMethod(source) {
  if (source instanceof PaymentMethod) {
    return source
  } else {
    Object.keys(source || {}).forEach(key => {
      this[key] = source[key]
    })

    if (!this.id) {
      this.id = UUID.generate()
    }

    if (!this.creationTime) {
      this.creationTime = new Date().getTime()
    }

    if (!this.lastEditTime) {
      this.lastEditTime = this.creationTime
    }

    return Immutable(this, { prototype: PaymentMethod.prototype })
  }
}

PaymentMethod.prototype.constructor = PaymentMethod

PaymentMethod.prototype.getName = function getName() {
  return this.name || PaymentMethod.getName(this.type)
}

PaymentMethod.getName = function getName(_type) {
  return _type
    .replace('_PAYMENT_METHOD', '')
    .split('_')
    .join(' ')
    .toLowerCase()
    .replace(/\b./g, a => a.toUpperCase())
}

PaymentMethod.CASH_PAYMENT_METHOD = 'CASH_PAYMENT_METHOD'
PaymentMethod.CHEQUE_PAYMENT_METHOD = 'CHEQUE_PAYMENT_METHOD'
PaymentMethod.CREDIT_CARD_PAYMENT_METHOD = 'CREDIT_CARD_PAYMENT_METHOD'
PaymentMethod.CUSTOM_PAYMENT_METHOD = 'CUSTOM_PAYMENT_METHOD'
PaymentMethod.VOUCHER_PAYMENT_METHOD = 'VOUCHER_PAYMENT_METHOD'

PaymentMethod.getTypes = function getTypes() {
  return [
    PaymentMethod.CASH_PAYMENT_METHOD,
    PaymentMethod.CREDIT_CARD_PAYMENT_METHOD,
    PaymentMethod.CHEQUE_PAYMENT_METHOD,
    PaymentMethod.VOUCHER_PAYMENT_METHOD,
    PaymentMethod.CUSTOM_PAYMENT_METHOD,
  ]
}

export default PaymentMethod
