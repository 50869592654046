/* Copyright 2013 - 2024 Waiterio LLC */
import Immutable from 'seamless-immutable'
import Extra from './Extra.js'
import UUID from './UUID.js'

function Item(source) {
  if (source instanceof Item) {
    return source
  } else {
    Object.keys(source || {}).forEach(key => {
      this[key] = source[key]
    })

    if (!this.id) {
      this.id = UUID.generate()
    }

    if (!this.creationTime) {
      this.creationTime = new Date().getTime()
    }

    if (!this.lastEditTime) {
      this.lastEditTime = this.creationTime
    }

    if (!this.price) {
      this.price = 0
    }

    if (!this.extras) {
      this.extras = []
    }

    if (typeof this.available === 'undefined') {
      this.available = true
    }

    if (typeof this.visibleToCustomers === 'undefined') {
      this.visibleToCustomers = true
    }

    if (typeof this.description === 'undefined') {
      this.description = null
    }

    if (this.extras) {
      for (let i = 0; i < this.extras.length; i += 1) {
        this.extras[i] = new Extra(this.extras[i])
      }
    }

    return Immutable(this, { prototype: Item.prototype })
  }
}

Item.prototype.constructor = Item

Item.prototype.addOrUpdateExtra = function addOrUpdateExtra(extra) {
  let item = this

  if (!extra.id) {
    item = item.setIn(['extras', item.extras.length], new Extra(extra))
  } else {
    let index = this.getExtraIndex(extra.id)

    if (!index && index !== 0) {
      index = this.extras.length
    }

    item = item.setIn(['extras', index], new Extra(extra))
  }

  return item
}

Item.prototype.getExtra = function getExtra(extraId) {
  let extra = null

  const index = this.getExtraIndex(extraId)

  if (index || index === 0) {
    extra = this.extras[index]
  }

  return extra
}

Item.prototype.getExtraIndex = function getExtraIndex(extraId) {
  let index = null
  let found = false
  let currentIndex = 0

  while (currentIndex < this.extras.length && !found) {
    if (this.extras[currentIndex].id === extraId) {
      found = true
      index = currentIndex
    }

    currentIndex += 1
  }

  return index
}

Item.prototype.removeExtra = function removeExtra(extraId) {
  return this.set(
    'extras',
    this.extras.filter(extra => extra.id !== extraId),
  )
}

export default Item
