/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import getBrowserLanguage from '@monorepo/shared/getBrowserLanguage.js'

export const paths = ['/orders/:mealId']

const ConfirmationRedirect = () => {
  const locale = getBrowserLanguage() || 'en'
  const { mealId } = useParams()
  return <Navigate exact to={`/${locale}/orders/${mealId}`} />
}

export default ConfirmationRedirect
