/* Copyright 2013 - 2024 Waiterio LLC */
import { useQuery } from '@tanstack/react-query'
import fetchRestaurant from './fetchRestaurant.js'

export default function useRestaurant(idOrVanityId) {
  return useQuery({
    queryKey: ['restaurants', idOrVanityId],
    queryFn: () => fetchRestaurant(idOrVanityId),
    enabled: !!idOrVanityId,
  })
}
