/* Copyright 2013 - 2024 Waiterio LLC */
import addPaymentRest from '@waiterio/api-client/addPayment.js'
import { plantPayment } from '../forage/PaymentsForage.js'

export default function addPayment(restaurantId, payment) {
  return plantPayment(payment).then(payment =>
    addPaymentRest(restaurantId, payment).catch(error => {
      throw error
    }),
  )
}
