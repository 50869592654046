/* Copyright 2013 - 2024 Waiterio LLC */
function BadRequestError(error = '') {
  this.message = error?.message || error
  this.status = 400
  this.name = 'BadRequestError'
}

BadRequestError.prototype = Error.prototype
BadRequestError.prototype.toString = function toString() {
  return this.message
}

export default BadRequestError
