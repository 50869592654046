/* Copyright 2013 - 2024 Waiterio LLC */
import Immutable from 'seamless-immutable'

function Payment(source) {
  Object.keys(source || {}).forEach(key => {
    this[key] = source[key]
  })

  if (!this.creationTime) {
    this.creationTime = 0
  }

  if (!this.lastEditTime) {
    this.lastEditTime = this.creationTime || 0
  }

  if (!this.type) {
    this.type = Payment.Type.STRIPE_PAYMENT_INTENT
  }

  return Immutable(this, { prototype: Payment.prototype })
}

Payment.prototype.constructor = Payment

Payment.Type = {}
Payment.Type.STRIPE_PAYMENT_INTENT = 'STRIPE_PAYMENT_INTENT'

export default Payment
