/* Copyright 2013 - 2024 Waiterio LLC */
import getMenus from '@waiterio/api-client/getMenus.js'
import NotFoundError from '@monorepo/errors/NotFoundError.js'

export default async function getMenu(restaurantId) {
  let menus = await getMenus(restaurantId)
  let menu = menus.length ? menus[0] : null

  if (!menu) {
    throw new NotFoundError('menu not found')
  }

  return menu
}
