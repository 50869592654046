/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState } from 'react'
import { css, jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import IconCollapse from '@stiloso/icons/IconCollapse.js'
import IconExpand from '@stiloso/icons/IconExpand.js'
import colLg12 from '@waiterio/styles/bootstrap/colLg12.js'
import colMd12 from '@waiterio/styles/bootstrap/colMd12.js'
import colSm12 from '@waiterio/styles/bootstrap/colSm12.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import headingContainer from '../styles/headingContainer.js'
import headingTitle from '../styles/headingTitle.js'
import useMenu from '../useMenu.js'
import useRestaurant from '../useRestaurant.js'
import useSubdomain from '../useSubdomain.js'

const categoryTitle = css({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 600,
  cursor: 'pointer',
  backgroundColor: '#f5f7fb',
  borderRadius: 4,
})

const iconCollapseExpand = css({
  background: 'var(--color-primary)',
  fill: 'white',
  width: 48,
  height: 48,
  padding: 14,
})

const categoryItem = css({
  overflow: 'hidden',
  background: 'white',
  border: '1px solid #eeeeee',
  borderTop: 'none',
  boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.06)',
  transition: 'all .5s ease-in-out',
})

export const paths = []

const MenuSection = props => {
  const { locale } = props
  const { t } = useTranslation(locale)
  const vanityId = useSubdomain()
  const { data: restaurant = {} } = useRestaurant(vanityId)
  const { data: menu = {} } = useMenu(restaurant?._id)
  const expanded = typeof props.expanded !== 'undefined' ? props.expanded : true

  return (
    <section css={{ marginTop: 40, marginBottom: 40 }}>
      <div css={container}>
        <div css={row}>
          <div css={colSm12}>
            <div css={headingContainer}>
              <h3 css={headingTitle}>{t('Menu')}</h3>
            </div>
          </div>
        </div>
        <div css={row}>
          <div css={[colMd12, colLg12]}>
            {menu?.categories?.map((category, index) => {
              if (category.visibleToCustomers !== false) {
                return (
                  <CategoryComponent
                    key={category.id}
                    title={category.name}
                    isOpen={expanded || index === 0}
                  >
                    <div
                      css={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}
                    >
                      {category?.items?.map(item => {
                        if (item.visibleToCustomers !== false) {
                          return (
                            <div key={item.id} css={{ display: 'flex' }}>
                              <div
                                css={{ padding: 10, fontSize: 18, flexGrow: 1 }}
                              >
                                {item.name}
                              </div>
                              <div css={{ padding: 10, fontSize: 18 }}>
                                {item.price}
                              </div>
                            </div>
                          )
                        } else {
                          return null
                        }
                      })}
                    </div>
                  </CategoryComponent>
                )
              } else {
                return null
              }
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

const CategoryComponent = props => {
  const { title, children } = props
  const [isOpen, setIsOpen] = useState(props.isOpen ? props.isOpen : false)

  const onClick = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div
      css={{
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 20,
        maxWidth: 800,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <div css={categoryTitle} onClick={onClick}>
        <div css={{ flexGrow: 1, padding: '0 15px' }}>{title}</div>
        {isOpen ? (
          <IconCollapse css={iconCollapseExpand} />
        ) : (
          <IconExpand css={iconCollapseExpand} />
        )}
      </div>
      <div
        css={[
          categoryItem,
          {
            display: isOpen ? 'flex' : 'none',
          },
        ]}
      >
        <div>{children}</div>
      </div>
    </div>
  )
}

export default MenuSection
