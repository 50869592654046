/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import singleLine from '@waiterio/styles/singleLine.js'

const modalHeader = css([
  singleLine,
  {
    textAlign: 'center',
    lineHeight: '48px',
    padding: '0 16px',
    color: 'white',
    background: 'var(--color-primary)',
  },
])

const ModalHeader = ({ children, testId }) => (
  <div css={modalHeader} data-testid={testId || 'modal-title'}>
    {children}
  </div>
)

export default ModalHeader
