/* Copyright 2013 - 2024 Waiterio LLC */
export default function getDefaultFractionDigits(currencyCode) {
  let defaultFractionDigits = null

  if (currencyCode) {
    /* istanbul ignore next */
    switch (currencyCode) {
      case 'BIF':
      case 'CLP':
      case 'DJF':
      case 'GNF':
      case 'IDR':
      case 'JPY':
      case 'KMF':
      case 'KRW':
      case 'MGA':
      case 'PYG':
      case 'RWF':
      case 'VND':
      case 'VUV':
      case 'XAF':
      case 'XOF':
      case 'XPF':
        defaultFractionDigits = 0
        break
      default:
        defaultFractionDigits = 2
        break
    }
  }

  return defaultFractionDigits
}
