/* Copyright 2013 - 2024 Waiterio LLC */
import { loadStripe } from '@stripe/stripe-js/pure.js'
import getStripePublishableKeyUS from '@waiterio/stripe-publishable/getStripePublishableKeyUS.js'

let stripeUS

export default async function getStripeBrowserUS(stripeAccountId) {
  if (!stripeUS) {
    let stripePublishableKeyUS = getStripePublishableKeyUS()
    stripeUS = await loadStripe(stripePublishableKeyUS, {
      stripeAccount: stripeAccountId,
    })
  }
  return stripeUS
}
