/* Copyright 2013 - 2024 Waiterio LLC */
import { getContext, produce } from '@monorepo/context/index.js'
import { pickMeals } from '../forage/MealsForage.js'

function arrayToMapOfIds(array) {
  return array.reduce((mapp, current) => {
    mapp[current._id] = current
    return mapp
  }, {})
}

export default async function rehydrate() {
  try {
    const [meals] = await Promise.all([pickMeals()])

    let { data } = getContext()

    produce(draft => {
      draft.data.meals = { ...data.meals, ...arrayToMapOfIds(meals) }
    })
  } catch (error) {
    console.error(error)
  }
}
