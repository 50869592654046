/* Copyright 2013 - 2024 Waiterio LLC */
import Immutable from 'seamless-immutable'
import Item from './Item.js'
import PaymentMethod from './PaymentMethod.js'

function Itemstamp(source) {
  if (source instanceof Itemstamp) {
    return source
  } else {
    Object.keys(source || {}).forEach(key => {
      this[key] = source[key]
    })

    if (!this.creationTime) {
      this.creationTime = 0
    }

    if (!this.lastEditTime) {
      this.lastEditTime = this.creationTime || 0
    }

    if (!this.course) {
      this.course = 0
    }

    if (!this.extras) {
      this.extras = []
    }

    if (this.item && this.item.price && !Number.isNaN(this.item.price)) {
      if (this.item instanceof Item) {
        this.item = this.item.set('price', parseFloat(this.item.price))
      } else {
        this.item.price = parseFloat(this.item.price)
      }
    }

    if (this.paymentMethod) {
      this.paymentMethod = new PaymentMethod(this.paymentMethod)
    }

    return Immutable(this, { prototype: Itemstamp.prototype })
  }
}

Itemstamp.prototype.constructor = Itemstamp

Itemstamp.prototype.addOrUpdateExtra = function addOrUpdateExtra(extra) {
  return this.setIn(['extras', this.extras.length], extra)
}

Itemstamp.prototype.removeExtra = function removeExtra(extraId) {
  return this.set(
    'extras',
    this.extras.filter(extra => extra.id !== extraId),
  )
}

Itemstamp.Status = {
  REQUESTED: 'REQUESTED',
  REJECTED: 'REJECTED',
  ANNOTATED: 'ANNOTATED',
  ORDERED: 'ORDERED',
  COOKING: 'COOKING',
  READY: 'READY',
  SERVED: 'SERVED',
  CANCELLED: 'CANCELLED',
}

Itemstamp.Status.getAll = () => [
  Itemstamp.Status.REQUESTED,
  Itemstamp.Status.ANNOTATED,
  Itemstamp.Status.ORDERED,
  Itemstamp.Status.COOKING,
  Itemstamp.Status.READY,
  Itemstamp.Status.SERVED,
  Itemstamp.Status.REJECTED,
  Itemstamp.Status.CANCELLED,
]

export default Itemstamp
