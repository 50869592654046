/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect, useRef } from 'react'
import { jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import { useContext } from '@monorepo/context/index.js'
import countries from '@monorepo/shared/countries.js'
import headingContainer from '../styles/headingContainer.js'
import headingTitle from '../styles/headingTitle.js'

export const paths = []

const MapSection = props => {
  const { locale } = props
  const { t } = useTranslation(locale)
  let [context] = useContext()
  const restaurant = context.data.restaurant || {}

  const { street } = restaurant

  const googleMapRef = useRef()

  const getCoordinatesFromAddress = () => {
    const { name, street, city, zipcode, state, countryCode } = restaurant

    const address = `${name} ${(street && street.trim() + ',') || ''}${
      (zipcode && zipcode.trim() + ',') || ''
    }${(city && city.trim() + ',') || ''}${
      (state && state.trim() + ',') || ''
    }${(countryCode && (countries[countryCode] ?? countryCode)) || ''}`

    return new Promise((resolve, reject) => {
      const geocoder = new window.google.maps.Geocoder()
      geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK') {
          const { location } = results[0].geometry

          const coordinates = {
            lat: location.lat(),
            lng: location.lng(),
          }

          resolve(coordinates)
        } else {
          reject(status)
        }
      })
    })
  }

  const createGoogleMap = coordinates =>
    new window.google.maps.Map(googleMapRef.current, {
      zoom: 16,
      center: coordinates,
    })

  const createMapMarker = (map, coordinates) =>
    new window.google.maps.Marker({
      map,
      position: coordinates,
    })

  const startGoogleMap = () => {
    getCoordinatesFromAddress()
      .then(coordinates => {
        const map = createGoogleMap(coordinates)
        createMapMarker(map, coordinates)
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    const onScrollingToGoogleMaps = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && entry.intersectionRatio >= 0.25) {
          let googleMapScript = document.createElement('script')
          googleMapScript.src =
            'https://maps.googleapis.com/maps/api/js?key=AIzaSyArbBpwLeOP_q07C4US9vNqlTYnSQd2Aac'
          window.document.body.appendChild(googleMapScript)
          googleMapScript.addEventListener('load', startGoogleMap)
        }
      })
    }

    if (typeof IntersectionObserver !== 'undefined') {
      const observer = new IntersectionObserver(onScrollingToGoogleMaps, {
        threshold: [0.25],
        rootMargin: '150px',
      })

      observer.observe(googleMapRef.current)
    }
  }, [])

  return (
    street && (
      <>
        <div css={headingContainer}>
          <h3 css={headingTitle}>{t('Address')}</h3>
        </div>
        <div
          ref={googleMapRef}
          css={{
            maxHeight: 500,
            height: '100%',
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: 60,
          }}
        />
      </>
    )
  )
}

export default MapSection
