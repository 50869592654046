/* Copyright 2013 - 2024 Waiterio LLC */
import { createSelector } from 'reselect'
import getDefaultCoverUrl from '@waiterio/shared/getDefaultCoverUrl.js'

const restaurant = context => context?.data?.restaurant

const coverPhotoSelector = createSelector([restaurant], restaurant => {
  let coverPhotoUrl

  if (restaurant?.coverUrl) {
    coverPhotoUrl = restaurant.coverUrl
  } else if (restaurant?._id) {
    coverPhotoUrl = getDefaultCoverUrl(restaurant._id)
  }

  return coverPhotoUrl
})

export default coverPhotoSelector
