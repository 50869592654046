/* Copyright 2013 - 2024 Waiterio LLC */
import Immutable from 'seamless-immutable'
import Discount from './Discount.js'
import Itemstamp from './Itemstamp.js'
import ServiceCharge from './ServiceCharge.js'
import Tax from './Tax.js'
import UUID from './UUID.js'

export const COUNTER = 'COUNTER'
export const DELIVERY = 'DELIVERY'
export const TAKEAWAY = 'TAKEAWAY'
export const TABLE = 'TABLE'

function Meal(source) {
  if (source instanceof Meal) {
    return source
  } else {
    Object.keys(source || {}).forEach(key => {
      this[key] = source[key]
    })

    if (!this._id) {
      this._id = UUID.generate()
    }

    if (!this.shortId) {
      this.shortId = UUID.generateMealShortId()
    }

    if (!this.creationTime) {
      this.creationTime = new Date().getTime()
    }

    if (!this.lastEditTime) {
      this.lastEditTime = this.creationTime
    }

    if (!this.itemstamps) {
      this.itemstamps = {}
    }

    if (!this.usersIds) {
      this.usersIds = []
    }

    if (!this.customers && this.customers !== 0) {
      this.customers = 1
    }

    if (!this.customersPaid) {
      this.customersPaid = 0
    }

    if (this.itemstamps) {
      Object.keys(this.itemstamps).forEach(itemstampId => {
        this.itemstamps[itemstampId] = new Itemstamp(
          this.itemstamps[itemstampId],
        )
      })
    }

    if (this.discount) {
      this.discount = new Discount(this.discount)
    }

    if (this.serviceCharge) {
      this.serviceCharge = new ServiceCharge(this.serviceCharge)
    }

    if (this.tax) {
      this.tax = new Tax(this.tax)
    }

    return Immutable(this, { prototype: Meal.prototype })
  }
}

Meal.prototype.constructor = Meal

Meal.ONE_HOUR = 3600000 // ONE_HOUR
Meal.THREE_HOURS = 10800000 // THREE_HOURS
Meal.SIX_HOURS = 21600000 // SIX_HOURS
Meal.TWELVE_HOURS = 43200000 // TWELVE_HOURS
Meal.TWENTYFOUR_HOURS = 86400000 // TWENTYFOUR_HOURS

Meal.prototype.addItemstamp = function addItemstamp(item, userId, status) {
  const itemstamp = Meal.createItemstamp(item, userId, status)
  return this.addOrUpdateItemstamp(itemstamp)
}

Meal.prototype.addOrUpdateDiscount = function addOrUpdateDiscount(discount) {
  let meal = this

  if (discount) {
    meal = meal.set('discount', discount)
  }

  return meal
}

Meal.prototype.addOrUpdateItemstamp = function addOrUpdateItemstamp(itemstamp) {
  let meal = this

  if (itemstamp && itemstamp.id) {
    meal = meal.setIn(['itemstamps', itemstamp.id], itemstamp)
    meal = meal.addUserId(itemstamp.userId)
  }

  return meal
}

Meal.createItemstamp = function createItemstamp(item, userId, status) {
  return new Itemstamp({
    id: UUID.generate(),
    creationTime: new Date().getTime(),
    userId,
    status,
    item,
  })
}

Meal.prototype.removeDiscount = function removeDiscount(discount) {
  return this.removeDiscountById(discount.id)
}

Meal.prototype.removeDiscountById =
  function removeDiscountById(/* discountId */) {
    return this.set('discount', null)
  }

Meal.prototype.removeItemstamp = function removeItemstamp(itemstamp) {
  return this.removeItemstampById(itemstamp.id)
}

Meal.prototype.removeItemstampById = function removeItemstampById(itemstampId) {
  return this.set('itemstamps', this.itemstamps.without(itemstampId))
}

Meal.prototype.setItemstampStatus = function setItemstampStatus(
  itemstampId,
  status,
  userId,
) {
  let meal = this

  let itemstamp = meal.itemstamps[itemstampId]

  if (itemstamp) {
    itemstamp = itemstamp.set('status', status)

    if (userId) {
      itemstamp = itemstamp.set('userId', userId)
    }

    itemstamp = itemstamp.set('lastEditTime', new Date().getTime())
    meal = meal.addOrUpdateItemstamp(itemstamp)
  }

  return meal
}

Meal.prototype.setItemstampCourse = function setItemstampCourse(
  itemstampId,
  course,
  userId,
) {
  let meal = this

  let itemstamp = meal.itemstamps[itemstampId]

  if (itemstamp) {
    itemstamp = itemstamp.set('course', course)

    if (userId) {
      itemstamp = itemstamp.set('userId', userId)
    }

    itemstamp = itemstamp.set('lastEditTime', new Date().getTime())
    meal = meal.addOrUpdateItemstamp(itemstamp)
  }

  return meal
}

Meal.prototype.addUserId = function addUserId(userId) {
  let meal = this

  if (userId && !meal.containsUserId(userId)) {
    meal = meal.setIn(['usersIds', meal.usersIds.length], userId)
  }

  return meal
}

Meal.prototype.containsUserId = function containsUserId(userId) {
  return this.usersIds.indexOf(userId) > -1
}

export default Meal
