/* Copyright 2013 - 2024 Waiterio LLC */
import Immutable from 'seamless-immutable'
import UUID from './UUID.js'

function Extra(source) {
  if (source instanceof Extra) {
    return source
  } else {
    Object.keys(source || {}).forEach(key => {
      this[key] = source[key]
    })

    if (!this.id) {
      this.id = UUID.generate()
    }

    if (!this.creationTime) {
      this.creationTime = new Date().getTime()
    }

    if (!this.lastEditTime) {
      this.lastEditTime = this.creationTime
    }

    if (!this.price) {
      this.price = 0
    }

    return Immutable(this, { prototype: Extra.prototype })
  }
}

Extra.prototype.constructor = Extra

export default Extra
