/* Copyright 2013 - 2024 Waiterio LLC */
import { getContext, produce } from '@monorepo/context/index.js'
import getMenu from './getMenu.js'

export default async function fetchMenu(restaurantId) {
  let { data } = getContext()
  let { menu } = data

  if (!menu._id) {
    menu = await getMenu(restaurantId)

    produce(draft => {
      draft.data.menu = menu
    })
  }

  return menu
}
