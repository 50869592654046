/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint no-bitwise: 0 */

export default function getDefaultCoverUrl(idToConvertToImageName) {
  let index = 0

  const images = [
    'black-coffee-restaurant-cover-0e68beb7',
    'burger-restaurant-cover-f7b7fe4a',
    'burrito-restaurant-cover-732f58ec',
    'cappuccino-restaurant-cover-c8402bf3',
    'dinner-party-restaurant-cover-2a926250',
    'egg-noodles-restaurant-cover-1352e492',
    'juices-restaurant-cover-65435db4',
    'pizza-restaurant-cover-2634a57d',
    'french-fries-restaurant-cover-b0f39d56',
    'sandwitch-restaurant-cover-136f2275',
  ]

  if (idToConvertToImageName) {
    const hashCode = idToConvertToImageName.split('').reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0)
      return a & a
    }, 0)
    index = Math.abs(hashCode % images.length)
  }

  return `https://www.imagelato.com/images/${images[index]}.jpg`
}
