/* Copyright 2013 - 2024 Waiterio LLC */
import { css } from '@emotion/react'

export default css`
  label: heading-container;

  margin-bottom: 80px;

  @media (max-width: 992px) {
    margin-bottom: 30px;
  }

  @media (max-width: 767px) {
    margin-bottom: 30px;
  }
`
