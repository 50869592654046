/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { useContext } from '@monorepo/context/index.js'
import updateTheme from '../theme/updateTheme.js'

const ThemeInjector = () => {
  let [context] = useContext()

  const theme = context?.data?.restaurant?.theme

  updateTheme(theme)

  return null
}

export default ThemeInjector
