/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import LayoutComponent from '../components/LayoutPublicComponent.js'
import MenuSection from './MenuSection.js'
import useRestaurant from '../useRestaurant.js'
import useSubdomain from '../useSubdomain.js'
import languages from '../languages.js'

export const paths = languages.map(locale => ({
  path: `/${locale}/menu`,
  props: {
    locale,
  },
}))

export const Head = ({ locale }) => {
  const { t } = useTranslation(locale)
  const vanityId = useSubdomain()
  const { data: restaurant = {} } = useRestaurant(vanityId)

  const url = `/${locale}/menu/`
  const canonical = restaurant.vanityId
    ? `https://${restaurant.vanityId}.waiterio.com${url}`
    : null

  const title = `${t('Menu')} | ${restaurant.name || t('Restaurant')}`
  const description = restaurant.description || t('Restaurant')

  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={restaurant.coverPhotoUr} />
      <meta property="og:site_name" content={t('Successful Restaurant')} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="article" />
      <meta property="og:url" content={canonical} />
      <meta property="og:image" content={restaurant.coverPhotoUr} />
      <meta name="application-name" content={title} />
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      {canonical && <link rel="canonical" href={canonical} />}
    </>
  )
}

const MenuPage = props => {
  const { locale } = props

  return (
    <LayoutComponent locale={locale}>
      <div css={{ background: '#626262', height: 62 }} />

      <MenuSection locale={locale} />
    </LayoutComponent>
  )
}

export default MenuPage
