/* Copyright 2013 - 2024 Waiterio LLC */
import { createSelector } from 'reselect'
import values from 'ramda/src/values.js'
import last from 'ramda/src/last.js'
import equals from 'ramda/src/equals.js'
import Meal, { TABLE } from '@waiterio/model/Meal.js'
import Itemstamp from '@waiterio/model/Itemstamp.js'

import { toLines, mergeAllLines, sortLines } from '@waiterio/shared/liner.js'
import MealHelper from '@waiterio/shared/MealHelper.js'
import { formatPrice } from '@waiterio/shared/PriceFormatter.js'

import { mealsSelector } from './AppSelectors.js'

const mealProps = (context, props) => props?.meal
const menuProps = (context, props) => props?.menu
const tableProps = (context, props) => props?.table

export const annotatedMealSelector = createSelector([mealsSelector], meals => {
  let meal = meals.find(meal =>
    Object.keys(meal.itemstamps).some(
      itemstampId =>
        meal.itemstamps[itemstampId].status === Itemstamp.Status.ANNOTATED,
    ),
  )

  return meal
})

export const mealSelector = createSelector(
  [mealProps, annotatedMealSelector, menuProps, tableProps],
  (mealProps, meal, menu, table) => {
    if (menu) {
      return (
        mealProps ||
        meal ||
        new Meal({
          restaurantId: menu.restaurantId,
          table,
          tax: menu.tax,
          service: TABLE,
        })
      )
    }
  },
)

export const categoriesSelector = createSelector(
  [mealProps, menuProps],
  (meal, menu) => {
    if (menu) {
      const itemstamps = values(meal.itemstamps)
      let lines = toLines(itemstamps)

      lines = sortLines(menu, lines)
      lines = mergeAllLines(lines)

      return menu.categories.reduce((categoriesArray, category) => {
        if (category.visibleToCustomers !== false) {
          categoriesArray.push({
            id: category.id,
            name: category.name,
            currency: menu.currency,
            items: category.items.reduce((array, item) => {
              const itemLines = lines.filter(line => line.id === item.id)

              if (itemLines.length) {
                array = array.concat(
                  itemLines.map(line => {
                    line.description = item.description
                    line.photoUrl = item.photoUrl

                    return line
                  }),
                )
              } else if (item.visibleToCustomers !== false) {
                array = array.concat({
                  id: item.id,
                  name: item.name,
                  description: item.description,
                  photoUrl: item.photoUrl,
                  price: item.price,
                  available: item.available,
                  itemstampIds: [],
                })
              }

              return array
            }, []),
          })
        }

        return categoriesArray
      }, [])
    }
  },
)

export const itemsSelector = createSelector(
  [mealProps, menuProps],
  (meal, menu) => {
    if (meal && menu) {
      let itemstamps = values(meal.itemstamps)

      let lines = toLines(itemstamps)

      lines = sortLines(menu, lines)

      lines.forEach(line => {
        line.extras = line.extras.sort((firstExtra, secondExtra) => {
          if (firstExtra.name > secondExtra.name) {
            return 1
          } else if (secondExtra.name > firstExtra.name) {
            return -1
          } else {
            return 0
          }
        })
      })

      lines = lines.reduce((_lines, line) => {
        const lastLine = last(_lines)
        const sameItem = lastLine && lastLine.id === line.id
        const sameItemWithSameExtrasAndNote =
          sameItem &&
          lastLine.note === line.note &&
          equals(lastLine.extras, line.extras)

        let shouldMerge

        if (sameItemWithSameExtrasAndNote) {
          shouldMerge = lastLine
        } else if (!sameItemWithSameExtrasAndNote && sameItem) {
          let itemWithSameExtrasAndNote = _lines.find(
            _line =>
              _line.id === line.id &&
              _line.note === line.note &&
              equals(_line.extras, line.extras),
          )

          if (itemWithSameExtrasAndNote) {
            shouldMerge = itemWithSameExtrasAndNote
          } else {
            shouldMerge = false
          }
        } else {
          shouldMerge = false
        }

        if (shouldMerge) {
          shouldMerge.quantity += 1
          shouldMerge.itemstampIds.push(line.itemstampIds[0])
        } else {
          _lines.push(line)
        }

        return _lines
      }, [])

      lines = lines.map(line => {
        const category = menu.categories.find(
          category => category.id === line.categoryId,
        )

        if (category) {
          if (category.extras) {
            line.extras = line.extras.concat(
              category.extras.filter(
                categoryExtra =>
                  !line.extras.find(
                    lineExtra => lineExtra.id === categoryExtra.id,
                  ),
              ),
            )
          }

          const item = category.items.find(item => item.id === line.id)

          if (item?.extras) {
            line.extras = line.extras.concat(
              item.extras.filter(
                itemExtra =>
                  !line.extras.find(lineExtra => lineExtra.id === itemExtra.id),
              ),
            )
          }

          line.photoUrl = item.photoUrl
        }

        return line
      })

      lines = lines.map(line => {
        line.priceWithExtra =
          line.price +
          line.extras.reduce((total, extra) => {
            if (extra.quantity) {
              total += extra.quantity * extra.price
            }

            return total
          }, 0)

        line.priceWithExtra = formatPrice(
          line.priceWithExtra,
          menu.currency,
          true,
        )

        return line
      })

      return lines
    }
  },
)

export const itemsCountSelector = createSelector([mealProps], meal =>
  meal ? new MealHelper(meal, true).getItemsCount() : 0,
)

export const totalPriceSelector = createSelector(
  [itemsSelector, mealProps, menuProps],
  (items, meal, menu) => {
    if (items && meal && menu) {
      let totalPrice = 0

      if (items.length && items[0].status !== Itemstamp.Status.CANCELLED) {
        totalPrice = items.reduce((total, item) => {
          total += item.quantity * item.price
          total += item.extras.reduce((_total, extra) => {
            if (extra.quantity) {
              _total += item.quantity * extra.price
            }

            return _total
          }, 0)

          return total
        }, 0)
      }

      return formatPrice(totalPrice, menu.currency, true)
    }
  },
)
