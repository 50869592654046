/* Copyright 2013 - 2024 Waiterio LLC */
import Immutable from 'seamless-immutable'
import Extra from './Extra.js'
import Item from './Item.js'
import UUID from './UUID.js'

function Category(source) {
  if (source instanceof Category) {
    return source
  } else {
    Object.keys(source || {}).forEach(key => {
      this[key] = source[key]
    })

    if (!this.id) {
      this.id = UUID.generate()
    }

    if (!this.creationTime) {
      this.creationTime = new Date().getTime()
    }

    if (!this.lastEditTime) {
      this.lastEditTime = this.creationTime
    }

    if (!this.extras) {
      this.extras = []
    }

    if (!this.items) {
      this.items = []
    }

    if (this.items) {
      for (let i = 0; i < this.items.length; i += 1) {
        this.items[i] = new Item(this.items[i])
      }
    }

    return Immutable(this, { prototype: Category.prototype })
  }
}

Category.prototype.constructor = Category

Category.UNCATEGORIZED = 'uncategorized'

Category.prototype.getExtraIndex = function getExtraIndex(extraId) {
  let index = null
  let found = false
  let currentIndex = 0

  while (currentIndex < this.extras.length && !found) {
    if (this.extras[currentIndex].id === extraId) {
      found = true
      index = currentIndex
    }

    currentIndex += 1
  }

  return index
}

Category.prototype.getItemIndex = function getItemIndex(itemId) {
  let index = null
  let found = false
  let currentIndex = 0

  if (this.items) {
    while (currentIndex < this.items.length && !found) {
      if (this.items[currentIndex].id === itemId) {
        found = true
        index = currentIndex
      }

      currentIndex += 1
    }
  }

  return index
}

Category.prototype.getExtra = function getExtra(extraId) {
  let extra = null

  const extraIndex = this.getExtraIndex(extraId)

  if (extraIndex || extraIndex === 0) {
    extra = this.extras[extraIndex]
  }

  return extra
}

Category.prototype.getItem = function getItem(itemId) {
  let item = null

  const itemIndex = this.getItemIndex(itemId)

  if (itemIndex || itemIndex === 0) {
    item = this.items[itemIndex]
  }

  return item
}

Category.prototype.addOrUpdateExtra = function addOrUpdateExtra(extra) {
  let category = this

  if (!extra.id) {
    category = category.setIn(
      ['extras', category.extras.length],
      new Extra(extra),
    )
  } else {
    let index = this.getExtraIndex(extra.id)

    if (!index && index !== 0) {
      index = this.extras.length
    }

    category = category.setIn(['extras', index], new Extra(extra))
  }

  return category
}

Category.prototype.addOrUpdateItem = function addOrUpdateItem(item) {
  let category = this

  if (!item.id) {
    category = category.setIn(['items', category.items.length], new Item(item))
  } else {
    let index = this.getItemIndex(item.id)

    if (!index && index !== 0) {
      index = this.items.length
    }

    category = category.setIn(['items', index], new Item(item))
  }

  return category
}

Category.prototype.removeExtra = function removeExtra(extraId) {
  return this.set(
    'extras',
    this.extras.filter(extra => extra.id !== extraId),
  )
}

Category.prototype.removeItem = function removeItem(itemId) {
  return this.set(
    'items',
    this.items.filter(item => item.id !== itemId),
  )
}

export default Category
