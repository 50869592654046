/* Copyright 2013 - 2024 Waiterio LLC */
import Immutable from 'seamless-immutable'
import PaymentMethod from './PaymentMethod.js'
import UUID from './UUID.js'

function Restaurant(source) {
  if (source instanceof Restaurant) {
    return source
  } else {
    Object.keys(source || {}).forEach(key => {
      this[key] = source[key]
    })

    if (!this._id) {
      this._id = UUID.generate()
    }

    if (!this.shortId) {
      this.shortId = UUID.generateRestaurantShortId()
    }

    if (!this.creationTime) {
      this.creationTime = new Date().getTime()
    }

    if (!this.lastEditTime) {
      this.lastEditTime = this.creationTime
    }

    if (!this.paymentMethods) {
      this.paymentMethods = []
    }

    if (this.paymentMethods) {
      for (let i = 0; i < this.paymentMethods.length; i += 1) {
        this.paymentMethods[i] = new PaymentMethod(this.paymentMethods[i])
      }
    }

    if (!this.verifiedFields) {
      this.verifiedFields = []
    }

    return Immutable(this, { prototype: Restaurant.prototype })
  }
}

Restaurant.prototype.constructor = Restaurant

Restaurant.Type = {
  CASUAL_DINING: 'Casual Dining',
  FINE_DINING: 'Fine Dining',
  BUFFET: 'Buffet',
  HOTEL: 'Hotel',
  BAR_PUB: 'Bar/Pub',
  NIGHTCLUB: 'Nightclub',
  COUNTER_SERVICE: 'Counter Service',
  FAST_FOOD: 'Fast Food',
  COFFEE_SHOP: 'Coffee Shop',
  FOOD_TRUCK: 'Food Truck',
  DELIVERY: 'Delivery',
  SHISHA: 'Shisha',
  CAFETERIA: 'Cafeteria',
  DELI_GROCERY: 'Deli/Grocery',
  BAKERY: 'Bakery',
  CATERING: 'Catering',
  FESTIVAL: 'Festival',
}

Restaurant.prototype.addOrUpdatePaymentMethod =
  function addOrUpdatePaymentMethod(paymentMethod) {
    let index = this.getPaymentMethodIndex(paymentMethod.id)

    if (!index && index !== 0) {
      index = this.paymentMethods.length
    }

    return this.setIn(
      ['paymentMethods', index],
      new PaymentMethod(paymentMethod),
    )
  }

Restaurant.prototype.getPaymentMethodIndex = function getPaymentMethodIndex(
  paymentMethodId,
) {
  const paymentMethodIndex = this.paymentMethods.findIndex(
    paymentMethod => paymentMethod.id === paymentMethodId,
  )

  return paymentMethodIndex >= 0 ? paymentMethodIndex : null
}

Restaurant.prototype.removePaymentMethod = function removePaymentMethod(
  paymentMethod,
) {
  return this.removePaymentMethodById(paymentMethod.id)
}

Restaurant.prototype.removePaymentMethodById = function removePaymentMethodById(
  paymentMethodId,
) {
  return this.set(
    'paymentMethods',
    this.paymentMethods.filter(
      paymentMethod => paymentMethod.id !== paymentMethodId,
    ),
  )
}

export default Restaurant
