/* Copyright 2013 - 2024 Waiterio LLC */
import Immutable from 'seamless-immutable'

function Tax(source) {
  if (source instanceof Tax) {
    return source
  } else {
    Object.keys(source || {}).forEach(key => {
      this[key] = source[key]
    })

    if (!this.quantity) {
      this.quantity = 0
    }

    if (typeof this.alreadyIncludedInItems === 'undefined') {
      this.alreadyIncludedInItems = true
    }

    return Immutable(this, { prototype: Tax.prototype })
  }
}

export default Tax
