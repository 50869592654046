/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'

const IconClose = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-close'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M19.008 6.408l-1.416-1.416-5.592 5.592-5.592-5.592-1.416 1.416 5.592 5.592-5.592 5.592 1.416 1.416 5.592-5.592 5.592 5.592 1.416-1.416-5.592-5.592z" />
    </svg>
  )
}

export default IconClose
