/* Copyright 2013 - 2024 Waiterio LLC */
import IS_DEVELOPMENT from '@monorepo/env/IS_DEVELOPMENT.js'
import IS_PRODUCTION from '@monorepo/env/IS_PRODUCTION.js'

export default function getStripePublishableKeyUS() {
  let stripePublishableKey = 'pk_test_ujDoFFAgNWpqYX5tHP5nGzV9'

  if (IS_PRODUCTION) {
    stripePublishableKey = 'pk_live_UUN2TWFtpKArPJ2PAV5c95nS'
  } else if (IS_DEVELOPMENT) {
    stripePublishableKey = 'pk_test_ujDoFFAgNWpqYX5tHP5nGzV9'
    // stripePublishableKey = 'pk_live_UUN2TWFtpKArPJ2PAV5c95nS';
  }

  return stripePublishableKey
}
