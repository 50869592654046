/* Copyright 2013 - 2024 Waiterio LLC */
import localForage from 'localforage'
import { produce } from '@monorepo/context/index.js'
import Payment from '@waiterio/model/Payment.js'

const prefix = 'whitelabel.waiterio.payments.'

export function plantPayment(payment) {
  return localForage.setItem(prefix + payment._id, payment).then(payment => {
    payment = new Payment(payment)

    produce(draft => {
      draft.data.payments[payment._id] = payment
    })

    return payment
  })
}

export function burnPayment(paymentId) {
  return localForage.removeItem(prefix + paymentId).then(() => {
    produce(draft => {
      delete draft.data.payments[paymentId]
    })

    return paymentId
  })
}

export function pickPayment(paymentId) {
  return localForage.getItem(prefix + paymentId).then(payment => {
    console.log('returning', payment ? new Payment(payment) : null)
    return payment ? new Payment(payment) : null
  })
}

export function pickPayments() {
  return localForage
    .keys()
    .then(keyss =>
      Promise.all(
        keyss
          .filter(key => key.indexOf(prefix) > -1)
          .map(key =>
            localForage.getItem(key).then(payment => new Payment(payment)),
          ),
      ),
    )
}
