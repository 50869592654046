/* Copyright 2013 - 2024 Waiterio LLC */
import Immutable from 'seamless-immutable'

function ServiceCharge(source) {
  if (source instanceof ServiceCharge) {
    return source
  } else {
    Object.keys(source || {}).forEach(key => {
      this[key] = source[key]
    })

    if (!this.quantityType) {
      this.quantityType = ServiceCharge.QuantityType.FIXED
    }

    if (!this.quantity) {
      this.quantity = 0
    }

    return Immutable(this, { prototype: ServiceCharge.prototype })
  }
}

ServiceCharge.QuantityType = {
  FIXED: 'FIXED',
  PERCENTAGE: 'PERCENTAGE',
}

export default ServiceCharge
