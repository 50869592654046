/* Copyright 2013 - 2024 Waiterio LLC */
import localForage from 'localforage'
import { produce } from '@monorepo/context/index.js'
import Meal from '@waiterio/model/Meal.js'

const prefix = 'whitelabel.waiterio.meals.'

export function plantMeal(meal) {
  return localForage.setItem(prefix + meal._id, meal).then(meal => {
    meal = new Meal(meal)

    produce(draft => {
      draft.data.meals[meal._id] = meal
    })

    return meal
  })
}

export function burnMeal(mealId) {
  return localForage.removeItem(prefix + mealId).then(() => {
    produce(draft => {
      delete draft.data.meals[mealId]
    })

    return mealId
  })
}

export function pickMeal(mealId) {
  return localForage.getItem(prefix + mealId).then(meal => {
    console.log('returning', meal ? new Meal(meal) : null)
    return meal ? new Meal(meal) : null
  })
}

export function pickMeals() {
  return localForage
    .keys()
    .then(keyss =>
      Promise.all(
        keyss
          .filter(key => key.indexOf(prefix) > -1)
          .map(key => localForage.getItem(key).then(meal => new Meal(meal))),
      ),
    )
}
