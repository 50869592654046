/* Copyright 2013 - 2024 Waiterio LLC */
import { getContext, produce } from '@monorepo/context/index.js'
import getRestaurant from './getRestaurant.js'

export default async function fetchRestaurant(idOrVanityId) {
  let { data } = getContext()
  let { restaurant } = data

  if (!restaurant._id) {
    restaurant = await getRestaurant(idOrVanityId)

    produce(draft => {
      draft.data.restaurant = restaurant
    })
  }

  return restaurant
}
