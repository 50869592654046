/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint no-bitwise: 0 */

const UUID = {}

UUID.CHARS_UNREADABLE =
  '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ' // 62 CHARACTERS
UUID.CHARS_READABLE =
  '0123456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ' // 59 CHARACTERS EXCLUDING l I O FOR READABILITY

UUID.generate = function generate() {
  return 'xxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[x]/g, c => {
    let r = (Math.random() * 16) | 0
    let v = c === 'x' ? r : (r & 3) | 8
    return v.toString(16)
  })
}

UUID.uuid = function uuid(lenght, base, readable) {
  const CHARS = readable ? UUID.CHARS_READABLE : UUID.CHARS_UNREADABLE

  let uuid = ''

  for (let i = 0; i < lenght; i += 1) {
    uuid += CHARS[Math.floor(Math.random() * base)]
  }

  return uuid
}

UUID.generateMealShortId = function generateMealShortId() {
  return UUID.uuid(4, UUID.CHARS_READABLE.length, true)
}

UUID.generateRestaurantShortId = function generateRestaurantShortId() {
  return UUID.uuid(6, UUID.CHARS_UNREADABLE.length, false)
}

export default UUID
