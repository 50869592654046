/* Copyright 2013 - 2024 Waiterio LLC */
import getDefaultFractionDigits from '@monorepo/shared/currencies/getDefaultFractionDigits.js'
import getCurrencySymbol from '@monorepo/shared/currencies/getCurrencySymbol.js'

export const formatPrice = (price, currencyCode, showCurrencySymbol) => {
  if (typeof price === 'undefined' || price === null) {
    price = 0
  }

  if (!Number.isNaN(price)) {
    if (typeof price === 'string') {
      price = parseFloat(price, 10)
    }

    price = price.toFixed(getDefaultFractionDigits(currencyCode))
  }

  if (showCurrencySymbol) {
    const currencySymbol = getCurrencySymbol(currencyCode)

    price = currencySymbol + price
  }

  return price
}

export const formatPriceWithoutSymbol = (price, currencyCode) =>
  formatPrice(price, currencyCode, false)

export const formatPriceWithSymbol = (price, currencyCode) =>
  formatPrice(price, currencyCode, true)

function PriceFormatter(currencyCode, showCurrencySymbol) {
  this.currencyCode = currencyCode
  this.showCurrencySymbol = showCurrencySymbol
}

PriceFormatter.prototype.format = function format(price) {
  return formatPrice(price, this.currencyCode, this.showCurrencySymbol)
}

export default PriceFormatter
