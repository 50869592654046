/* Copyright 2013 - 2024 Waiterio LLC */
import Pusher from 'pusher-js'
import IS_DEVELOPMENT from '@monorepo/env/IS_DEVELOPMENT.js'
import IS_PRODUCTION from '@monorepo/env/IS_PRODUCTION.js'
import ENVIRONMENT from '@monorepo/env/ENVIRONMENT.js'
import { getContext } from '@monorepo/context/index.js'
import Event from '@waiterio/model/Event.js'
import getMeal from '@waiterio/api-client/getMeal.js'

import PushNotificationHandler from './PushNotificationHandler.js'

import { pickMeal, plantMeal } from '../forage/MealsForage.js'
import { currentMealSelector } from '../selectors/AppSelectors.js'

const forceTLS = !!IS_DEVELOPMENT

const pusher = new Pusher('8af67bd4bfc5f2d7874b', { forceTLS })

let channel = null
let channelName = null

export default {
  subscribe() {
    const context = getContext()
    const { restaurant } = context.data

    if (restaurant._id) {
      if (channel && channelName) {
        pusher.unsubscribe(channelName)
        channel = null
        channelName = null
      }

      channelName = 'restaurant_' + restaurant._id

      if (!IS_PRODUCTION) {
        channelName += '_' + ENVIRONMENT
      }

      channel = pusher.subscribe(channelName)

      channel.bind(Event.MEAL_UPDATED_EVENT, this.handleMealUpdatedEvent, this)
    }
  },

  handleMealUpdatedEvent(mealEvent) {
    const context = getContext()
    const { mealId } = mealEvent
    const meal = currentMealSelector(context, { mealId })
    const { restaurant } = context.data

    if (
      mealEvent.restaurantId &&
      meal &&
      mealEvent.restaurantId === restaurant._id
    ) {
      if (mealEvent.meal) {
        pickMeal(mealEvent.mealId).then(() => {
          plantMeal(mealEvent.meal).then(() =>
            PushNotificationHandler.sendNotification(mealEvent),
          )
        })
      } else {
        getMeal(mealEvent.mealId).then(meal => {
          mealEvent.meal = meal
          this.handleMealAddedOrUpdatedEvent(mealEvent)
        })
      }
    }
  },
}
