/* Copyright 2013 - 2024 Waiterio LLC */
import { createSelector } from 'reselect'
import { formatPrice } from '@waiterio/shared/PriceFormatter.js'
import MealHelper from '@waiterio/shared/MealHelper.js'

import values from 'ramda/src/values.js'
import last from 'ramda/src/last.js'
import equals from 'ramda/src/equals.js'

import { toLines, sortLines } from '@waiterio/shared/liner.js'

const mealProps = (context, props) => props?.meal
const menuProps = (context, props) => props?.menu

export const totalPriceSelector = createSelector([mealProps], meal =>
  meal ? new MealHelper(meal, true).getTotalPrice() : 0,
)

export const totalPriceFormattedSelector = createSelector(
  [totalPriceSelector, menuProps],
  (totalPrice, menu) => {
    if (menu) {
      return formatPrice(totalPrice, menu.currency, true)
    }
  },
)

export const taxPriceSelector = createSelector(
  [mealProps, menuProps],
  (meal, menu) => {
    if (meal && menu) {
      return formatPrice(
        new MealHelper(meal, true, undefined).getTaxPrice(),
        menu.currency,
        true,
      )
    }
  },
)

export const itemsCountSelector = createSelector([mealProps], meal =>
  meal ? new MealHelper(meal, true).getItemsCount() : 0,
)

export const itemsSelector = createSelector(
  [mealProps, menuProps],
  (meal, menu) => {
    if (meal && menu) {
      let itemstamps = values(meal.itemstamps)

      let lines = toLines(itemstamps)

      lines = sortLines(menu, lines)

      lines.forEach(line => {
        line.extras = line.extras.sort((firstExtra, secondExtra) => {
          if (firstExtra.name > secondExtra.name) {
            return 1
          } else if (secondExtra.name > firstExtra.name) {
            return -1
          } else {
            return 0
          }
        })
      })

      lines = lines.reduce((_lines, line) => {
        const lastLine = last(_lines)
        const sameItem = lastLine && lastLine.id === line.id
        const sameItemWithSameExtrasAndNote =
          sameItem &&
          lastLine.note === line.note &&
          equals(lastLine.extras, line.extras)

        let shouldMerge

        if (sameItemWithSameExtrasAndNote) {
          shouldMerge = lastLine
        } else if (!sameItemWithSameExtrasAndNote && sameItem) {
          let itemWithSameExtrasAndNote = _lines.find(
            _line =>
              _line.id === line.id &&
              _line.note === line.note &&
              equals(_line.extras, line.extras),
          )

          if (itemWithSameExtrasAndNote) {
            shouldMerge = itemWithSameExtrasAndNote
          } else {
            shouldMerge = false
          }
        } else {
          shouldMerge = false
        }

        if (shouldMerge) {
          shouldMerge.quantity += 1
          shouldMerge.itemstampIds.push(line.itemstampIds[0])
        } else {
          _lines.push(line)
        }

        return _lines
      }, [])

      lines = lines.map(line => {
        const category = menu.categories.find(
          category => category.id === line.categoryId,
        )

        if (category) {
          if (category.extras) {
            line.extras = line.extras.concat(
              category.extras.filter(
                categoryExtra =>
                  !line.extras.find(
                    lineExtra => lineExtra.id === categoryExtra.id,
                  ),
              ),
            )
          }

          const item = category.items.find(item => item.id === line.id)

          if (item?.extras) {
            line.extras = line.extras.concat(
              item.extras.filter(
                itemExtra =>
                  !line.extras.find(lineExtra => lineExtra.id === itemExtra.id),
              ),
            )
          }

          line.photoUrl = item.photoUrl
        }

        return line
      })

      lines = lines.map(line => {
        line.priceWithExtra =
          line.price +
          line.extras.reduce((total, extra) => {
            if (extra.quantity) {
              total += extra.quantity * extra.price
            }

            return total
          }, 0)

        line.priceWithExtra = formatPrice(
          line.priceWithExtra,
          menu.currency,
          true,
        )

        return line
      })

      return lines
    }
  },
)
