/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { Link } from 'react-router-dom'
import { useTranslation } from '@multilocale/react/index.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import colMd6 from '@waiterio/styles/bootstrap/colMd6.js'
import colLg4 from '@waiterio/styles/bootstrap/colLg4.js'
import colSm12 from '@waiterio/styles/bootstrap/colSm12.js'
import countries from '@monorepo/shared/countries.js'
import useMenu from '../useMenu.js'
import useRestaurant from '../useRestaurant.js'
import useSubdomain from '../useSubdomain.js'

const footerSection = css({
  paddingTop: 20,
})

const footerBox = css({
  display: 'flex',
  flexDirection: 'column',
  padding: 20,
})

const footerItemHeader = css({
  color: '#333',
  fontSize: 24,
  margin: 0,
  textTransform: 'capitalize',
})

const FooterComponent = props => {
  const locale = props.locale || 'en'
  const { t } = useTranslation(locale)
  const vanityId = useSubdomain()
  const { data: restaurant = {} } = useRestaurant(vanityId)
  const { data: menu = {} } = useMenu(restaurant?._id)

  const {
    name,
    street,
    zipcode,
    city,
    state,
    countryCode,
    email,
    phone,
    website,
    whatsapp,
    facebookId,
    instagramId,
    tripadvisorId,
    deliveryServiceOffered,
    takeawayServiceOffered,
  } = restaurant

  const showOrderOnline =
    menu &&
    phone &&
    (deliveryServiceOffered !== false || takeawayServiceOffered !== false)

  return (
    <footer css={[footerSection, container]}>
      <div css={row}>
        {(street || city) && (
          <div css={[colSm12, colMd6, colLg4]}>
            <div css={footerBox}>
              <h4 css={footerItemHeader}>{t('Address')}</h4>
              <div css={{ marginTop: 10 }}>{name}</div>
              {street && (
                <div css={{ marginTop: 5 }}>{`${street || ''}`?.trim()}</div>
              )}
              {(city || zipcode) && (
                <div css={{ marginTop: 5 }}>
                  {`${zipcode || ''} ${city || ''}`?.trim()}
                </div>
              )}
              {(state || countryCode) && (
                <div css={{ marginTop: 5 }}>
                  {`${state || ''} ${
                    t(countries[countryCode] ?? countryCode, locale) || ''
                  }`?.trim()}
                </div>
              )}
            </div>
          </div>
        )}
        {(email || phone || whatsapp || website) && (
          <div css={[colSm12, colMd6, colLg4]}>
            <div css={footerBox}>
              <h4 css={footerItemHeader}>{t('Contact us')}</h4>
              {email && (
                <a
                  href={`mailto:${email}`}
                  css={{ marginTop: 10 }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {email}
                </a>
              )}
              {phone && (
                <a href={`tel:${phone}`} css={{ marginTop: 5 }}>
                  {phone}
                </a>
              )}
              {whatsapp && (
                <a
                  href={`https://api.whatsapp.com/send?phone=${whatsapp}`}
                  css={{ marginTop: 5 }}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Whatsapp {whatsapp}
                </a>
              )}
              {website && (
                <a
                  href={
                    website.includes('http') ? website : `http://${website}`
                  }
                  css={{ marginTop: 5 }}
                >
                  {website}
                </a>
              )}
            </div>
          </div>
        )}

        {(facebookId || instagramId || tripadvisorId) && (
          <div css={[colSm12, colMd6, colLg4]}>
            <div css={footerBox}>
              <h4 css={footerItemHeader}>{t('Follow Us')}</h4>
              {facebookId && (
                <div css={{ marginTop: 10 }}>
                  <a
                    href={`https://www.facebook.com/${facebookId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Facebook
                  </a>
                </div>
              )}
              {instagramId && (
                <div css={{ marginTop: 10 }}>
                  <a
                    href={`https://www.instagram.com/${instagramId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Instagram
                  </a>
                </div>
              )}
              {tripadvisorId && (
                <div css={{ marginTop: 5 }}>
                  <a
                    href={tripadvisorId}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Tripadvisor
                  </a>
                </div>
              )}
            </div>
          </div>
        )}
        <div css={[colSm12, colMd6, colLg4]}>
          <div css={footerBox}>
            <h4 css={footerItemHeader}>{t('More Links')}</h4>
            <div
              css={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}
            >
              <Link to={`/${locale}`}>{t('Home')}</Link>
              <Link to={`/${locale}/contact`} css={{ marginTop: 5 }}>
                {t('Contact')}
              </Link>
              {menu && (
                <Link to={`/${locale}/menu`} css={{ marginTop: 5 }}>
                  {t('Menu')}
                </Link>
              )}
              {showOrderOnline && (
                <Link to={`/${locale}/order`} css={{ marginTop: 5 }}>
                  {t('Order Online')}
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      <div css={[row, { marginTop: 40 }]}>
        <div css={[colSm12, { textAlign: 'center' }]}>
          <div css={{ borderTop: '1px solid #efefef', padding: '20px 0' }}>
            {t('Copyright')} {new Date().getFullYear()} {name}.&nbsp;
            {t('Powered by')}&nbsp;
            <a href={`https://www.waiterio.com/${locale}/`}>Waiterio</a>{' '}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default FooterComponent
