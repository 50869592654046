/* Copyright 2013 - 2024 Waiterio LLC */
import either from 'ramda/src/either.js'
import nthArg from 'ramda/src/nthArg.js'
import compose from 'ramda/src/compose.js'
import propEq from 'ramda/src/propEq.js'
import converge from 'ramda/src/converge.js'
import assoc from 'ramda/src/assoc.js'
import curry from 'ramda/src/curry.js'
import map from 'ramda/src/map.js'
import find from 'ramda/src/find.js'
import findIndex from 'ramda/src/findIndex.js'
import reduce from 'ramda/src/reduce.js'
import comparator from 'ramda/src/comparator.js'
import lt from 'ramda/src/lt.js'
import prop from 'ramda/src/prop.js'
import sort from 'ramda/src/sort.js'
import dissoc from 'ramda/src/dissoc.js'
import pipe from 'ramda/src/pipe.js'
import values from 'ramda/src/values.js'
import last from 'ramda/src/last.js'

import Itemstamp from '@waiterio/model/Itemstamp.js'
import { formatPrice } from './PriceFormatter.js'

const equalsId = propEq('id')
const findById = converge(find, [compose(equalsId, nthArg(0)), nthArg(1)])

const findCategoryIndex = (categories, itemId) =>
  findIndex(category => findById(itemId, category.items), categories)
const setCategoryIndex = assoc('categoryIndex')
const setCategoryIndexOnItemstamp = converge(setCategoryIndex, [
  (categories, itemstamp) => findCategoryIndex(categories, itemstamp.id),
  nthArg(1),
])
const setCategoryIndexOnItemstamps = curry((menu, itemstamps) =>
  map(
    itemstamp => setCategoryIndexOnItemstamp(menu.categories, itemstamp),
    itemstamps,
  ),
)

const findItemIndex = (categories, itemId) =>
  map(
    category => findIndex(item => item.id === itemId, category.items),
    categories,
  ).find(index => index > -1)
const setItemIndex = assoc('itemIndex')
const setItemIndexOnItemstamp = converge(setItemIndex, [
  (categories, itemstamp) => findItemIndex(categories, itemstamp.id),
  nthArg(1),
])
const setItemIndexOnItemstamps = curry((menu, itemstamps) =>
  map(
    itemstamp => setItemIndexOnItemstamp(menu.categories, itemstamp),
    itemstamps,
  ),
)

const findStatusIndex = (statuses, itemStatus) =>
  findIndex(status => status === itemStatus)(statuses)
const setStatusIndex = assoc('statusIndex')
const setStatusIndexOnItemstamp = converge(setStatusIndex, [
  (statuses, itemstamp) => findStatusIndex(statuses, itemstamp.status),
  nthArg(1),
])
const setStatusIndexOnItemstamps = curry((statuses, itemstamps) =>
  map(itemstamp => setStatusIndexOnItemstamp(statuses, itemstamp), itemstamps),
)

const variadicEither = (_head, ..._tail) => reduce(either, _head, _tail)
const makeComparator = _prop =>
  comparator((a, b) => lt(prop(_prop, a), prop(_prop, b)))
const sortByProps = curry((_props, list) =>
  sort(variadicEither(...map(makeComparator, _props)), list),
)

const removeCategoryIndex = dissoc('categoryIndex')
const removeItemIndex = dissoc('itemIndex')
const removeStatusIndex = dissoc('statusIndex')
const removeIndexes = pipe(
  removeCategoryIndex,
  removeItemIndex,
  removeStatusIndex,
)
const removeIndexesFromList = map(removeIndexes)

const findCategory = (categories, itemId) =>
  find(category => findById(itemId, category.items), categories)
const findCategoryId = (categories, itemId) => {
  let category = findCategory(categories, itemId)
  return category ? category.id : null
}
const setCategoryId = assoc('categoryId')
const setCategoryIdOnItemstamp = converge(setCategoryId, [
  (categories, itemstamp) => findCategoryId(categories, itemstamp.id),
  nthArg(1),
])
const setCategoryIdOnItemstamps = curry((menu, itemstamps) =>
  map(
    itemstamp => setCategoryIdOnItemstamp(menu.categories, itemstamp),
    itemstamps,
  ),
)

export const sortLines = (menu, itemstamps) =>
  pipe(
    setCategoryIndexOnItemstamps(menu),
    setStatusIndexOnItemstamps(Itemstamp.Status.getAll()),
    setItemIndexOnItemstamps(menu),
    sortByProps(['course', 'categoryIndex', 'itemIndex', 'statusIndex']),
    removeIndexesFromList,
    setCategoryIdOnItemstamps(menu),
  )(itemstamps)

export const toLine = itemstamp => ({
  quantity: 1,
  itemstampIds: [itemstamp.id],

  id: itemstamp.item.id,
  name: itemstamp.item.name,
  price: itemstamp.item.price,
  course: itemstamp.course,
  paid: itemstamp.paid,
  status: itemstamp.status,
  note: itemstamp.note,
  userId: itemstamp.userId,

  extras: values(
    [].concat(itemstamp.extras || []).reduce((extras, extra) => {
      if (extras[extra.id]) {
        extras[extra.id].quantity += 1
      } else {
        extras[extra.id] = {
          id: extra.id,
          quantity: 1,
          name: extra.name,
          price: extra.price,
        }
      }

      return extras
    }, {}),
  ),
})

export const toLines = map(toLine)

export const mergeLines = (
  lines,
  mergeNoteAndExtras,
  mergeStatus,
  mergeCourses,
) => {
  if (mergeNoteAndExtras === undefined) {
    mergeNoteAndExtras = true
  }
  if (mergeStatus === undefined) {
    mergeStatus = true
  }
  if (mergeCourses === undefined) {
    mergeCourses = true
  }

  return lines.reduce((lines, line) => {
    const lastLine = last(lines)

    let shouldMerge = lastLine && lastLine.id === line.id

    if (shouldMerge && !mergeCourses) {
      shouldMerge = lastLine.course === line.course
    }

    if (shouldMerge && !mergeNoteAndExtras) {
      shouldMerge =
        !(
          lastLine.extras.length > 0 ||
          (lastLine.note && lastLine.note.length > 0)
        ) && !(line.extras.length > 0 || (line.note && line.note.length > 0))
    }

    if (shouldMerge && !mergeStatus) {
      shouldMerge = lastLine.status === line.status
    }

    if (shouldMerge) {
      lastLine.quantity += 1
      lastLine.itemstampIds.push(line.itemstampIds[0])
    } else {
      lines.push(line)
    }

    return lines
  }, [])
}

export const mergeAllLines = lines => mergeLines(lines, true, true, true)

export const mergeLinesWithDifferentStatus = lines =>
  mergeLines(lines, false, true, true)

export const mergeLinesWithSameStatusWithoutNoteAndExtras = lines =>
  mergeLines(lines, false, false, false)

export const mergeLinesWithSameStatusWithoutNoteAndExtrasAndNoCourses = lines =>
  mergeLines(lines, false, false, true)

export const formatLinesPrices = (menu, lines) =>
  lines.map(line => {
    line.price = formatPrice(line.price, menu.currency)

    line.extras = line.extras.map(extra => {
      extra.price = formatPrice(extra.price, menu.currency)
      return extra
    })

    return line
  })
