/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect, useRef, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { useParams } from 'react-router-dom'
import { useContext } from '@monorepo/context/index.js'
import { useTranslation } from '@multilocale/react/index.js'
import IconAdd from '@stiloso/icons/IconAdd.js'
import IconRemove from '@stiloso/icons/IconRemove.js'
import IconClose from '@stiloso/icons/IconClose.js'
import IconAngleLeft from '@stiloso/icons/IconAngleLeft.js'
import IconAngleRight from '@stiloso/icons/IconAngleRight.js'
import { formatPriceWithSymbol } from '@waiterio/shared/PriceFormatter.js'
import Itemstamp from '@waiterio/model/Itemstamp.js'
import Meal from '@waiterio/model/Meal.js'
import colLg4 from '@waiterio/styles/bootstrap/colLg4.js'
import colMd12 from '@waiterio/styles/bootstrap/colMd12.js'
import colSm12 from '@waiterio/styles/bootstrap/colSm12.js'
import colLg8 from '@waiterio/styles/bootstrap/colLg8.js'
import dNone from '@waiterio/styles/bootstrap/dNone.js'
import dLgBlock from '@waiterio/styles/bootstrap/dLgBlock.js'
import dLgNone from '@waiterio/styles/bootstrap/dLgNone.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import getMeals from '@waiterio/api-client/getMeals.js'
import { burnMeal, plantMeal } from '../forage/MealsForage.js'
import AddExtrasAndNoteDialog from '../dialogs/AddExtrasAndNoteDialog.js'
import ErrorDialog from '../dialogs/ErrorDialog.js'
import LoadingDialog from '../dialogs/LoadingDialog.js'
import addMeal from '../updater/addMeal.js'
import headingContainer from '../styles/headingContainer.js'
import headingTitle from '../styles/headingTitle.js'
import {
  categoriesSelector,
  mealSelector,
} from '../selectors/TableSelectors.js'
import {
  itemsSelector,
  totalPriceFormattedSelector,
  taxPriceSelector,
} from '../selectors/PaymentSelectors.js'
import useMenu from '../useMenu.js'
import useRestaurant from '../useRestaurant.js'
import useSubdomain from '../useSubdomain.js'
import languages from '../languages.js'

const { ANNOTATED } = Itemstamp.Status

const orderContainer = css`
  background-color: white;
  padding: 50px 0;
`

const errorMessage = css({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  height: 160,
  fontSize: 36,
  color: 'red',
  padding: 36,
})

export const paths = languages
  .map(locale => [
    {
      path: `/${locale}/table`,
      props: {
        locale,
      },
    },
    {
      path: `/${locale}/table/*`,
      props: {
        locale,
      },
    },
    {
      path: `/${locale}/table/:table`,
      props: {
        locale,
      },
    },
    {
      path: `/${locale}/tables/:table`,
      props: {
        locale,
      },
    },
    // TODO temporary fix for old qr code links for https://analytics.waiterio.com/restaurants/e810a23b9c659ae4561f39c0
    {
      path: `/${locale}/table1`,
      props: {
        locale,
        table: '1',
      },
    },
    {
      path: `/${locale}/table2`,
      props: {
        locale,
        table: '2',
      },
    },
    {
      path: `/${locale}/table3`,
      props: {
        locale,
        table: '3',
      },
    },
    {
      path: `/${locale}/table4`,
      props: {
        locale,
        table: '4',
      },
    },
    {
      path: `/${locale}/table5`,
      props: {
        locale,
        table: '5',
      },
    },
    {
      path: `/${locale}/table6`,
      props: {
        locale,
        table: '6',
      },
    },
    {
      path: `/${locale}/table7`,
      props: {
        locale,
        table: '7',
      },
    },
    {
      path: `/${locale}/table8`,
      props: {
        locale,
        table: '8',
      },
    },
    {
      path: `/${locale}/table9`,
      props: {
        locale,
        table: '9',
      },
    },
    {
      path: `/${locale}/table10`,
      props: {
        locale,
        table: '10',
      },
    },
    {
      path: `/${locale}/table11`,
      props: {
        locale,
        table: '11',
      },
    },
    {
      path: `/${locale}/table12`,
      props: {
        locale,
        table: '12',
      },
    },
    {
      path: `/${locale}/table13`,
      props: {
        locale,
        table: '13',
      },
    },
    {
      path: `/${locale}/table14`,
      props: {
        locale,
        table: '14',
      },
    },
    {
      path: `/${locale}/table15`,
      props: {
        locale,
        table: '15',
      },
    },
    {
      path: `/${locale}/table16`,
      props: {
        locale,
        table: '16',
      },
    },
    {
      path: `/${locale}/table17`,
      props: {
        locale,
        table: '17',
      },
    },
    {
      path: `/${locale}/table18`,
      props: {
        locale,
        table: '18',
      },
    },
    {
      path: `/${locale}/table19`,
      props: {
        locale,
        table: '19',
      },
    },
    {
      path: `/${locale}/table20`,
      props: {
        locale,
        table: '20',
      },
    },
    {
      path: `/${locale}/table21`,
      props: {
        locale,
        table: '21',
      },
    },
    {
      path: `/${locale}/table22`,
      props: {
        locale,
        table: '22',
      },
    },
    {
      path: `/${locale}/table23`,
      props: {
        locale,
        table: '23',
      },
    },
    {
      path: `/${locale}/table24`,
      props: {
        locale,
        table: '24',
      },
    },
    {
      path: `/${locale}/table25`,
      props: {
        locale,
        table: '25',
      },
    },
    {
      path: `/${locale}/table26`,
      props: {
        locale,
        table: '26',
      },
    },
    {
      path: `/${locale}/table27`,
      props: {
        locale,
        table: '27',
      },
    },
    {
      path: `/${locale}/table28`,
      props: {
        locale,
        table: '28',
      },
    },
    {
      path: `/${locale}/table29`,
      props: {
        locale,
        table: '29',
      },
    },
    {
      path: `/${locale}/table30`,
      props: {
        locale,
        table: '30',
      },
    },
    {
      path: `/${locale}/table31`,
      props: {
        locale,
        table: '31',
      },
    },
    {
      path: `/${locale}/table32`,
      props: {
        locale,
        table: '32',
      },
    },
    {
      path: `/${locale}/table33`,
      props: {
        locale,
        table: '33',
      },
    },
    {
      path: `/${locale}/table34`,
      props: {
        locale,
        table: '34',
      },
    },
    {
      path: `/${locale}/table35`,
      props: {
        locale,
        table: '35',
      },
    },
    {
      path: `/${locale}/table36`,
      props: {
        locale,
        table: '36',
      },
    },
    {
      path: `/${locale}/table37`,
      props: {
        locale,
        table: '37',
      },
    },
    {
      path: `/${locale}/table38`,
      props: {
        locale,
        table: '38',
      },
    },
    {
      path: `/${locale}/table39`,
      props: {
        locale,
        table: '39',
      },
    },
    {
      path: `/${locale}/table40`,
      props: {
        locale,
        table: '40',
      },
    },
    {
      path: `/${locale}/tableMcKabro`,
      props: {
        locale,
        table: 'McKabro',
      },
    },
    {
      path: `/${locale}/tableMinimal`,
      props: {
        locale,
        table: 'Minimal',
      },
    },
    {
      path: `/${locale}/tableInsurgente`,
      props: {
        locale,
        table: 'Insurgente',
      },
    },
    {
      path: `/${locale}/tablePasillo`,
      props: {
        locale,
        table: 'Pasillo',
      },
    },
    {
      path: `/${locale}/tableB-1`,
      props: {
        locale,
        table: 'B-1',
      },
    },
    {
      path: `/${locale}/tableB-2`,
      props: {
        locale,
        table: 'B-2',
      },
    },
    {
      path: `/${locale}/tableb-3`,
      props: {
        locale,
        table: 'b-3',
      },
    },
    {
      path: `/${locale}/tableb-4`,
      props: {
        locale,
        table: 'b-4',
      },
    },
    {
      path: `/${locale}/tableb.5`,
      props: {
        locale,
        table: 'b.5',
      },
    },
    {
      path: `/${locale}/tableb-6`,
      props: {
        locale,
        table: 'b-6',
      },
    },
    {
      path: `/${locale}/tableBarra`,
      props: {
        locale,
        table: 'Barra',
      },
    },
  ])
  .flat(1)

export const Head = ({ locale }) => {
  const { t } = useTranslation(locale)
  let [context] = useContext()

  const restaurant = context?.data?.restaurant ?? {}

  const url = `/${locale}/table/`
  const canonical = restaurant.vanityId
    ? `https://${restaurant.vanityId}.waiterio.com${url}`
    : null

  const title = `${t('Self Order')} | ${restaurant.name || t('Restaurant')}`
  const description = restaurant.description || t('Restaurant')

  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={restaurant.coverPhotoUr} />
      <meta property="og:site_name" content={t('Successful Restaurant')} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="article" />
      <meta property="og:url" content={canonical} />
      <meta property="og:image" content={restaurant.coverPhotoUr} />
      <meta name="application-name" content={title} />
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      {canonical && <link rel="canonical" href={canonical} />}
    </>
  )
}

const TablePage = props => {
  const { locale = 'en' } = props
  let { table } = useParams()
  table = table || props.table
  const { t } = useTranslation(locale)
  let [context, produce] = useContext()
  produce = produce(['pages', 'table'])
  let {
    addExtrasAndNoteDialog = false,
    sendingDialog,
    confirmationDialog,
    errorDialog,
  } = context.pages.table
  const { loading, error } = context
  const vanityId = useSubdomain()
  const { data: restaurant = {} } = useRestaurant(vanityId)
  const { data: menu = {} } = useMenu(restaurant?._id)

  let meal = mealSelector(context, { meal: props.meal, menu, table })
  const categories = categoriesSelector(context, { meal, menu })
  const items = itemsSelector(context, { meal, menu })
  const totalPrice = totalPriceFormattedSelector(context, { meal, menu })
  const taxPrice = taxPriceSelector(context, { meal, menu })
  const { currency: currencyCode } = menu

  const [smartphoneCartView, setSmartphoneCartView] = useState(false)
  const [activeCategory, setActiveCategory] = useState('')
  const [remainingNavbarScroll, setRemainingNavbarScroll] = useState(0)
  const [initialNavbarScroll, setInitialNavbarScroll] = useState(0)
  const categoriesRefs = {}

  const navbarRef = useRef({})

  const navbarCategoriesRefs = {}

  const toggleSmartphoneCartView = () => {
    setSmartphoneCartView(!smartphoneCartView)
  }

  useEffect(() => {
    const onScrollPage = event => {
      Object.values(categoriesRefs).forEach(categoryRef => {
        const scrollPosition = window.scrollY
        const currentElement = categoryRef.current

        if (currentElement) {
          let bottom =
            currentElement.offsetTop +
            currentElement.getBoundingClientRect().height

          if (
            currentElement.offsetTop + 132 <= scrollPosition &&
            scrollPosition - 172 <= bottom
          ) {
            const id = Object.keys(categoriesRefs).find(
              key => categoriesRefs[key] === categoryRef,
            )

            const navbarElement = navbarRef.current

            setActiveCategory(id)

            const screenWidth = window.innerWidth > 0 && window.innerWidth
            const firstNavbarCategoryId = Object.keys(navbarCategoriesRefs)[0]

            if (id !== firstNavbarCategoryId) {
              const navbarCategoryElementLeft =
                navbarCategoriesRefs[id].current.offsetLeft
              const scrollLeftOnDesktop = navbarCategoryElementLeft - 20
              const scrollLeftOnSmartphone = navbarCategoryElementLeft

              navbarElement.scrollTo({
                left:
                  screenWidth > 992
                    ? scrollLeftOnDesktop
                    : scrollLeftOnSmartphone,
              })
            } else {
              navbarElement.scrollTo({
                left: 0,
              })
            }

            const maxScrollLeft =
              navbarElement.scrollWidth - navbarElement.clientWidth

            setRemainingNavbarScroll(maxScrollLeft - navbarElement.scrollLeft)
          }
        }
      })
    }

    document.addEventListener('scroll', onScrollPage)

    const navbarElement = navbarRef.current
    const maxScrollLeft = navbarElement.scrollWidth - navbarElement.clientWidth

    setRemainingNavbarScroll(maxScrollLeft)
    setInitialNavbarScroll(maxScrollLeft)

    return function cleanup() {
      window.removeEventListener('scroll', onScrollPage)
    }
  }, [categoriesRefs, navbarRef, navbarCategoriesRefs])

  const scrollNavbarRight = () => {
    const navbarElement = navbarRef.current

    navbarElement.scrollLeft += navbarElement.scrollWidth / 4
    const maxScrollLeft = navbarElement.scrollWidth - navbarElement.clientWidth

    setRemainingNavbarScroll(maxScrollLeft - navbarElement.scrollLeft)
  }

  const scrollNavbarLeft = () => {
    const navbarElement = navbarRef.current

    navbarElement.scrollLeft -= navbarElement.scrollWidth / 4
    const maxScrollLeft = navbarElement.scrollWidth - navbarElement.clientWidth

    setRemainingNavbarScroll(maxScrollLeft + navbarElement.scrollLeft)
  }

  const hideAddExtrasAndNoteDialog = () => {
    produce(draft => {
      delete draft.currentItemstampId
      delete draft.addExtrasAndNoteDialog
    })
  }

  const showAddExtrasAndNoteDialog = item => {
    produce(draft => {
      draft.addExtrasAndNoteDialog = item
    })
  }

  const updateMeal = meal => {
    produce(draft => {
      draft.meal = meal
      plantMeal(draft.meal)
    })
  }

  const addOrUpdateItemstamp = itemstamp => {
    updateMeal(meal.addOrUpdateItemstamp(itemstamp))
  }

  const addItemstamp = item => {
    let itemstamp = Meal.createItemstamp(item, null, ANNOTATED)
    meal.addOrUpdateItemstamp(itemstamp)
    addOrUpdateItemstamp(itemstamp)
  }

  const showSendingDialog = () => {
    produce(draft => {
      draft.sendingDialog = true
    })
  }

  const hideSendingDialog = () => {
    produce(draft => {
      delete draft.sendingDialog
    })
  }

  const updateItemstamp = item => {
    let itemstamp = Meal.createItemstamp(item, null, ANNOTATED)

    item.extras.forEach(extra => {
      if (extra.quantity) {
        itemstamp = itemstamp.addOrUpdateExtra(extra)
      }
    })

    if (item.note) {
      itemstamp = itemstamp.set('note', item.note)
    }

    addOrUpdateItemstamp(itemstamp)
  }

  const removeItemstamp = itemstampId => {
    updateMeal(meal.removeItemstampById(itemstampId))
  }

  const showErrorDialog = error => {
    produce(draft => {
      draft.errorDialog = error ? error.toString() : null
    })
  }

  const hideErrorDialog = () => {
    produce(draft => {
      delete draft.errorDialog
    })
  }

  const showConfirmationDialog = () => {
    produce(draft => {
      draft.confirmationDialog = true
    })
  }

  const hideConfirmationDialog = () => {
    produce(draft => {
      delete draft.confirmationDialog
    })
    setSmartphoneCartView(false)
  }

  const sendOrder = async event => {
    event.preventDefault()

    try {
      showSendingDialog()

      const meals = await getMeals(
        restaurant?._id,
        new Date().getTime() - 86400000,
      )

      const tableMeal = meals
        .filter(meal => meal.table === table)
        .sort((a, b) => new Date(b.creationTime) - new Date(a.creationTime))[0]

      const isTableAvailable =
        tableMeal &&
        (tableMeal.archived ||
          Object.keys(tableMeal?.itemstamps).every(
            itemstampId =>
              tableMeal.itemstamps[itemstampId].status ===
                Itemstamp.Status.CANCELLED ||
              tableMeal.itemstamps[itemstampId].paid,
          ))

      if (isTableAvailable || !tableMeal) {
        Object.keys(meal.itemstamps).forEach(itemstampId => {
          if (
            Itemstamp.Status.ANNOTATED === meal.itemstamps[itemstampId].status
          ) {
            meal = meal.setItemstampStatus(
              itemstampId,
              Itemstamp.Status.ORDERED,
            )
          }
        })

        addMeal(meal.restaurantId, meal)
          .then(() => {
            hideSendingDialog()
            showConfirmationDialog()
            burnMeal(meal._id)
          })
          .catch(error => {
            Object.keys(meal.itemstamps).forEach(itemstampId => {
              if (
                Itemstamp.Status.ORDERED === meal.itemstamps[itemstampId].status
              ) {
                meal = meal.setItemstampStatus(
                  itemstampId,
                  Itemstamp.Status.ANNOTATED,
                )
              }
            })

            updateMeal(meal)

            hideSendingDialog()

            showErrorDialog(error)
          })
      } else {
        hideSendingDialog()

        showErrorDialog(
          t(
            'Table is currently occupied. Please wait until the table is available.',
          ),
        )
      }
    } catch (error) {
      hideSendingDialog()

      showErrorDialog(error)
    }
  }

  const { name } = restaurant || {}

  categories?.forEach(category => {
    categoriesRefs[category.id] = React.createRef()
    navbarCategoriesRefs[category.id] = React.createRef()
  })

  return (
    <>
      {loading && <div css={{ marginBottom: 30 }} className="loader" />}

      {!loading && (
        <>
          <nav
            css={{
              backgroundColor: 'var(--color-primary)',
              display: 'flex',
              position: 'fixed',
              justifyContent: 'space-between',
              top: 0,
              zIndex: 2000,
              width: '100%',
              height: 66,
              '@media(max-width: 992px)': {
                height: 64,
              },
            }}
          >
            <div
              css={{
                fontWeight: 800,
                margin: 'auto',
                fontSize: 20,
                color: 'white',
                textTransform: 'capitalize',
              }}
            >
              {restaurant.name}
            </div>
          </nav>

          <div css={{ background: 'var(--color-primary)', height: 62 }} />

          {/* removing this parent div will break CategoriesNavbar */}
          <div css={orderContainer}>
            <div css={container}>
              <div css={row}>
                <div css={colSm12}>
                  <div css={headingContainer}>
                    <h3 css={headingTitle}>{t('Self Order')}</h3>
                  </div>
                </div>
              </div>
            </div>

            {!(categories?.length > 0) && (
              <div css={container}>
                <div css={[row, { justifyContent: 'center' }]}>
                  {t(
                    "There isn't a menu available for this restaurant at the  moment",
                    locale,
                  )}
                </div>
              </div>
            )}

            {categories?.length > 0 && (
              <CategoriesNavbar
                categories={categories}
                categoriesRefs={categoriesRefs}
                navbarRef={navbarRef}
                navbarCategoriesRefs={navbarCategoriesRefs}
                activeCategory={activeCategory}
                remainingNavbarScroll={remainingNavbarScroll}
                initialNavbarScroll={initialNavbarScroll}
                scrollNavbarLeft={scrollNavbarLeft}
                scrollNavbarRight={scrollNavbarRight}
              />
            )}

            <div css={container}>
              <div css={row}>
                <div css={[colMd12, colLg8]}>
                  {categories?.map((category, index) => (
                    <div
                      key={category.id}
                      css={{ marginBottom: 40 }}
                      ref={categoriesRefs[category.id]}
                      data-testid={`category-${index}`}
                    >
                      <h1 css={{ marginTop: 40 }}>{category.name}</h1>

                      {/* eslint-disable indent */}
                      <div css={{ display: 'flex', flex: 1, flexWrap: 'wrap' }}>
                        {category.items.map(item => (
                          <div
                            key={item.id}
                            css={{
                              maxWidth: 480,
                              width: '100%',
                              height: '100%',
                              position: 'relative',
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginRight: 'auto',
                              marginBottom: 25,
                              borderRadius: 4,
                              borderWidth: 1,
                              borderStyle: 'solid',
                              borderColor: item.quantity
                                ? 'var(--color-primary)'
                                : '#ebebeb',
                              cursor:
                                item.available !== false
                                  ? 'pointer'
                                  : 'inherit',
                              '@media(min-width: 993px)': {
                                ':hover':
                                  item.available !== false
                                    ? {
                                        border:
                                          '1px solid var(--color-primary)',
                                        color: 'black',
                                        fill: 'black',
                                      }
                                    : null,
                              },
                              '@media(max-width: 992px)': {
                                maxWidth: '100%',
                              },
                              '@media(max-width: 576px)': {
                                border: 0,
                                borderRadius: 0,
                                borderBottom: '1px solid #ebebeb',
                              },
                            }}
                            onClick={
                              item.available !== false
                                ? () =>
                                    addItemstamp({
                                      id: item.id,
                                      name: item.name,
                                      price: item.price,
                                    })
                                : null
                            }
                          >
                            <div
                              css={{
                                flex: 1,
                                padding: 16,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                '@media(max-width: 576px)': {
                                  padding: '16px 0px',
                                },
                              }}
                            >
                              {item.quantity && (
                                <div
                                  css={{
                                    zIndex: 1,
                                    fontSize: 13,
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    width: 18,
                                    height: 18,
                                    color: 'white',
                                    textAlign: 'center',
                                    backgroundColor: 'var(--color-primary)',
                                    borderTopRightRadius: 2,
                                    borderBottomLeftRadius: 4,
                                    '@media(max-width: 576px)': {
                                      top: 16,
                                    },
                                  }}
                                >
                                  {item.quantity}
                                </div>
                              )}

                              <div
                                css={{
                                  fontSize: 16,
                                  fontWeight: 'bold',
                                  maxWidth: 200,
                                  textDecoration:
                                    item.available === false
                                      ? 'line-through'
                                      : 'inherit',
                                }}
                              >
                                {item.name}
                              </div>

                              {item.description && (
                                <div
                                  css={{
                                    marginTop: 8,
                                    fontWeight: '300',
                                    fontSize: 14,
                                    maxWidth: 300,
                                  }}
                                >
                                  {item.description}
                                </div>
                              )}

                              <div
                                css={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  marginTop: 15,
                                }}
                              >
                                <div css={{ paddingTop: 3, paddingBottom: 3 }}>
                                  {formatPriceWithSymbol(
                                    item.price,
                                    currencyCode,
                                  )}
                                </div>
                                {!item.photoUrl && (
                                  <div
                                    css={{
                                      paddingLeft: 3,
                                      paddingRight: 3,
                                      background: 'var(--color-primary)',
                                    }}
                                  >
                                    <IconAdd
                                      css={{
                                        marginTop: 3,
                                        width: 18,
                                        height: 18,
                                        fill: 'white',
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>

                            {item.photoUrl && (
                              <img
                                css={{
                                  objectFit: 'cover',
                                  width: 158,
                                  height: '100%',
                                  borderTopRightRadius: 3,
                                  borderBottomRightRadius: 3,
                                  '@media(max-width: 576px)': {
                                    margin: '16px 0px 16px 16px',
                                    width: 88,
                                    height: 88,
                                    outline: 'rgba(0, 0, 0, 0.03) solid 1px',
                                  },
                                }}
                                src={item.photoUrl}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                      {/* eslint-enable indent */}
                    </div>
                  ))}
                </div>

                {name && items && (
                  <div
                    css={[
                      colLg4,
                      dNone,
                      dLgBlock,
                      {
                        paddingLeft: 0,
                        paddingRight: 0,
                        marginLeft: -15,
                        marginTop: 40,
                        border: '1px solid #ebebeb',
                        userSelect: 'none',
                      },
                    ]}
                  >
                    <div css={{ position: 'sticky', top: 132, left: 0 }}>
                      <div
                        css={{
                          display: 'flex',
                          justifyContent: 'center',
                          textTransform: 'capitalize',
                          fontWeight: 'bold',
                          paddingTop: 30,
                        }}
                      >
                        {restaurant.name}
                      </div>
                      <div
                        css={{
                          marginTop: 30,
                          maxHeight: 300,
                          overflowY: 'auto',
                        }}
                      >
                        {items.map((line, index) => (
                          <div
                            data-testid={`cart-item-${index}`}
                            key={line.itemstampIds[0]}
                            css={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              fontWeight: '200',
                              cursor: 'pointer',
                              ':hover': {
                                backgroundColor: '#f6f6f6',
                              },
                              ':first-of-type': {
                                marginTop: 0,
                              },
                            }}
                          >
                            <div
                              css={{
                                display: 'flex',
                                flex: '25%',
                                margin: 'auto 14px',
                              }}
                            >
                              <IconRemove
                                data-testid={`icon-remove-cart-item-${index}`}
                                css={{
                                  width: 18,
                                  height: 18,
                                  fill: 'var(--color-primary)',
                                  cursor: 'pointer',
                                }}
                                onClick={() =>
                                  removeItemstamp(line.itemstampIds[0])
                                }
                              />
                              <div
                                css={{
                                  fontSize: 14,
                                  fontWeight: 'bold',
                                  maxWidth: 25,
                                  width: '100%',
                                  textAlign: 'center',
                                }}
                              >
                                {line.quantity}
                              </div>
                              <IconAdd
                                data-testid={`icon-add-cart-item-${index}`}
                                css={{
                                  width: 18,
                                  height: 18,
                                  fill: 'var(--color-primary)',
                                  cursor: 'pointer',
                                }}
                                onClick={() =>
                                  updateItemstamp({
                                    id: line.id,
                                    name: line.name,
                                    price: line.price,
                                    extras: line.extras,
                                    note: line.note,
                                  })
                                }
                              />
                            </div>
                            <div
                              data-testid={`cart-item-${index}-show-extras-and-note-dialog`}
                              onClick={() => showAddExtrasAndNoteDialog(line)}
                              css={{ flex: '75%', padding: '14px' }}
                            >
                              {line.name}

                              {line.extras.map(
                                extra =>
                                  extra.quantity && (
                                    <div css={{ marginTop: 2 }} key={extra.id}>
                                      + {extra.name}
                                    </div>
                                  ),
                              )}

                              {line.note && (
                                <div css={{ marginTop: 2 }}>! {line.note}</div>
                              )}
                            </div>
                            <div
                              onClick={() => showAddExtrasAndNoteDialog(line)}
                              css={{
                                flex: '25%',
                                padding: '14px',
                                textAlign: 'right',
                                fontSize: 14,
                              }}
                            >
                              {line.priceWithExtra}
                            </div>
                          </div>
                        ))}
                      </div>
                      {items.length === 0 && (
                        <div
                          css={{
                            display: 'flex',
                            justifyContent: 'center',
                            textAlign: 'center',
                            fontWeight: '200',
                            color: '#626262',
                            marginTop: 30,
                          }}
                        >
                          {t('Your cart is empty')}
                        </div>
                      )}

                      {items.length > 0 && (
                        <>
                          {meal.tax && !meal.tax.alreadyIncludedInItems && (
                            <div
                              css={{
                                fontWeight: '200',
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: 30,
                                paddingLeft: 15,
                                paddingRight: 15,
                              }}
                            >
                              <span>
                                {meal.tax.name} {meal.tax.quantity}%
                              </span>
                              <span data-testid="tax-price">{taxPrice}</span>
                            </div>
                          )}

                          <div
                            css={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginTop: 30,
                              paddingLeft: 15,
                              paddingRight: 15,
                            }}
                          >
                            <div css={{ fontWeight: 'bold' }}>{t('Total')}</div>
                            <div
                              data-testid="total-price"
                              css={{ fontWeight: 'bold' }}
                            >
                              {totalPrice}
                            </div>
                          </div>
                        </>
                      )}

                      <div
                        onClick={sendOrder}
                        css={{
                          fontSize: 18,
                          fontWeight: '200',
                          backgroundColor: 'var(--color-primary)',
                          color: 'white',
                          marginTop: 30,
                          marginLeft: 15,
                          marginRight: 15,
                          padding: '10px 50px',
                          cursor: 'pointer',
                          textAlign: 'center',
                          textTransform: 'uppercase',
                          pointerEvents: items.length === 0 && 'none',
                          opacity: items.length === 0 && 0.5,
                          ':hover, :focus': {
                            backgroundColor: 'var(--color-primary-dark)',
                          },
                        }}
                      >
                        {t('Send order')}
                      </div>
                    </div>
                  </div>
                )}

                {sendingDialog && (
                  <LoadingDialog
                    title={t('Sending order')}
                    message={t('Sending order')}
                  />
                )}
                {confirmationDialog && (
                  <ErrorDialog
                    confirm={t('Okay')}
                    close={hideConfirmationDialog}
                    locale={locale}
                    message={t('Your order has been sent successfully')}
                    title={t('Order sent')}
                  />
                )}
                {errorDialog && (
                  <ErrorDialog
                    close={hideErrorDialog}
                    locale={locale}
                    message={errorDialog}
                  />
                )}

                {error && (
                  <>
                    <div css={errorMessage}>{error}</div>
                    <form>
                      <input type="hidden" name="again" value="true" />
                      <button
                        type="submit"
                        css={{
                          maxWidth: 500,
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          lineHeight: '48px',
                          textAlign: 'center',
                          marginTop: 8,
                          textTransform: 'uppercase',
                          cursor: 'pointer',
                          backgroundColor: '#626262',
                          color: 'white',
                          fontSize: 20,
                          fontWeight: 'bold',
                        }}
                      >
                        Try again
                      </button>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>

          {addExtrasAndNoteDialog && (
            <AddExtrasAndNoteDialog
              item={addExtrasAndNoteDialog}
              currencyCode={currencyCode}
              close={hideAddExtrasAndNoteDialog}
              addOrUpdateItemstamp={addOrUpdateItemstamp}
              meal={meal}
              locale={locale}
            />
          )}

          {items?.length > 0 && (
            <div
              data-testid="toggle-smartphone-cart-view"
              css={[
                dLgNone,
                {
                  display: 'flex',
                  justifyContent: 'space-between',
                  position: 'fixed',
                  bottom: 0,
                  left: 0,
                  padding: 15,
                  backgroundColor: 'var(--color-primary)',
                  color: 'white',
                  width: '100%',
                  zIndex: 1000,
                },
              ]}
              onClick={toggleSmartphoneCartView}
            >
              <span css={{ textTransform: 'capitalize' }}>
                {t('View cart items')}
              </span>
              <span>{totalPrice}</span>
            </div>
          )}

          {smartphoneCartView && (
            <div
              css={[
                dLgNone,
                {
                  zIndex: 3000,
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  overflow: 'hidden',
                  overflowY: 'scroll',
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'white',
                },
              ]}
            >
              <div
                css={{
                  paddingLeft: 15,
                  paddingRight: 15,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  maxWidth: 350,
                  width: '100%',
                }}
              >
                <div css={{ marginTop: 30 }}>
                  <IconClose
                    css={{
                      width: 24,
                      height: 24,
                      fill: 'var(--color-primary)',
                    }}
                    onClick={toggleSmartphoneCartView}
                  />
                </div>

                <div
                  css={{
                    display: 'flex',
                    justifyContent: 'center',
                    textTransform: 'capitalize',
                    fontWeight: 'bold',
                    marginTop: 30,
                  }}
                >
                  {restaurant.name}
                </div>

                {items &&
                  items.map(item => (
                    <div
                      key={item.itemstampIds[0]}
                      css={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontWeight: '200',
                        marginTop: 30,
                      }}
                    >
                      <div
                        css={{
                          display: 'flex',
                          flex: '25%',
                          marginLeft: 5,
                          marginRight: 5,
                        }}
                      >
                        <IconRemove
                          css={{
                            width: 18,
                            height: 18,
                            fill: 'var(--color-primary)',
                          }}
                          onClick={() => removeItemstamp(item.itemstampIds[0])}
                        />
                        <div
                          css={{
                            fontSize: 14,
                            fontWeight: 'bold',
                            marginTop: 2,
                            maxWidth: 25,
                            width: '100%',
                            textAlign: 'center',
                          }}
                        >
                          {item.quantity}
                        </div>
                        <IconAdd
                          css={{
                            width: 18,
                            height: 18,
                            fill: 'var(--color-primary)',
                          }}
                          onClick={() =>
                            updateItemstamp({
                              id: item.id,
                              name: item.name,
                              price: item.price,
                              extras: item.extras,
                              note: item.note,
                            })
                          }
                        />
                      </div>
                      <div
                        onClick={() => showAddExtrasAndNoteDialog(item)}
                        css={{ flex: '75%', marginLeft: 5, marginRight: 5 }}
                      >
                        {item.name}

                        {item.extras.map(
                          extra =>
                            extra.quantity && (
                              <div css={{ marginTop: 2 }} key={extra.id}>
                                + {extra.name}
                              </div>
                            ),
                        )}

                        {item.note && (
                          <div css={{ marginTop: 2 }}>! {item.note}</div>
                        )}

                        <div
                          css={{
                            marginTop: 4,
                            fontSize: 13,
                            color: 'var(--color-primary)',
                            cursor: 'pointer',
                          }}
                        >
                          {t('Edit')}
                        </div>
                      </div>
                      <div
                        onClick={() => showAddExtrasAndNoteDialog(item)}
                        css={{
                          flex: '25%',
                          textAlign: 'right',
                          marginLeft: 5,
                          marginRight: 5,
                          fontSize: 14,
                        }}
                      >
                        {item.priceWithExtra}
                      </div>
                    </div>
                  ))}

                {items.length === 0 && (
                  <div
                    css={{
                      display: 'flex',
                      justifyContent: 'center',
                      textAlign: 'center',
                      fontWeight: '200',
                      color: '#626262',
                      marginTop: 30,
                    }}
                  >
                    {t('Your cart is empty')}
                  </div>
                )}

                <div
                  css={{
                    height: 1,
                    color: '#e5e5e5',
                    backgroundColor: '#e5e5e5',
                    border: 'none',
                    marginTop: 40,
                  }}
                />

                {meal.tax && (
                  <div
                    css={{
                      fontWeight: '200',
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: 30,
                    }}
                  >
                    <span>
                      {meal.tax.name} {meal.tax.quantity}%
                    </span>
                    <span data-testid="tax-price">{taxPrice}</span>
                  </div>
                )}

                <div
                  css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 30,
                  }}
                >
                  <div css={{ fontWeight: 'bold' }}>{t('Total')}</div>
                  <div css={{ fontWeight: 'bold' }}>{totalPrice}</div>
                </div>

                <div
                  data-testid="smartphone-view-send-button"
                  onClick={sendOrder}
                  css={{
                    fontSize: 18,
                    fontWeight: '200',
                    backgroundColor: 'var(--color-primary)',
                    color: 'white',
                    marginTop: 30,
                    marginBottom: 30,
                    padding: '10px 50px',
                    cursor: 'pointer',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    pointerEvents: items.length === 0 && 'none',
                    opacity: items.length === 0 && 0.5,
                    ':hover, :focus': {
                      backgroundColor: 'var(--color-primary-dark)',
                    },
                  }}
                >
                  {t('Checkout')}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

const CategoriesNavbar = props => {
  const {
    activeCategory,
    categories,
    categoriesRefs,
    initialNavbarScroll,
    navbarCategoriesRefs,
    navbarRef,
    remainingNavbarScroll,
    scrollNavbarLeft,
    scrollNavbarRight,
  } = props

  const onClickCategory = category => {
    const isSmartphone = window.matchMedia('(max-width: 992px)').matches

    window.scrollTo({
      top:
        categoriesRefs[category.id].current.offsetTop +
        navbarRef.current.offsetHeight * (isSmartphone ? 2.2 : 3),
      behavior: 'smooth',
    })
  }

  return (
    categories && (
      <>
        <div
          css={[
            container,
            {
              position: 'sticky',
              top: 66,
              left: 0,
              right: 0,
              zIndex: 1000,
              backgroundColor: 'white',
              '@media (max-width: 992px)': {
                top: 64,
              },
            },
          ]}
        >
          {initialNavbarScroll !== remainingNavbarScroll && (
            <IconAngleLeft
              onClick={scrollNavbarLeft}
              css={[
                dNone,
                dLgBlock,
                {
                  width: 20,
                  height: 20,
                  position: 'absolute',
                  left: 15,
                  top: 22,
                  zIndex: 1,
                  backgroundColor: 'white',
                  cursor: 'pointer',
                  fill: '#626262',
                  ':hover': {
                    fill: 'var(--color-primary)',
                  },
                },
              ]}
            />
          )}

          <div
            ref={navbarRef}
            css={{
              display: 'flex',
              overflowX: 'hidden',
              '@media (max-width: 992px)': {
                overflowX: 'scroll',
              },
            }}
          >
            {categories?.map((category, index) => (
              <div
                data-testid={`navbar-category-${index}`}
                key={category.id}
                ref={navbarCategoriesRefs[category.id]}
                css={{
                  position: 'relative',
                  paddingLeft: 30,
                  height: 66,
                  lineHeight: '66px',
                  color:
                    activeCategory === category.id
                      ? 'var(--color-primary)'
                      : '#999999',
                  fontSize: 14,
                  textTransform: 'uppercase',
                  whiteSpace: 'nowrap',
                  cursor: 'pointer',
                  '@media (hover: hover) and (pointer: fine)': {
                    ':hover': {
                      color: 'var(--color-primary)',
                      ':after': {
                        width: '100%',
                      },
                    },
                  },
                  ':after': {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    left: 15,
                    width: activeCategory === category.id ? '100%' : 0,
                    height: 4,
                    backgroundColor: 'var(--color-primary)',
                  },
                  ':first-of-type': {
                    paddingLeft: 0,
                    ':after': {
                      left: 0,
                    },
                  },
                  ':last-of-type': {
                    paddingRight: 0,
                    ':after': {
                      left: remainingNavbarScroll !== initialNavbarScroll && 30,
                    },
                  },
                  '@media (max-width: 992px)': {
                    height: 64,
                    lineHeight: '64px',
                  },
                  WebkitTapHighlightColor: 'rgba(0,0,0,0)',
                }}
                onClick={() => onClickCategory(category)}
              >
                {category.name}
              </div>
            ))}
          </div>

          {remainingNavbarScroll !== 0 && (
            <IconAngleRight
              onClick={scrollNavbarRight}
              css={[
                dNone,
                dLgBlock,
                {
                  width: 20,
                  height: 20,
                  position: 'absolute',
                  right: 9,
                  zIndex: 1,
                  top: 22,
                  backgroundColor: 'white',
                  cursor: 'pointer',
                  fill: '#626262',
                  ':hover': {
                    fill: 'var(--color-primary)',
                  },
                },
              ]}
            />
          )}
        </div>
        <div
          css={{
            maxWidth: 1920,
            width: '100%',
            position: 'sticky',
            top: 132,
            left: 0,
            right: 0,
            zIndex: 1000,
            height: 1,
            backgroundColor: '#ebebeb',
            '@media (max-width: 992px)': {
              top: 128,
            },
          }}
        />
      </>
    )
  )
}

export default TablePage
