/* Copyright 2013 - 2024 Waiterio LLC */
function NotFoundError(error = '') {
  this.message = error?.message || error
  this.status = 404
  this.name = 'NotFoundError'
}

NotFoundError.prototype = Error.prototype
NotFoundError.prototype.toString = function toString() {
  return this.message
}

export default NotFoundError
